
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { Expander } from '../platformLayout/tv/Dashboard/helperComponent/ExpandableTile';
import { useLocation, useNavigate } from 'react-router-dom';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MODULE, PUBLISHING_SOURCE } from '../../framework/constant/constant';
import { Checkbox } from '@progress/kendo-react-inputs';
import moment from 'moment';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import InfoRoundButton from '../../components/InfoRoundButton';
import { ContentEditForm } from '../masters/ContentEditForm';
import { history } from '../../components/history';
import LanguageTranslator from '../../components/LanguageTranslator';
import { GenreEditForm } from '../masters/GenreEditForm';
import { ConfirmAlert } from '../../ConfirmAlert';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import MMImageEditForm from './MMImageEditForm';
import ImageCard from './component/ImageCard';
import BossDialog from '../../components/BossDialog';

const SeasonDetailForm = ({ dataItem, setDataItem, selectedSeries, setSelectedSeries, isArchiveChanged, setIsArchiveChanged, createEpisodeDataItem, setCreateEpisodeDataItem, ...props }) => {

  const lang = useTranslation();
  const location = useLocation();

  const [content, setContent] = useState([]);
  const [episodeContent, setEpisodeContent] = useState([]);
  const [openImageBank, setOpenImageBank] = useState(false);
  const [openSeriesSelection, setOpenSeriesSelection] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');
  const [disableEpsiodeFields, setDisableEpsiodeFields] = useState(true)
  const [mediaCategory, setMediaCategory] = useState([]);
  const [showTranslateForm, setShowTranslateForm] = useState(false);
  const [genres, setGenres] = useState([]);
  const [currentUserChannels, setCurrentUserChannels] = useState([]);
  const [openImageInput, setOpenImageInput] = useState(false);

  const defaultFormState = {
    Content: false,
  }
  const [showEditForm, setShowEditForm] = useState(defaultFormState);


  //on create new entity and edit quickEdit is false but on quickEdit click quickEdit is true
  const [openForm, setFormOpen] = useState(dataItem.SID == 0 || props.quickEdit);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.selectedSeries) {
      setSelectedSeries(props.selectedSeries);
    }
    loadcombo();
    if (dataItem.SID > 0) {
      loadEditData();
    }
  }, []);


  const loadcombo = async () => {
    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
    setMediaCategory(mediaCategory.data);
    let content = await API.getDataLookup(ENTITYNAME.Content, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    let filteredEpisodeContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Episode);
    let filteredSeasonContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Season)
    setEpisodeContent(filteredEpisodeContent);
    // setEpisodeContent(content.data);
    setContent(filteredSeasonContent);
    let genresRes = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenres(genresRes.data);

    let userChannels = await utility.getUserChannels()
    setCurrentUserChannels(userChannels)

    if (dataItem.SID == 0) {

      if (filteredSeasonContent.length > 0) {
        const updatedContent = await API.getAndSaveNextNumber(filteredSeasonContent[0].SID, { ...filteredSeasonContent[0], isSaveNextnumber: false });
        if (!updatedContent.success) return;

        setDataItem({
          ...dataItem,
          Genres: utility.getDefaultItems(genresRes.data),
          SubGenres: utility.getDefaultItems(genresRes.data),
          AssetId: updatedContent.data.HouseNumber,
          Content: filteredSeasonContent.length > 0 ? filteredSeasonContent[0] : {}
        })
      }

      setCreateEpisodeDataItem({
        NumberOfEpisodes: 1,
        Content: filteredEpisodeContent.length > 0 ? filteredEpisodeContent[0] : {},
        StartingEpisodes: 1,
        Duration: "00:00:00:00",
      })

    }

  }

  const onChange = async (e) => {

    if (e.target.name == "Content") {

      let item = { ...dataItem, Content: e.target.value }
      const updatedContent = await API.getAndSaveNextNumber(e.target.value.SID, { ...e.target.value, isSaveNextnumber: false });
      if (!updatedContent.success) return;
      console.log(!updatedContent.success);

      setDataItem({ ...item, AssetId: updatedContent.data.HouseNumber });

    } else if (e.target.name == "Title") {
      setDataItem({ ...dataItem, Title: e.target.value, Description: e.target.value });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    if (e.target.name == "Archive") {
      console.log(!isArchiveChanged);
      setIsArchiveChanged(!isArchiveChanged)
    }
  };

  const handleCreateEpisodeChange = (e) => {
    setCreateEpisodeDataItem({ ...createEpisodeDataItem, [e.target.name]: e.target.value });

    if (e.target.name == "NumberOfEpisodes") {
      if (e.target.value > 0) {
        setDisableEpsiodeFields(() => false);
      } else {
        setDisableEpsiodeFields(() => true);
      }
    }
  };



  const loadEditData = async () => {
    setSelectedImageUrl(dataItem.ImageUrl);
    if (dataItem.Series_Id == undefined || dataItem.Series_Id.length == 0) return;
  };


  const setSelectedImage = (dataList) => {
    setSelectedImageUrl(() => dataList[0].ImageUrl);
    setDataItem({ ...dataItem, ["ImageUrl"]: dataList[0].ImageUrl });
  }

  const onSelectionSeries = (dataList) => {
    // setSeries(() => dataList[0]);

    console.log(dataList);
    setSelectedSeries(() => dataList[0]);
    setDataItem({ ...dataItem, ["Series_Id"]: dataList[0]._id });
  }

  const onCancelEdit = () => {
    if (dataItem.SID == 0) {

      if (location.state.selectedSeries) {
        history.replace(`/home/MediaEpisode/Series/${location.state.selectedSeries.SID}`, { state: { quickEdit: false, copy: false } })
        navigate(-1);
      } else {
        navigate(-1);
      }

      setFormOpen(false);
    } else {
      props.quickEdit ? navigate(-1) : setFormOpen(false);
    }
  }

  async function validationCheck() {


    if (dataItem.Title == '' || dataItem.Title == undefined) {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.SeasonNumber < 1 || dataItem.SeasonNumber == undefined) {
      toast.error(`${lang.season_number_should_not_be_zero_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    //Already existing season of selected series
    if (dataItem.SID == 0 && selectedSeries.SID != undefined) {
      let temp = [];
      if (props.existingSeasons != undefined) {
        temp = props.existingSeasons.map((x) => x.SeasonNumber.replace('Season: ', ''))
      } else {
        //fetch
        let existingSeasons = await API.getData(ENTITYNAME.Season, { query: ['Series_Id', '=', selectedSeries._id] });
        temp = existingSeasons.data.map((x) => x.SeasonNumber)
      }
      let checkSeasonNumber = temp.some((x) => parseInt(x) == dataItem.SeasonNumber);
      if (checkSeasonNumber) {
        toast.error(` Season Number is already exists in Selected Parent Series, Please Select correct Parent Series`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

    }
    if (dataItem.Genres == undefined || dataItem.Genres.length == 0) {
      toast.error(`${lang.please_select_genre_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.SID == 0 && Object.keys(dataItem.Content).length == 0) {
      toast.error(`${lang.please_select_content_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Description == '' || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.SID == 0) {
      if (createEpisodeDataItem.NumberOfEpisodes < 1 || createEpisodeDataItem.NumberOfEpisodes == undefined) {
        toast.error(`${lang.number_of_epiosde_should_not_be_zero_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if (Object.keys(createEpisodeDataItem.Content).length == 0) {
        toast.error(`${lang.please_select_episode_content_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if (createEpisodeDataItem.StartingEpisodes < 1 || createEpisodeDataItem.StartingEpisodes == undefined) {
        toast.error(`${lang.starting_epiosde_should_not_be_zero_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if (createEpisodeDataItem.Duration.includes("_") || createEpisodeDataItem.Duration == '00:00:00:00') {
        toast.error(`${lang.invalid_duration_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }
    return true;
  }

  const onSaveData = async () => {

    let isValid = await validationCheck();

    if (isValid) {

      if (props.quickEdit) {

        props.onSave();
        onCancelEdit();

      } else {

        setFormOpen(false);

      }
    }
  };

  const onCreateMasterClick = (key) => {
    setShowEditForm({
      ...defaultFormState,
      [key]: true
    });
  }

  const handleLangugaeTranslate = (data) => {
    setDataItem({ ...dataItem, Synopsis: data });
    setShowTranslateForm(false);
  }

  const updateReferenceCode = async () => {
    let updateReferenceCode = {
      season_id: dataItem._id,
      SeasonCode: dataItem?.SeasonCode ?? ""
    }
    await API.updateEpisodeReferenceCode(updateReferenceCode);
    props.loadEpisodes(dataItem._id, false);
  }

  const confirAlertUpdateReferenceCode = async () => {
    ConfirmAlert(
      () => { updateReferenceCode() },
      () => { },
      lang.sync_reference_code_message,
      'Are you sure you want to update ?'
    );
  };

  return (
    <div className="card mb-3 ml-2 mt-3" style={{ maxWidth: "70vw" }}>
      <i className="fa-solid fa-pen-to-square" onClick={() => setFormOpen(true)} style={{ position: "absolute", top: "20px", right: "35px", cursor: 'pointer' }}></i>
      <Expander expand={dataItem.SID == 0 ? true : false} title={dataItem.Title.length == 0 ? `${lang.enter_title}` : dataItem.Title} subtext={" #" + dataItem.AssetId} img={dataItem.ImageUrl}>

        <div className='flex-container' style={{ margin: "10px" }} >
          <div>
            <ImageCard style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} img={dataItem.ImageUrl} alt='Dummy' />
          </div>

          <div style={{ textAlign: "left", marginLeft: "20px" }}>
            <h1 className='d-inline' style={{ fontFamily: "Roboto", fontSize: "40px", whiteSpace: 'nowrap', fontWeight: "400", textOverflow: "ellipsis", overflow: "hidden" }} >{utility.subString(dataItem?.Title, 20)}</h1>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem?.AssetId} </p>
            {dataItem?.PGRating && dataItem?.PGRating?.Description && <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem?.PGRating?.Description}</p>}
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem?.Genres?.map((obj) => obj?.Description).join(' | ')}</p>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem?.Languages?.map((obj) => obj?.Description).join(' | ')}</p>
            <h2 style={{ margin: "20px 0px 3px 0px", fontFamily: "Roboto", fontSize: "20px" }}>{lang.description_label}</h2>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "15px" }}>{utility.subString(dataItem?.Description, 60)}</p>
            <br />
          </div>
        </div>
      </Expander>
      {openForm && <BossDialog
        title={dataItem?.SID > 0 ? dataItem?.Title : `${lang.create_season_dialog_header}`}
        onClose={onCancelEdit}
        width={"70%"}
        height={dataItem?.SID > 0 ? "" : "86%"}
      >
        <div className="row mt-2 mb-2">
          <div className="marlr ml-4">
            <SaveButton title={"Confirm"} tooltip={"Confirm"} onClick={() => onSaveData()}></SaveButton>
            <CancelButton onClick={onCancelEdit}></CancelButton>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-9'>
            <Form
              render={(formRenderProps) => (
                <FormElement>

                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-2">
                      <div className="row">
                        <div className="form-group col-6">
                          <label htmlFor="">{lang.title_label} *</label>
                          <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.Title} onChange={(e) => onChange(e)} />
                        </div>

                        <div className="form-group col-3">
                          <label htmlFor="">{lang.season_number_label}</label>
                          <input name="SeasonNumber" type="number" className="form-control form-control-sm" value={dataItem.SeasonNumber} onChange={(e) => onChange(e)} disabled={dataItem.SID > 0 ? true : false} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">{lang.description_label} *</label>
                        <input name="Description" type="text" className="form-control form-control-sm" value={dataItem.Description} onChange={(e) => onChange(e)} />
                      </div>

                      <div className='row'>
                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.content_label} *</label>
                            <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                            <DropDownList
                              data={content}
                              name="Content"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Content}
                              onChange={onChange}
                              disabled={dataItem.SID > 0 ? true : false}
                            />
                          </div>
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '24px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Content') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label htmlFor="">{lang.asset_id_label}</label>
                            <h4>{dataItem.AssetId}</h4>
                          </div>
                        </div>
                        <div className="form-group col" >
                          <label htmlFor="">{lang.series_code_label}</label>
                          <input name="SeasonCode" style={{ maxWidth: (dataItem.SID > 0 && dataItem?.SeasonCode?.length > 0) && '67%' }} type="text" className="form-control form-control-sm" value={dataItem.SeasonCode} onChange={(e) => onChange(e)} />
                          {dataItem.SID > 0 && dataItem?.SeasonCode?.length > 0 && <RoundButton title={lang.sync_reference_code_message} icon="refresh" onClick={confirAlertUpdateReferenceCode} />}
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.genre_label} *</label>
                            <MultiSelect
                              data={genres}
                              name={"Genres"}
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Genres}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.create_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '24px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>

                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.sub_genre_label} </label>
                            <MultiSelect
                              data={genres}
                              name={"SubGenres"}
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.SubGenres}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.create_sub_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '24px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>

                      </div>



                      <div className="form-group">
                        <label htmlFor="">{lang.synopsis_label}</label>
                        <label className='float-right cursor-pointer' title={lang.translate_synopsis_level} onClick={() => setShowTranslateForm(true)}><i className="fa-solid fa-language" aria-hidden="true"></i></label>
                        <textarea type="text" className="form-control form-control-sm" name="Synopsis" value={dataItem.Synopsis} onChange={(e) => onChange(e)} />
                      </div>

                      <div className="row">
                        <div className="form-group col-12">
                          <label htmlFor="">{lang.series_label}</label><br />
                          <div style={{ display: "flex" }}>
                            <input name="Description" type="text" className="d-inline form-control form-control-sm" value={selectedSeries?.Title} onChange={(e) => onChange(e)} disabled={dataItem.SID > 0 ? true : props.disableSelectSeries} />

                            <button type="button" title='Create Content' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                              style={{ height: "30px", marginLeft: "10px" }} onClick={() => { setOpenSeriesSelection(true) }} disabled={dataItem.SID > 0 ? true : props.disableSelectSeries}>

                              {lang.select_button_text}
                            </button>

                            <button type="button" title='Create Content' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                              style={{ height: "30px", marginLeft: "10px" }} onClick={() => { setSelectedSeries({ Title: '' }) }} disabled={dataItem.SID > 0 ? true : props.disableSelectSeries}>

                              <i className="fa-solid fa-times fa-xs"></i>
                            </button>



                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-check col-2" style={{ marginBottom: "20px" }}>
                          <Field
                            style={{ border: "1px solid grey" }}
                            name={"Archive"}
                            component={Checkbox}
                            label={lang.archive}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Archive ? true : false}
                          />
                        </div>

                        <div className="form-check col-2" style={{ marginBottom: "20px" }}>
                          <Field
                            style={{ border: "1px solid grey" }}
                            name={"IsLive"}
                            component={Checkbox}
                            label={lang.islive_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.IsLive ? true : false}
                          />
                        </div>

                      </div>
                    </div>
                  </div>

                </FormElement>
              )}
            />
          </div>

          <div className="col-md-3">
            <div className='mt-4' style={{ textAlign: "center" }}>
              <span style={{ position: "absolute", top: "0px", right: "25px", cursor: "pointer" }} >
                <i className="fa fa-pencil pr-1" title="Edit Image Url" onClick={() => setOpenImageInput(true)}></i>
                <i className="fa fa-file-text" title='Select Image' onClick={() => setOpenImageBank(true)}></i>
              </span>
              <ImageCard style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} img={dataItem.ImageUrl} alt='Dummy' />
            </div>
          </div>
        </div>

        {dataItem.SID == 0 && <>
          <p style={{ backgroundColor: "white", paddingLeft: '03px', paddingRight: '03px', marginLeft: '18px', }}>{lang.episodes_label}</p>
          <div className="row" style={{ border: "solid 1px", padding: "20px", margin: "09px 20px 10px 20px" , marginTop: "-15px"}}>

            <div className='col-6'>
              <div className="form-group">
                <label htmlFor="">{lang.no_of_episodes_label}</label>
                <input name="NumberOfEpisodes" type="number" className="form-control form-control-sm" value={createEpisodeDataItem.NumberOfEpisodes} onChange={(e) => handleCreateEpisodeChange(e)} />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label htmlFor="TabView">{lang.content_label} *</label>
                <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                <DropDownList
                  data={episodeContent}
                  name="Content"
                  textField="Description"
                  dataItemKey="_id"
                  value={createEpisodeDataItem.Content}
                  onChange={handleCreateEpisodeChange}
                  validator={(value) => value ? "" : "Please select value"}

                />
              </div>
            </div>

            <div className='col-6'>
              <div className="form-group">
                <label htmlFor="">{lang.starting_episode_label}</label>
                <input name="StartingEpisodes" type="number" className="form-control form-control-sm" value={createEpisodeDataItem.StartingEpisodes} onChange={(e) => handleCreateEpisodeChange(e)} />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.duration_label} *</label>
                <TimePickerWithFormat
                  className="form-control form-control-sm"
                  name="Duration"
                  value={createEpisodeDataItem.Duration}
                  onChange={(e) => handleCreateEpisodeChange(e)}
                  required
                />
              </div>
            </div>
          </div>
          <p className='text-muted' style={{ fontSize: '11px', position: "relative", top: "20px", left: "15px" }}>{`* ${currentUserChannels && currentUserChannels.length == 1 ? `Deal Rights will automatically be created for ${currentUserChannels[0].FullChannelName}` : "Please create deal rights before saving"}`}</p>
        </>}
      </BossDialog>
      }
      {openImageBank && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => setOpenImageBank(!openImageBank)} setDataList={setSelectedImage} title={lang.select_image_collection_header} mode={"single"} width={"50vw"} height={"60vh"} />}
      {openSeriesSelection && <CollectionSelection entityname={ENTITYNAME.Series} closeForm={() => setOpenSeriesSelection(!openSeriesSelection)} setDataList={onSelectionSeries} title={lang.select_series_collection_header} mode={"single"} width={"50vw"} height={"60vh"} />}

      {showEditForm.Content && <ContentEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadcombo()} filterMediaCategory={props.mediaCategory} />}
      {showTranslateForm && <LanguageTranslator onSubmit={handleLangugaeTranslate} cancelEdit={() => setShowTranslateForm(false)} />}
      {showEditForm.Genre && <GenreEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadcombo()} />}
      {/* Image Content Popup */}
      {openImageInput && <MMImageEditForm onClose={() => setOpenImageInput(false)} selectedImageUrl={dataItem.ImageUrl} setDataList={(img) => { setSelectedImageUrl(img); setDataItem({ ...dataItem, ["ImageUrl"]: img }) }} />}
    </div>
  )
}

export default SeasonDetailForm