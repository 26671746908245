/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { Grid, GridColumn as Column, getSelectedState, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import BossDialog from "../../components/BossDialog";

export const VarianceEditForm = (props) => {
  const [dataItem, setDataItem] = useState({
    
    Description : '',
    Archive: false
  });
  const [showForm, setShowForm] = useState(false);
  
  const [gridData, setGridData] = useState([]);
  const lang=useTranslation();

  useEffect(() => {
    setEditItem();
  }, [])

  const handleChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

 async function setEditItem() {
    let mediaData = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Variance._id', '=', props.item._id] });
    setGridData(mediaData);
console.log(gridData)
    let dataItemLocal = {};
    
      dataItemLocal = {
        ...props.item,
        SID: props.item.SID ? 0 : props.item.SID,
        Description : props.item.copy ? 'copy of ' + props.item.Description : props.item.Description,      
        Archive: props.item.Archive

      }
     setDataItem(dataItemLocal);
    setShowForm(true);

  }

  const isValid = () => {
    const regex = /^[0-9*]*$/;

    if(dataItem.Description=="" || dataItem.Description==undefined){
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    
    return true;
  }
  
  
  const handleSubmit = async () => {

    if(isValid()){

      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Description : dataItem.Description,  
        Archive: dataItem.Archive ?? false,
        }
      console.log(saveData)
      const res = await API.saveData(ENTITYNAME.Variance,saveData);
      if(res.success){
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_VARIANCE : LOGEVENT.UPDATE_VARIANCE, module: MODULE.VARIANCE, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.refresh();
        props.cancelEdit();
      }else{
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      
    }
  }

  return (
    <>
      <div>
        <BossDialog
          title={props.item.SID > 0 ? props.item.Description : `${lang.create_variance_dialog_header}`}
          onClose={props.cancelEdit}
          width="800px"
          height="620px"
        >
          {showForm && <Form
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader title={(props.item.SID > 0 ? dataItem.Description: `${lang.new_title_editpageheader}`)} onSubmit={handleSubmit} onCancel={props.cancelEdit}/>
                <div className="row">
                  <div className="col-12">

                    <div className="row mt-2">
                      <div className="col-12">
                        <label>{lang.description_label} *</label>
                       <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Description"} onChange={handleChange} value={dataItem.Description} />
                       </div>                     
                    </div>                   
                    <div className="row mt-2">
                   
                      <div className="col-3" >
                        <Field
                          name={"Archive"}
                          component={Checkbox}
                          label={lang.archive}
                          checked={dataItem.Archive}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="row ml-3 mr-3 mt-2">
                        <div className="">
                        <div style={{ display: 'flex',margin: '0px 0px 0px -02px' ,alignItems: 'center', textAlign: 'left' }}>
                            <i className="fa-solid fa-link"></i>
                            <h6 style={{ fontWeight: 600, margin: '02px 0px 0px 05px' }}>Attached Media</h6>
                        </div>
                          <Grid data={gridData} style={{ height: "45vh",width:"20wh", marginTop: "04px" }} resizable={true}>                            
                          <GridColumn field="Title" title={lang.title_column} editable={false} />
                          <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
                            <GridColumn field="MediaCategoryType.Description" title={lang.media_category_type_column} />
                            <GridColumn field="Duration" cell={TimeCell}  title={lang.duration_column} />
                          </Grid>
                          <div className='text-right'>
                            <p className='muted'>{lang.note_variance_link_from_interstitial_edit_form_label}</p>
                        </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </FormElement>
            )}
          />}
        </BossDialog>
      </div>
    </>)
};