/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../locale/useTranslation';
import * as API from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Popup } from "@progress/kendo-react-popup";
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import MyStatusCell from '../../components/MyStatusCell';
import InfoRoundButton from '../../components/InfoRoundButton';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../framework/utility/utilityProvider';
import SalesDashboardTile from '../Dashboard/SalesDashboardTile';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import { COMPANYTYPE, LOCALSTORAGE_KEY, SOCKET_ACTION, SOCKET_EVENTS } from '../../framework/constant/constant';
import digiSocket from '../../framework/socket/digiSocket';
import useDigiSocket from '../../framework/socket/useDigiFSocket';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const DigitalSignDashboardForm = () => {

    const lang = useTranslation();
    const [cards, setCards] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    const [selectedCompany, setSelectedCompany] = useState({ _id: 0, Name: "All" });
    const [companyCount, setCompanyCount] = useState(0);

    const anchor = useRef();
    const navigate = useNavigate();

    useDigiSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        switch (socketData.action) {
            case SOCKET_ACTION.DIGI_SCREEN_ON:
            case SOCKET_ACTION.DIGI_SCREEN_OFF:
                loadData();
                break;
            default:
                break;
        }
    })

    const onChange = (e) => {
        if (e.target.name == "Company") {
            setSelectedCompany(e.target.value);
            loadData({company_id : e.target.value._id});
        }
    };
    useEffect(() => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        digiSocket.emit(SOCKET_EVENTS.joinCompany, companyId);
        return () => {
            digiSocket.emit(SOCKET_EVENTS.leaveCompany, companyId);
        }
    }, []);

    useEffect(() => {
        loadData({company_id : selectedCompany._id});
    }, []);

    const loadData = async ({company_id= 0}) => {
        setIsLoading(true);
        let companiesFilter = (company_id && company_id != 0) ? [company_id] : companies?.length > 0 ? companies.map(x=>x?._id) : [];
        let res = await API.getDigitalSignDashboardData(companiesFilter);
        if (!res.success) {
            toast.error(res.message);
        } else {
            setCards(res.data.cards);
            setGridData(res?.data?.screens?.length > 0 ? res.data.screens.map(d => {
                d.activeAgo = utility.timeAgo(d?.activeAgo ?? 0);
                return d;
            }) : []);
            setCompanyCount(res?.data?.companyCount ?? 0);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchor.current && !anchor.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [show]);

    const Mystatuscell = (props) => {
        const field = props.field
        let tileBackColor = props.dataItem[field] ? "#63aa43" : "#E72929";
        let status = props.dataItem[field] ? "Active" : "Inactive";
        return <MyStatusCell title={status} tileBackColor={tileBackColor} />
    }

    return (<>
        {isLoading && <Loader height="100vh" />}
        {!isLoading && <div className='row m-2'>
            <div className='col-12'>
                <div className='flex-container'>
                    <h1 className="page-title txt-color-bludeDark" style={{ marginTop: "5px"}}>
                        <i className="fa fa-table marlr" style={{ marginTop: "10px", fontSize: "20px", color: "GrayText" }} ></i>
                        <span>{lang.digital_sign_dashboard_page_breadcrumb}</span>
                    </h1>
                    <div className="flex-container-reverse flex-item-auto" >
                        <button style={{ margin: '26px 0px', borderRadius: '18px', fontSize: '12px' }} ref={anchor} className="btn btn-primary cursor-pointer mt-2" onClick={() => setShow(!show)} > <i className="fa fa-arrow-down ml-1"></i> {lang.create_button_text} </button>
                        <Popup show={show} anchor={anchor.current} popupClass={"popup-content"} style={{ width: '13vw' }}>
                            <div className='cursor-pointer ml-2  mt-3' onClick={() => navigate("/home/digitalsignScreen", { state: { openNewEditForm: true } })}>
                                <i className={`fa fa-desktop fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_screen_button_title}
                            </div><hr />
                            <div className='cursor-pointer ml-2' onClick={() => navigate("/home/digitalSignContent", { state: { openNewEditForm: true } })}>
                                <i className={`fa fa-clipboard fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_content_button_title}
                            </div><hr />
                            <div className='cursor-pointer ml-2' onClick={() => navigate("/home/Playlist", { state: { openNewEditForm: true } })}>
                                <i className={`fa fa-play fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_playlist_button_title}
                            </div><hr />
                            <div className='cursor-pointer ml-2 mb-2' onClick={() => navigate("/home/digitalSignScreenGroup", { state: { openNewEditForm: true } })}>
                                <i className={`fa fa-object-ungroup fa-fw img-circle mr-1`} style={{ color: 'blue' }}></i>
                                {lang.new_screen_group_button_title}
                            </div>
                        </Popup>
                        {isEnterpriseLogin && <div className='col-3' style={{ fontSize: '12px', marginTop: '-9px' }} >
                            <label htmlFor="TabView">{lang.company_label}</label>
                            <span style={{ fontSize: '15px', color: 'blue' }} className='float-right cursor-pointer mr-1' onClick={() => navigate("/home/digitalSignCompany")}>{companyCount}</span>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={[
                                    { _id: 0, Name: "All" },
                                    ...companies
                                ]}
                                name="Company"
                                textField="Name"
                                dataItemKey="_id"
                                value={selectedCompany}
                                onChange={onChange}
                            />
                        </div>}
                    </div>
                </div>
                <div className='row mt-4'>
                    {cards.map((card) => {
                        return (
                            <div className={'col-3'}>
                                <SalesDashboardTile
                                    iconBackColor={card?.backgroundColor ?? "#3c83c9"}
                                    route={card?.route}
                                    count={card?.count}
                                    icon={card?.icon}
                                    status={card?.name}
                                    description={lang.view_details_label}
                                    iconStyle={{ fontSize: '32px', marginTop: '5px', marginLeft: card?.icon == "tv" ? '2px' : '8px' }}
                                    showDetails={card?.showDetails ?? false}
                                />
                            </div>
                        )
                    })}
                </div>

                <div className="row shadow mt-3">
                    <div className='col-12 mb-3'>
                        <div className='row mb-2'>
                            <div className="col m-1">
                                <div className="float-left mt-1">{lang.screen_players_label}
                                    <InfoRoundButton message="Total Screen-1 * Last 5 Screen" />
                                </div>
                                <div className='float-right mt-1'>
                                    <RoundButton title={lang.add_more_label} icon='external-link' onClick={() => window.open("/home/digitalsignScreen", "_blank")} />
                                </div>
                            </div>
                        </div>
                        <Grid data={gridData} resizable={true} className='digitalsign-dashboard-custom-grid' >
                            <GridColumn field="SerialNumber" title="Serial Number" />
                            <GridColumn field="Name" title="Name" />
                            <GridColumn field="screenDetail.orientation" title="Orientation" />
                            <GridColumn field="activeAgo" title="Active ago" />
                            <GridColumn field="active" title="Status" cell={Mystatuscell} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>}
    </>);

}

export default DigitalSignDashboardForm;