/* eslint-disable */
import { Splitter } from "@progress/kendo-react-layout";
import { memo, useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { TreeView } from "@progress/kendo-react-treeview";
import { toast } from "react-toastify";
import { ENTITYNAME, MEDIA_MANAGEMENT_FILTER_BY_OPTIONS } from "../../../framework/constant/constant";
import ManageContentCollection from "./manageContentCollection";
import ExportModal from "./component/exportModal";

const ManageContentMediaCategory = (props) => {

    const { filterData, showExport, setShowExport } = props;
    const initialDataState = {
        skip: 0,
        take: 25,
    };
    const [page, setPage] = useState(initialDataState);
    const [total, setTotal] = useState(1);

    var selectedItem = null;

    const [tree, setTree] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [originalGridData, setoriginalGridData] = useState([]);

    const [horizontalPanes, setHorizontalPanes] = useState([
        {
            size: "0%",
            resizable: false
        },
        {},

    ]);

    useEffect(() => {
        if (filterData) {
            loadData({
                page: 1,
                perPage: 25,
            });
        }
    }, [filterData])

    const loadData = async (pagination) => {

        console.log(filterData);
        setIsLoading(true);
        // if (filterData.filterBy.value === MEDIA_MANAGEMENT_FILTER_BY_OPTIONS[6].value) {
        //     setHorizontalPanes(
        //         [
        //             {
        //                 size: "20%",
        //                 collapsible: true,
        //             },
        //             {},
        //         ]
        //     )
        //     await loadTree();
        //     setIsLoading(false);
        //     return;
        // } else {
        //     setHorizontalPanes(
        //         [
        //             {
        //                 size: "0%",
        //                 resizable: false
        //             },
        //             {},
        //         ]
        //     )
        // }

        setHorizontalPanes(
            [
                {
                    size: "0%",
                    resizable: false
                },
                {},
            ]
        )

        let args = {
            filterBy: filterData.filterBy.value,
            fromDate: new Date(filterData.fromDate).getTime(),
            toDate: new Date(filterData.toDate).getTime(),
            // platformSID: filterData?.platform ? filterData.platform.SID : 0,
            // regionSID: filterData?.region ? filterData.region.SID : 0,
            // categories: filterData?.category ? filterData.category : [],
            genres: filterData?.genre ? filterData.genre : [],
            PGRating: filterData?.pgRating ? filterData.pgRating : null,
            channel: filterData?.channel ? filterData.channel.SID : 0,
            productionYear : filterData?.productionYear ? filterData.productionYear : (new Date()).getFullYear(),
            page: pagination.page ?? 1,
            perPage: pagination?.perPage ?? 25,
        }
        console.log(args);
        let manageContentRes = await API.getMediaEpisodeFromManageContent(args);
        console.log(manageContentRes);
        if(manageContentRes.success){
            setGridData(manageContentRes.data);
            setoriginalGridData(manageContentRes.data);
            setIsLoading(false);
            setTotal(manageContentRes.pagination.total);
        }else{
            setIsLoading(false);
            toast.error(manageContentRes.message);
        }
    }

    const pageChange = async (e) => {
        setPage({
            skip: e.page.skip,
            take: e.page.take,
        });
        loadData({
            page: e.page.skip / e.page.take + 1,
            perPage: e.page.take,
        });
    };

    const loadTree = async () => {

        let seriesDataRes = await API.getData(ENTITYNAME.Series);
        let seriesData = await seriesDataRes.data.map(async data => {
            let seriesSeasonDataRes = await API.getData(ENTITYNAME.Season, { query: ['Series_Id', '=', data._id] });
            return { ...data, Name: data.Title + ` (${seriesSeasonDataRes.data.length})`, children: seriesSeasonDataRes.data.map(data => ({ ...data, Name: data.Title })) }
        })
        seriesData = await Promise.all(seriesData);
        let res = await API.getContentCategories();
        if (res.success) {
            setTree([{ Name: 'Series (' + seriesData.length + ')', children: seriesData }, { Name: 'Movies (' + res.data.length + ')', children: res.data }, { Name: 'Programs (' + res.data.length + ')', children: res.data }]);
        } else {
            toast.error(res.message);
        }
    }

    const onHorizontalSplitterChange = (event) => {
        setHorizontalPanes(event.newState);
    };

    const onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
        setTree([...tree])
    };

    const setChildren = (parentId, children) => {

        const treeLocal = [...tree];
        treeLocal.map(item => {
            item.children = item.children.map(i => {
                if (i._id === parentId) {
                    i.children = children;
                }
                return i;
            })

            return item;
        });

        setTree(treeLocal);
    }

    const onItemClick = async (event) => {
        //for highlighting selected item
        // if (selectedItem) {
        //     selectedItem.selected = false;
        // }
        // event.item.selected = true;
        selectedItem = event.item;
        setGridData([]);
        setIsLoading(true);
        var data = [];
        if (event.itemHierarchicalIndex.startsWith('0')) {
            //for series
            var newData = [];
            if (selectedItem.Series_Id) {
                console.log('Series all season data');
                let resMedia = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Season_id', '=', selectedItem._id] });
                newData = resMedia.data.map(data => ({ ...data, Name: data.Title }));
            }
            else {
                console.log('Season data');
                for (let index = 0; index < selectedItem.children.length; index++) {
                    let resMedia = await API.getData(ENTITYNAME.MediaEpisode, { query: ['Season_id', '=', selectedItem.children[index]._id] });
                    newData = [...newData, ...resMedia.data.map(data => ({ ...data, Name: data.Title }))];
                }
            }
            setGridData(newData);
            setoriginalGridData(newData);
        } else {
            //for movies and programs
            let res = await API.getContentCategories(selectedItem._id);
            let resMedia = await API.getMediaFromContentCategory(selectedItem._id);

            if (event.itemHierarchicalIndex.startsWith('1')) {
                //for movies            
                if (resMedia.success) {
                    data = resMedia.data.filter(data => data.MediaCategory.SID == 3);
                }
            } else {
                //for program
                if (resMedia.success) {
                    data = resMedia.data.filter(data => data.MediaCategory.SID == 1);
                }
            }
            if (resMedia.success) {
                setGridData(data);
                setoriginalGridData(data)
            }
            if (res.success) {
                if (res.data.length > 0 && res.data[0].children.length > 0) {
                    setChildren(selectedItem._id, res.data[0].children);
                }
            }
        }

        setIsLoading(false)


    };

    //search by title
    const setSearch = (text) => {
        if (text == '') {
            setGridData(originalGridData);
        } else {
            var data = originalGridData.filter((x) => x?.Title?.toLowerCase()?.includes(text?.toLowerCase()));
            setGridData(data)
        }
    }


    return <>
        <Splitter
            panes={horizontalPanes}
            onChange={onHorizontalSplitterChange}
            style={{ height: '100%' }}>

            <div style={{ margin: '0px 0px 0px 15px', height: '95%', overflow: 'auto' }}>
                <div>
                    <h1 className="page-title txt-color-bludeDark" style={{ margin: '10px 5px' }}>
                        <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
                        <span className="h1">MEDIA</span>
                    </h1>
                </div>

                <TreeView
                    size={"large"}
                    data={tree}
                    textField="Name"
                    childrenField="children"
                    expandIcons={true}
                    onExpandChange={onExpandChange}
                    onItemClick={onItemClick}
                />

            </div>
            {isLoading ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white", height: "100%" }}>
                    <i className="fa fa-spinner fa-spin fa-2xl" />
                </div>
                :
                <ManageContentCollection setGridData={setGridData} page={page} onPageChange={pageChange} filterBy={filterData?.filterBy} data={gridData} setSearch={setSearch} setSelectedItemCount={props.setSelectedItemCount} isLoading={isLoading} totalPages={total}/>
            }
        </Splitter>

        <ExportModal show={showExport} setShow={setShowExport} data={gridData} filterQuery={filterData} />
    </>

}

export default memo(ManageContentMediaCategory);