/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as API from "../../framework/API/api";
import { EditPageHeader } from '../../components/EditPageHeader';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../locale/useTranslation';
import { ENTITYNAME, PLATFORMTYPE } from '../../framework/constant/constant';
import { utility } from '../../framework/utility/utilityProvider';
import moment from "moment";
import BossDialog from '../../components/BossDialog';

export const ContractTitleEditForm = (props) => {
    console.log(props.data)

    const blankDataItem = {
        ...props.data,
        IsExclusive: props.data?.IsExclusive ?? false,
        ExclusiveStartDate: (props.data?.IsExclusive ?? false) ? moment(new Date(props.data?.ExclusiveStartDate)).utc().format('YYYY-MM-DD')  : '',
        ExclusiveEndDate: (props.data?.IsExclusive ?? false) ? moment(new Date(props.data?.ExclusiveEndDate)).utc().format('YYYY-MM-DD')  ?? '' : '',
        PublishStartDate: moment(new Date(props.data?.PublishStartDate)).format('YYYY-MM-DD'),
        PublishEndDate: moment(new Date(props.data?.PublishEndDate)).format('YYYY-MM-DD'),
        Region: props.data.Region ?? [],
        MinGap: props.data.MinGap ?? 0
    }

    const [dataItem, setDataItem] = useState(blankDataItem)
    const [playTime, setPlayTime] = useState([]);
    const [channel, setChannel] = useState([]);
    const [region, setRegion] = useState([]);
    const [platform, setPlatform] = useState([]);
    const [currency, setCurrency] = useState([]);

    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var playTimeRes = await API.getDataLookup(ENTITYNAME.PlayTime, { sort: { Description: 1 } });
        setPlayTime(playTimeRes.data);
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        var region = await API.getDataLookup(ENTITYNAME.Region, { sort: { Description: 1 } });
        setRegion(region.data);
        var platform = await API.getDataLookup(ENTITYNAME.Platform, { sort: { Description: 1 } });
        setPlatform(platform.data);
        var currencyRes = await API.getDataLookup(ENTITYNAME.Currency, { sort: { Description: 1 } });
        setCurrency(currencyRes.data);
    }
    const onChange = (e) => {
        if (e.target.name == "IsUnlimited") {
            dataItem.Exhibitions = 0;
            dataItem.Repeats = 0;
            dataItem.PlayTimeNumber = 0;
            dataItem.PlayTime = {};
            setDataItem({ ...dataItem, IsUnlimited: !dataItem.IsUnlimited });
        }
        else if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else if (e.target.name == 'IsExclusive') {
            setDataItem({ ...dataItem, IsExclusive: !dataItem.IsExclusive, ExclusiveStartDate: '', ExclusiveEndDate: '' });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (!utility.isValidDate(dataItem.PublishStartDate)) {
            toast.error(lang.invalid_start_date, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.PublishEndDate)) {
            toast.error(lang.invalid_end_date, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.PublishStartDate >= dataItem.PublishEndDate) {
            toast.error(`${lang.end_date_should_be_greater_than_start_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        // for non-linear
        if (props.data?.PlatformType.SID == PLATFORMTYPE[1].SID  && dataItem.Platform.length == 0) {
            toast.error(lang.please_select_platform_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (props.data?.PlatformType.SID == PLATFORMTYPE[1].SID  && dataItem.Region.length == 0) {
            toast.error(`${lang.please_select_region_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        // for linear
        if (props.data?.PlatformType.SID == PLATFORMTYPE[0].SID ) {
            if (dataItem.Channel.length == 0) {
                toast.error(lang.please_select_channel_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (dataItem.Region.length == 0) {
                toast.error(`${lang.please_select_region_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (dataItem.Amount < 0) {
                toast.error(`${lang.amount_should_be_negative_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (Object.keys(dataItem.Currency).length == 0) {
                toast.error(`${lang.please_select_Currency_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            } 
            if (!dataItem.IsUnlimited && dataItem.Exhibitions < 1) {
                toast.error(`${lang.exhibition_should_not_be_zero_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (!dataItem.IsUnlimited && dataItem.Repeats < 1) {
                toast.error(`${lang.repeat_should_not_be_zero_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (!dataItem.IsUnlimited && dataItem.PlayTimeNumber < 1) {
                toast.error(`${lang.play_time_number_should_not_be_zero_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (!dataItem.IsUnlimited && Object.keys(dataItem.PlayTime).length == 0) {
                toast.error(`${lang.please_select_play_time_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (dataItem.IsExclusive && !utility.isValidDate(dataItem.ExclusiveStartDate)) {
                toast.error(lang.please_select_exclusive_start_date_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
            if (dataItem.IsExclusive && !utility.isValidDate(dataItem.ExclusiveEndDate)) {
                toast.error(lang.please_select_exclusive_end_date_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
        }
        return true;
    }

    const onSave = () => {
        if (isValid()) {
            const data = {
                ...dataItem,
                Amount: dataItem.Amount=="" ? 0 : parseFloat(dataItem.Amount),
                Exhibitions: parseInt(dataItem.Exhibitions),
                Repeats: parseInt(dataItem.Repeats),
                PlayTimeNumber: parseInt(dataItem.PlayTimeNumber),
                PublishStartDate: (new Date(dataItem.PublishStartDate)).getTime(),
                PublishEndDate: (new Date(dataItem.PublishEndDate)).getTime(),
                ExclusiveStartDate: dataItem.IsExclusive ? (new Date(dataItem.ExclusiveStartDate)).getTime() : 0,
                ExclusiveEndDate: dataItem.IsExclusive ? (new Date(dataItem.ExclusiveEndDate)).getTime() : 0,
            }
            console.log(data);
            props.saveEditData(data);
            props.onCancel();
        }
    }

    return (
        <>
            <BossDialog title={`${lang.edit_label} ${dataItem?.ContractTitle?.Title}`} onClose={props.onCancel} width={"800px"}>
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader showTitle={false} onSubmit={onSave} onCancel={props.onCancel} />
                        <div className="row mt-2">
                            <div className='col-6'>
                                <label>{lang.title_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="Title" value={dataItem.ContractTitle?.Title} disabled />
                            </div>
                            <div className='col-6'>
                                <label>{lang.asset_id_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="AssetsID" value={dataItem.ContractTitle?.AssetId} disabled />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">{lang.start_date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="PublishStartDate" value={dataItem.PublishStartDate} onChange={(e) => onChange(e)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">{lang.end_Date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="PublishEndDate" value={dataItem.PublishEndDate} onChange={(e) => onChange(e)} />
                                </div>
                            </div>
                        </div>
                        {props.data?.PlatformType.SID == PLATFORMTYPE[0].SID && <div className='row'>
                            <div className='col-6'>
                                <label>{lang.channel_label} *</label>
                                <MultiSelect
                                    style={{ backgroundColor: "white" }}
                                    data={channel}
                                    name="Channel"
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channel}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.region_label} *</label><br />
                                <MultiSelect
                                    data={region}
                                    style={{ backgroundColor: "white" }}
                                    textField="Description"
                                    dataItemKey="_id"
                                    name="Region"
                                    value={dataItem.Region}
                                    onChange={onChange}
                                />
                            </div>
                        </div>}
                        {props.data?.PlatformType.SID == PLATFORMTYPE[0].SID && <div className='row mt-2 mb-2'>
                            <div className="col-3">
                                <label htmlFor="">{lang.amount_label} *</label>
                                <input type="number" className="form-control form-control-sm" name="Amount" value={dataItem.Amount} onChange={(e) => onChange(e)} min={0}/>
                            </div>
                            <div className="col-3">
                                <label htmlFor="">{lang.revenue_share_label}</label>
                                <input type="number" className="form-control form-control-sm" name="RevShare" value={dataItem.RevShare} onChange={(e) => onChange(e)}  min={0}/>
                            </div>
                            <div className="col-3">
                                <label htmlFor="">{lang.currency_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={currency}
                                    name="Currency"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Currency}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="">{lang.minimum_gap_label} </label>
                                    <input type="Number" className="form-control form-control-sm" name="MinGap" value={dataItem.MinGap} onChange={onChange} min={0} required />
                                </div>
                            </div>
                        </div>}
                        {props.data?.PlatformType.SID == PLATFORMTYPE[1].SID && <div className='row mt-2'>
                            <div className='col-6'>
                                <label htmlFor="TabView">{lang.platform_label} *</label><br />
                                <MultiSelect
                                    style={{ backgroundColor: "white" }}
                                    data={platform}
                                    name="Platform"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Platform}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.region_label} *</label><br />
                                <MultiSelect
                                    data={region}
                                    style={{ backgroundColor: "white" }}
                                    textField="Description"
                                    dataItemKey="_id"
                                    name="Region"
                                    value={dataItem.Region}
                                    onChange={onChange}
                                />
                            </div>
                        </div>}
                        {props.data?.PlatformType.SID == PLATFORMTYPE[0].SID && <div className="row mt-2">
                            <div className="col-6">
                                <input type={"checkbox"} name={"IsUnlimited"}
                                    onChange={(e) => onChange(e)}
                                    value={dataItem.IsUnlimited}
                                    checked={dataItem.IsUnlimited} />
                                <label className="ml-1">{lang.is_unlimited_label}</label>
                            </div>
                            <div className='col-6'>
                                <input name="IsExclusive" type={"checkbox"} value={dataItem.IsExclusive} checked={dataItem.IsExclusive} onClick={onChange} onChange={() => { }} />
                                <label className='ml-1'>{lang.exclusive_label}</label>
                            </div>
                        </div>}
                        {props.data?.PlatformType.SID == PLATFORMTYPE[0].SID && <div className="row mt-2">
                            <div className="col-6">
                                {!dataItem.IsUnlimited && <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">{lang.exhibition_label} *</label>
                                            <input type="Number" className="form-control form-control-sm" name="Exhibitions" value={dataItem.Exhibitions} onChange={onChange} min="0" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">{lang.repeat_label} *</label>
                                            <input type="Number" className="form-control form-control-sm" name="Repeats" value={dataItem.Repeats} onChange={onChange} min="0" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">{lang.play_time_number_label} *</label>
                                            <input type="Number" className="form-control form-control-sm" name="PlayTimeNumber" value={dataItem.PlayTimeNumber} onChange={onChange} min="0" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="TabView">{lang.playTime_label} *</label>
                                            <DropDownList
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={playTime}
                                                name="PlayTime"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.PlayTime}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="col-6">
                                {dataItem.IsExclusive && <div className="row mb-3">
                                    <div className="col-6">
                                        <label htmlFor="">{lang.start_date_label} *</label>
                                        <input type={'date'} name="ExclusiveStartDate" value={dataItem.ExclusiveStartDate} onChange={onChange} max={dataItem.ExclusiveEndDate} className="form-control-sm"
                                            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.start_date_label}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="">{lang.end_Date_label} *</label>
                                        <input type={'date'} name="ExclusiveEndDate" onChange={onChange} value={dataItem.ExclusiveEndDate} min={dataItem.ExclusiveStartDate} className="form-control-sm"
                                            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.end_Date_label}
                                        />
                                    </div>
                                </div>}
                            </div>

                        </div>}
                    </div>
                </div>

            </BossDialog>
        </>
    )
}
