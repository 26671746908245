/* eslint-disable */
import React, { useState, useEffect } from "react";
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api";
import { ENTITYNAME, OPERATORS, LOCALSTORAGE_KEY, MEDIACATEGORIES } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import { DataHelper } from "../../framework/helper/DataHelper";
import MyMultiSelect from "../../components/MyMultiSelect";
import BossDialog from "../../components/BossDialog";

const PlanningFilter = (props) => {


    let hideFields = props.hideFields ?? false;
    let hideChannel = props.hideChannel ?? false;
    let showDefaultButton = props.showDefaultButton ?? true;
    let isPlanningSlot = props?.isPlanningSlot ?? false;

    const blankDataItem = {
        Genres: [],
        PGRating: [],
        MediaCategory: [],
        Exhibitions: { ...OPERATORS[0], Condition: 0 },
        ReleaseYear: '',
        Language: [],
        Channel: [],
        CastName: '',
        CastType: {},
        isLive: false,
        CreatedFrom: '',
        CreatedTo: '',
        MinimumDaysGapInPrimaryEvent: '',
    }

    const [isCleared, setIsCleared] = useState(true);
    const [dataItem, setDataItem] = useState(props.filterDataItem && Object.keys(props.filterDataItem).length > 0 ? props.filterDataItem : blankDataItem);
    const [openForm, setFormOpen] = useState(!showDefaultButton);
    const [genre, setGenre] = useState([]);
    const [mediaCategory, setMediaCategory] = useState([]);
    const [language, setLanguage] = useState([]);
    const [castType, setCastType] = useState([]);
    const [pgRating, setPGRating] = useState([]);
    const [template, setTemplate] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(props?.filterDataItem?.Template ?? {});
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [hideSaveTemplateCheckBox, setHideSaveTemplateCheckBox] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [channels, setChannels] = useState([]);
    const [clearFilter, setClearFilter] = useState(false);

    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
        setIsCleared(true);
    }, [props.mediaCategory]);

    const loadcombo = async () => {
        let templateRes = await API.getDataLookup(ENTITYNAME.PlanningFilterTemplate, { sort: { Name: 1 } });
        let filteredTemplate = [...templateRes.data]
        if (props.mediaCategory) {
            filteredTemplate = filteredTemplate.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == props.mediaCategory))
        }
        setTemplate(filteredTemplate);
        let genres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
        setGenre(genres.data);
        let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
        let filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == true && item.SID != MEDIACATEGORIES.Series);
        if(isPlanningSlot){
            setMediaCategory(filteredMediaCategory.filter(x => x.SID == MEDIACATEGORIES.Movie || x.SID == MEDIACATEGORIES.Program));
        }else{
            setMediaCategory(filteredMediaCategory);
        }
        if (props.disableMediaCategory) {
            setDataItem({ ...blankDataItem, MediaCategory: filteredMediaCategory.filter((x) => x.SID == props.mediaCategory) })
        }
        let language = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguage(language.data);
        let castType = await API.getDataLookup(ENTITYNAME.CastType, { sort: { Description: 1 } });
        setCastType(castType.data);
        let pGRating = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        setPGRating(pGRating.data);
        let channelRes = await API.getValidChannels(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannels(channelRes.data);
    }

    const onChange = (e) => {

        if (e.target.value) {
            setIsCleared(false);
        }
        if (e.target.name == 'Template') {
            setDataItem(e.target.value.FilterModel);
            setSelectedTemplate(e.target.value);
            setHideSaveTemplateCheckBox(true);
            return;
        }
        if (e.target.name == 'templateName') {
            setTemplateName(e.target.value);
            return;
        }
        if (e.target.name == 'isLive') {
            console.log(e.target.value);
            setDataItem({ ...dataItem, isLive: !dataItem.isLive })
            return;
        }
        if (e.target.name == 'SaveAsTemplate') {
            console.log(e.target.value);
            return;
        }
        if (e.target.name == 'Condition') {
            setDataItem({ ...dataItem, Exhibitions: { ...dataItem.Exhibitions, [e.target.name]: e.target.value } });
            return;
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        setHideSaveTemplateCheckBox(false);

    }

    const onSearchClick = () => {

        if (dataItem.ReleaseYear && dataItem.ReleaseYear.toString().length != 4) {
            toast.error(`${lang.please_enter_valid_release_year_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let shortData = DataHelper.savePlanningFilterTemplate(dataItem); 

        let finalData = {
            ...shortData,
            Template: selectedTemplate,
        }
        console.log(finalData)
        props.toGetFilterDataItem(finalData);

        setFormOpen(false);
        props.onClose && props.onClose(false)

        console.log(saveAsTemplate);

        if (saveAsTemplate) {
            onConfirmSaveTemplate();
        }
    }

    const onConfirmSaveTemplate = async () => {

        if (templateName.length < 0) {
            toast.error(`${lang.please_enter_template_name_to_save_template_planningfilter_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            let saveData = {
                SID: 0,
                user_id: utility.getValue(LOCALSTORAGE_KEY.user_id),
                Name: templateName,
                FilterModel: DataHelper.savePlanningFilterTemplate(dataItem)
            }

            console.log(saveData);

            let res = await API.saveData(ENTITYNAME.PlanningFilterTemplate, saveData);

            if (!res.success) return;
            setDataItem(res.data.FilterModel);
            setSelectedTemplate(res.data);
            setHideSaveTemplateCheckBox(true);
            setSaveAsTemplate(false);
            utility.deleteLocalStorageItem(ENTITYNAME.PlanningFilterTemplate);
            loadcombo();

        }

    }

    const onClear = () => {
        setHideSaveTemplateCheckBox(false);
        if (props.disableMediaCategory) {
            setDataItem({ ...blankDataItem, MediaCategory: mediaCategory.filter((x) => x.SID == props.mediaCategory) });
        } else {
            setDataItem(blankDataItem);
        }
        setSelectedTemplate({});
        setSaveAsTemplate(false);
        setTemplateName('');
        setIsCleared(true);
        props.onClear && props.onClear();
    }

    const iconToolTip = () => {

        if (!isCleared) {

            let mediaCategoryFilter = dataItem.MediaCategory.length != 0 ? "Media Category \r\n" : "";
            let genresFilter = dataItem.Genres.length != 0 ? "Genres \r\n" : "";
            let pgRatingFilter = dataItem.PGRating.length != 0 ? "PG Rating \r\n" : "";
            let languageFilter = dataItem.Language.length != 0 ? "Language \r\n" : "";
            let releaseDateFilter = dataItem.ReleaseYear > 0 ? "Release Date" : "";
            let isLiveFilter = dataItem.isLive ? "IsLive" : "";

            return (mediaCategoryFilter + genresFilter + pgRatingFilter + languageFilter + releaseDateFilter + isLiveFilter);
        }
        return lang.show_search_options_media_liberary_tooltip
    }

    const deleteItem = async () => {

        const res = await API.deleteData(ENTITYNAME.PlanningFilterTemplate, selectedTemplate.SID);
        console.log(selectedTemplate);
        console.log(res);
        utility.deleteLocalStorageItem(ENTITYNAME.PlanningFilterTemplate);
        onClear();
        loadcombo();
    }

    return <>
        {showDefaultButton &&
            <button style={{ border: "0px", backgroundColor: "white", height: props.height ?? '40px' }} onClick={() => setFormOpen(true)} title={iconToolTip()} aria-label="Advanced search options" >
                <div className="input-group-append" >
                    <span className="input-group-text" style={{ backgroundColor: isCleared ? "white" : "black" }}>
                        <i style={{ margin: props.disableMediaCategory ? "4px 0px" : "5px 0px", color: isCleared ? "black" : "white" }} className="fa fa-list" />
                    </span>
                </div>
            </button>}

        {openForm &&
            <BossDialog
                title={lang.search_filter_dialog_header}
                width={"600px"}
                height={props?.popUpheight ?? "685px"}
                onClose={() => {

                    if (props.onClose) {
                        props.onClose(false);
                        setClearFilter(false);
                    } else if (clearFilter) {
                        props.toGetFilterDataItem({ ...dataItem, Template: selectedTemplate });
                        setFormOpen(false);
                        setClearFilter(false);
                    }
                    else {
                        setFormOpen(false);
                        setClearFilter(false);
                    }
                }}
            >
                <div className="row">
                    <div className="col-12"> 
                        <ActionButton title={props.saveButtonName ?? lang.search_button_tooltip} name={props.saveButtonName ?? lang.search_button_text} onClick={onSearchClick} />
                        <ActionButton title={lang.clear_button_tooltip} name={lang.clear_button_text} btnColor={"danger"} onClick={() => { onClear(); setClearFilter(true); }} />
                    </div>
                </div>
                <div className='row mt-3' style={{ marginLeft: "-02px", width: "570px" }}>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.template_label} : </label>
                            </div>
                            <div className="col-7">
                                <div className="form-group">
                                    <DropDownList
                                        data={template}
                                        name={"Template"}
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={selectedTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-1">
                                {hideSaveTemplateCheckBox && <button className={`btn btn-default btn-circle btn-my`} onClick={deleteItem} type="button" title={lang.delete_button_tooltip} style={{ marginLeft: "-15px" }}>
                                    <i className="fa fa-trash" aria-hidden="true" ></i>
                                </button>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.media_category_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={mediaCategory}
                                        name={"MediaCategory"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.MediaCategory}
                                        onChange={onChange}
                                        disabled={props.disableMediaCategory ?? false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.channel_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={channels}
                                        name={"Channel"}
                                        textField="FullChannelName"
                                        dataItemKey="_id"
                                        value={dataItem.Channel}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.genre_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={genre}
                                        name={"Genres"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Genres}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.cast_and_crew_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6 form-group">
                                        <input type="sting" className="form-control form-control-sm" name="CastName" value={dataItem.CastName} onChange={onChange} />
                                    </div>
                                    <div className="col-6 form-group">
                                        <DropDownList
                                            data={castType}
                                            name={"CastType"}
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.CastType}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.release_year_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        name="ReleaseYear"
                                        value={dataItem.ReleaseYear}
                                        onChange={onChange}
                                    />
                                </div></div>
                        </div>
                        {!hideFields && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.language_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={language}
                                        name={"Language"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Language}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        {!hideFields && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.pg_rating_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={pgRating}
                                        name={"PGRating"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.PGRating}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        {!hideFields && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.exhibition_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <DropDownList
                                        data={OPERATORS}
                                        name={"Exhibitions"}
                                        textField="Description"
                                        dataItemKey="ID"
                                        value={dataItem.Exhibitions}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="Condition"
                                        value={dataItem.Exhibitions.Condition}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.is_live_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name={"isLive"}
                                        type={"checkbox"}
                                        value={dataItem.isLive}
                                        checked={dataItem.isLive}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {isPlanningSlot && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.minimum_gap_in_primary_event_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input
                                        name={"MinimumDaysGapInPrimaryEvent"}
                                        className="form-control form-control-sm"
                                        type={"number"}
                                        value={dataItem.MinimumDaysGapInPrimaryEvent}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4 mt-3">
                                <label htmlFor="TabView">{lang.created_date_label} :</label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.from_label} :</label>
                                <div className="form-group">
                                    <input
                                        name={"CreatedFrom"}
                                        className="form-control form-control-sm"
                                        type={"date"}
                                        value={dataItem.CreatedFrom}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.to_label} :</label>
                                <div className="form-group">
                                    <input
                                        name={"CreatedTo"}
                                        className="form-control form-control-sm"
                                        type={"date"}
                                        value={dataItem.CreatedTo}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {!hideSaveTemplateCheckBox && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.save_as_template_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name={"SaveAsTemplate"}
                                        type={"checkbox"}
                                        value={saveAsTemplate}
                                        onClick={() => setSaveAsTemplate(!saveAsTemplate)}
                                        checked={saveAsTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}

                        {saveAsTemplate && <div className="row">
                            <div className="col-4 mt-2">
                                <label htmlFor="TabView">{lang.template_name_label} * : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name={"templateName"}
                                        value={templateName}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </BossDialog>}
    </>
}




export default PlanningFilter;