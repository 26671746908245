import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

export const CheckboxCell = (props) => {

    const field = props.field || "";
    
    const value = props.dataItem == undefined ? false : props.dataItem[field] ?? false;

    var disable = props.disable == undefined ? props.dataItem.inEdit==undefined ? false : props.dataItem.inEdit : props.disable;
   
    return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
    style={props.style}>
     <input type={"checkbox"} disabled={disable} checked={value} style={{width : "15px",height : "15px" }} onChange={() => {props.onChange({
            dataItem:props.dataItem,
            field:props.field,
            value: !value,
          })}}/>
    </td>;
  };