import React from 'react';

const BossDialog = ({ title, children, onClose, width = 'auto', height = 'auto', closeIcon = true, zIndex = 99, style = {}, showHeader = true }) => {
  return (
    <div className="boss-dialog-overlay" style={{ zIndex: zIndex }}>
      <div className="boss-dialog" style={{ width, height, zIndex: zIndex + 1, }}>
        {showHeader && <div className="boss-dialog-header">
          <span className="boss-dialog-title">{title}</span>
          {closeIcon && <button className="boss-dialog-close" onClick={onClose}>
            &times;
          </button>}
        </div>}
        <div className="boss-dialog-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default BossDialog;
