/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Expander } from '../platformLayout/tv/Dashboard/helperComponent/ExpandableTile';
import { useNavigate } from 'react-router-dom';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MODULE, PUBLISHING_SOURCE } from '../../framework/constant/constant';
import { Checkbox } from '@progress/kendo-react-inputs';
import moment from 'moment';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell'
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import InfoRoundButton from '../../components/InfoRoundButton';
import { ContentEditForm } from '../masters/ContentEditForm';
import LanguageTranslator from '../../components/LanguageTranslator';
import { DataHelper } from '../../framework/helper/DataHelper';
import { GenreEditForm } from '../masters/GenreEditForm';
import MMImageEditForm from './MMImageEditForm';
import ImageCard from './component/ImageCard';
import BossDialog from '../../components/BossDialog';

const SeriesDetailForm = ({ dataItem, setDataItem, isArchiveChanged, setIsArchiveChanged, createSeasonEpisode, setCreateSeasonEpisode, ...props }) => {

  const [i, setI] = useState(0);
  const [content, setContent] = useState([]);
  const [seasonContent, setSeasonContent] = useState([]);
  const [episodeContent, setEpisodeContent] = useState([]);
  const [openImageBank, setOpenImageBank] = useState(false);
  const [mediaCategory, setMediaCategory] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');
  const [seasonEpisodeDataItem, setSeasonEpisodeDataItem] = useState({
    SeasonContent: {},
    SeasonNumber: 0,
    EpisodeContent: {},
    NumberOfEpisodes: 0,
    Duration: '00:00:00:00',
    SeasonCode: "",
  });
  const [openImageInput, setOpenImageInput] = useState(false);

  const defaultFormState = {
    Content: false,
  }
  const [showEditForm, setShowEditForm] = useState(defaultFormState);

  //on create new entity and edit quickEdit is false but on quickEdit click quickEdit is true
  const [openForm, setFormOpen] = useState(dataItem.SID == 0 || props.quickEdit);
  const [showTranslateForm, setShowTranslateForm] = useState(false);
  const [genres, setGenres] = useState([]);
  const [currentUserChannels, setCurrentUserChannels] = useState([]);

  const navigate = useNavigate();
  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
    if (dataItem.SID > 0) {
      loadEditData();
    }
  }, []);

  const loadcombo = async () => {
    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
    setMediaCategory(mediaCategory.data);
    let content = await API.getDataLookup(ENTITYNAME.Content, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    let filteredEpisodeContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Episode);
    let filteredSeriesContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Series);
    let filteredSeasonContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Season)
    setEpisodeContent(filteredEpisodeContent);
    // setEpisodeContent(content.data);
    setSeasonContent(filteredSeasonContent);
    setContent(filteredSeriesContent);

    let genresRes = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
    setGenres(genresRes.data)

    let userChannels = await utility.getUserChannels()
    setCurrentUserChannels(userChannels)

    if (dataItem.SID == 0) {
      if (filteredSeasonContent.length > 0) {
        const updatedContent = await API.getAndSaveNextNumber(filteredSeriesContent[0].SID, { ...filteredSeriesContent[0], isSaveNextnumber: false });
        if (!updatedContent.success) return;

        setDataItem({
          ...dataItem,
          Genres: utility.getDefaultItems(genresRes.data),
          SubGenres: utility.getDefaultItems(genresRes.data),
          AssetId: updatedContent.data.HouseNumber,
          Content: filteredSeriesContent?.length > 0 ? filteredSeriesContent[0] : {}
        })
        setSeasonEpisodeDataItem({
          ...seasonEpisodeDataItem,
          SeasonContent: filteredSeasonContent?.length > 0 ? filteredSeasonContent[0] : {},
          EpisodeContent: filteredEpisodeContent?.length > 0 ? filteredEpisodeContent[0] : {}
        })
      }
    }

  }

  const onChange = async (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });

    if (e.target.name === "Content") {
      //check 
      const updatedContent = await API.getAndSaveNextNumber(e.target.value.SID, { ...dataItem.Content, isSaveNextnumber: false });
      if (!updatedContent.success) return;
      setDataItem(() => ({ ...dataItem, [e.target.name]: e.target.value, AssetId: updatedContent.data.HouseNumber }));
    }
    if (e.target.name == "Title") {
      setDataItem({ ...dataItem, Title: e.target.value, Description: e.target.value });
    }

    if (e.target.name == "Archive") {
      console.log(!isArchiveChanged);
      setIsArchiveChanged(!isArchiveChanged)
    }
  };

  //create season episode part
  const handleCreateSeasonEpisodeChange = (e) => {
    setSeasonEpisodeDataItem({ ...seasonEpisodeDataItem, [e.target.name]: e.target.value });
  }

  const onAddClick = () => {

    if (Object.keys(seasonEpisodeDataItem.SeasonContent).length == 0) {
      toast.error(`${lang.please_select_season_content_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (seasonEpisodeDataItem.SeasonNumber == undefined || seasonEpisodeDataItem.SeasonNumber < 1) {
      toast.error(`${lang.value_is_not_greater_then_zero_season_number_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(seasonEpisodeDataItem.EpisodeContent).length == 0) {
      toast.error(`${lang.please_select_epiosde_content_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (seasonEpisodeDataItem.NumberOfEpisodes == undefined || seasonEpisodeDataItem.NumberOfEpisodes < 1) {
      toast.error(`${lang.value_is_not_greater_then_zero_episode_number_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (seasonEpisodeDataItem.Duration.includes("_") || seasonEpisodeDataItem.Duration == '00:00:00:00') {
      toast.error(`${lang.invalid_duration_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (createSeasonEpisode.length != 0) {
      var checkDuplicate = createSeasonEpisode.filter((obj) => parseInt(obj.SeasonNumber) == parseInt(seasonEpisodeDataItem.SeasonNumber));
      if (checkDuplicate.length != 0) {
        toast.error(`${lang.entering_same_season_number_twice_series_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    }
    setCreateSeasonEpisode((old) => [...old, { ...seasonEpisodeDataItem, Id: i + 1, Duration: utility.convertStringWithFramesToMilliseconds(seasonEpisodeDataItem.Duration), SeasonCode: seasonEpisodeDataItem.SeasonCode + (seasonEpisodeDataItem.SeasonNumber) }]);
    setSeasonEpisodeDataItem({
      SeasonNumber: 0,
      NumberOfEpisodes: 0,
      Duration: seasonEpisodeDataItem.Duration,
      EpisodeContent: seasonEpisodeDataItem.EpisodeContent,
      SeasonContent: seasonEpisodeDataItem.SeasonContent,
      SeasonCode: seasonEpisodeDataItem.SeasonCode
    });
    setI(i + 1);
  }

  const deleteSeasonDetails = (dataItem) => {
    setCreateSeasonEpisode(createSeasonEpisode.filter((obj) => dataItem.Id != obj.Id))
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const MyCommandCell = (props) => (<div>
    <DeleteRoundButton onClick={() => deleteSeasonDetails(props.dataItem)} />
  </div>)



  const loadEditData = async () => {

    setSelectedImageUrl(dataItem.ImageUrl);

  };


  const setSelectedImage = (dataList) => {
    setSelectedImageUrl(() => dataList[0].ImageUrl);
    setDataItem({ ...dataItem, ["ImageUrl"]: dataList[0].ImageUrl });
  }

  const onCancelEdit = () => {
    if (dataItem.SID == 0) {
      navigate(-1);
    } else {
      props.quickEdit ? navigate(-1) : setFormOpen(false);
    }
  }

  function validationCheck() {

    if (dataItem.Title == "" || dataItem.Title == undefined) {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Content).length == 0) {
      toast.error(`${lang.please_select_content_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.SID == 0 && createSeasonEpisode.length == 0) {
      toast.error(`${lang.please_add_atleast_one_season_series_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true
  }

  const onSaveData = async () => {
    if (validationCheck()) {

      if (props.quickEdit) {

        props.onSave();
        onCancelEdit();

      } else {

        setFormOpen(false);

      }

    }
  };

  const onCreateMasterClick = (key) => {
    setShowEditForm({
      ...defaultFormState,
      [key]: true
    });
  }

  const handleLangugaeTranslate = (data) => {
    setDataItem({ ...dataItem, Synopsis: data });
    setShowTranslateForm(false);
  }


  return (
    <div className="card mb-3 ml-2 mt-3" style={{ maxWidth: "70vw" }}>
      <i className="fa-solid fa-pen-to-square" onClick={() => setFormOpen(true)} style={{ position: "absolute", top: "22px", right: "35px", cursor: "pointer" }}></i>
      <Expander expand={dataItem.SID == 0 ? true : false} title={dataItem.Title.length == 0 ? `${lang.enter_title_expender}` : dataItem.Title} subtext={" #" + dataItem.AssetId} img={dataItem.ImageUrl}>

        <div className='flex-container' style={{ margin: "10px" }} >
          <div>
            <ImageCard style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"250px"} height={"280px"} img={dataItem.ImageUrl} alt='Dummy' />
          </div>

          <div style={{ textAlign: "left", marginLeft: "20px" }}>
            <h1 className='d-inline' style={{ fontFamily: "Roboto", fontSize: "40px", whiteSpace: 'nowrap', fontWeight: "400", textOverflow: "ellipsis", overflow: "hidden" }} >{utility.subString(dataItem?.Title, 20)}</h1>
            {dataItem?.PGRating && dataItem?.PGRating?.Description && <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem?.PGRating?.Description}</p>}
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "16px", fontWeight: "" }}>{dataItem?.Languages?.map((obj) => obj?.Description).join(' | ')}</p>
            <h2 style={{ margin: "20px 0px 3px 0px", fontFamily: "Roboto", fontSize: "20px" }}>{lang.description_label}</h2>
            <p style={{ padding: "0px", fontWeight: "500", fontSize: "15px" }}>{utility.subString(dataItem?.Description, 60)}</p>
            <br />
          </div>
        </div>

      </Expander>
      {openForm && <BossDialog
        title={dataItem?.SID > 0 ? dataItem?.Title : `${lang.create_series_dialog_header}`}
        onClose={onCancelEdit}
        width={"960px"}
        height={dataItem?.SID > 0 ? "" : "685px"}
      >
        <div className="row mt-2 mb-2">
          <div className="marlr ml-3">
            <SaveButton title={"Confirm"} tooltip={"Confirm"} onClick={() => onSaveData()}></SaveButton>
            <CancelButton onClick={onCancelEdit}></CancelButton>

          </div>
        </div>

        <div className='row'>
          <div className='col-md-8'>
            <Form
              render={(formRenderProps) => (
                <FormElement>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-2">

                      <div className='row'>

                        <div className="form-group col-12">
                          <label htmlFor="">{lang.title_label} *</label>
                          <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.Title} onChange={onChange} required />
                        </div>
                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.content_label} *</label>
                            <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                            <DropDownList
                              data={content}
                              name="Content"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Content}
                              onChange={onChange}
                              disabled={dataItem.SID > 0 ? true : false}
                            />

                          </div>
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Content') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>
                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="">{lang.asset_id_label}</label>
                            <h4>{dataItem.AssetId}</h4>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.genre_label} *</label>
                            <MultiSelect
                              data={genres}
                              name={"Genres"}
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Genres}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.create_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>

                        <div className="col-5">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.sub_genre_label} </label>
                            <MultiSelect
                              data={genres}
                              name={"SubGenres"}
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.SubGenres}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-1" style={{ padding: '0px' }}>
                          <button type="button" title={lang.create_sub_genre_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                            style={{ height: "30px", margin: '20px 0px 0px 0px' }} onClick={() => { onCreateMasterClick('Genre') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>

                        <div className="form-group col-12">
                          <label htmlFor="">{lang.description_label} *</label>
                          <input name="Description" type="text" className="form-control form-control-sm" value={dataItem.Description} onChange={onChange} required />
                          {/* {dataItem.Description === "" ? <p style={{ color: "red" }}>* Please fill out this field. *</p> : <></>} */}
                        </div>

                        <div className="form-group col-12">
                          <label htmlFor="">{lang.synopsis_label}</label>
                          <label className='float-right cursor-pointer' onClick={() => setShowTranslateForm(true)}><i className="fa-solid fa-language" aria-hidden="true"></i></label>
                          <textarea name="Synopsis" type="text" className="form-control form-control-sm" id="Description" value={dataItem.Synopsis} onChange={onChange} required />
                          {/* {dataItem.Synopsis === "" ? <p style={{ color: "red" }}>* Please fill out this field. *</p> : <></>} */}
                        </div>

                        {dataItem.SID > 0 && <div className="form-check" style={{ marginBottom: "20px" }}>
                          <Field
                            style={{ border: "1px solid grey" }}
                            name={"Archive"}
                            component={Checkbox}
                            label={lang.archive}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Archive ? true : false}
                          />
                        </div>}

                        <div className="form-check" style={{ marginBottom: "20px" }}>
                          <Field
                            style={{ border: "1px solid grey" }}
                            name={"IsLive"}
                            component={Checkbox}
                            label={lang.islive_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.IsLive ? true : false}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </FormElement>
              )} />
          </div>
          <div className="col-md-4">
            <div className='mt-4' style={{ textAlign: "center" }}>
              <span style={{ position: "absolute", top: "0%", right: "18%", cursor: "pointer" }} >
                <i className="fa fa-pencil pr-1" title="Edit Image Url" onClick={() => setOpenImageInput(true)}></i>
                <i className="fa fa-file-text" title='Select Image' onClick={() => setOpenImageBank(true)}></i>
              </span>
              <ImageCard style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} height={"250px"} img={dataItem.ImageUrl} alt='Dummy' />

            </div>
          </div>
        </div>

        {dataItem.SID == 0 && <>
          <div className='mt-2' style={{ padding: '5px', borderRadius: '8px', backgroundColor: '#F0EBE3' }}>
            <Expander expand={false} title={lang.seasons_title} img={dataItem.ImageUrl} color={"#F0EBE3"}>
              <div className='row mt-1' style={{ border: "solid 1px", padding: "15px", margin: "0px 20px 10px 20px" }}>

                {/* <div className='row'> */}
                <div className="col-2">
                  <label htmlFor="TabView">{lang.s_content_label} *</label>
                  <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                  <DropDownList
                    style={{ backgroundColor: 'white' }}
                    data={seasonContent}
                    name="SeasonContent"
                    textField="Description"
                    dataItemKey="_id"
                    value={seasonEpisodeDataItem.SeasonContent}
                    onChange={handleCreateSeasonEpisodeChange}
                  />
                </div>

                <div className='col-2'>
                  <label htmlFor="">{lang.season_no_label} *</label>
                  <input name="SeasonNumber" type="number" className="form-control form-control-sm" value={seasonEpisodeDataItem.SeasonNumber} onChange={(e) => handleCreateSeasonEpisodeChange(e)} min={1} />
                </div>


                <div className="col-2">
                  <label htmlFor="TabView">{lang.ep_content_label} *</label>
                  <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                  <DropDownList
                    style={{ backgroundColor: 'white' }}
                    data={episodeContent}
                    name="EpisodeContent"
                    textField="Description"
                    dataItemKey="_id"
                    value={seasonEpisodeDataItem.EpisodeContent}
                    onChange={handleCreateSeasonEpisodeChange}
                  />
                </div>

                <div className='col-2'>
                  <label htmlFor="">{lang.episodes_label}</label>
                  <input name="NumberOfEpisodes" type="number" className="form-control form-control-sm" value={seasonEpisodeDataItem.NumberOfEpisodes} onChange={(e) => handleCreateSeasonEpisodeChange(e)} min={1} />
                </div>

                <div className="col-2">
                  <label htmlFor="">{lang.duration_only_label} *</label>
                  <InfoRoundButton message={lang.time_hh_mm_ss_ff_label}></InfoRoundButton>
                  <TimePickerWithFormat
                    className="form-control form-control-sm"
                    name="Duration"
                    style={{ width: '110px' }}
                    value={seasonEpisodeDataItem.Duration}
                    onChange={(e) => handleCreateSeasonEpisodeChange(e)}
                  />
                </div>
                <div className="col-2">
                  <label htmlFor="">{lang.series_code_label}</label>
                  <input name="SeasonCode" type="text" className="form-control form-control-sm" value={seasonEpisodeDataItem.SeasonCode} onChange={(e) => handleCreateSeasonEpisodeChange(e)} />
                </div>
                {/* </div> */}
                {/* <div className='row'> */}
                <div className='col-1' style={{ paddingTop: "15px" }}>
                  <AddRoundButton onClick={onAddClick}></AddRoundButton>
                </div>
                { /* </div> */}

                <div className='col-12 mt-2'>
                  <Grid data={createSeasonEpisode} style={{ height: "20vh" }}>
                    <GridColumn cell={MyCommandCell} width={"42px"} locked={true} />
                    <GridColumn field="SeasonNumber" title={lang.season_number_column} editable={false} width={"140px"} />
                    <GridColumn field="NumberOfEpisodes" title={lang.no_of_episodes_column} editable={false} width={"140px"} />
                    <GridColumn field="SeasonContent.Description" title={lang.season_content_column} editable={false} width={"140px"} />
                    <GridColumn field="EpisodeContent.Description" title={lang.episode_content_column} editable={false} width={"170px"} />
                    <GridColumn field="Duration" title={lang.duration_column} editable={false} cell={TimeCell} width={"140px"} />
                    <GridColumn field="SeasonCode" editable={false} width={"140px"} />
                  </Grid>
                </div>
              </div>
            </Expander>
          </div>
          <p className='text-muted' style={{ fontSize: '11px', position: "relative", top: "20px", left: "15px" }}>{`* ${currentUserChannels && currentUserChannels.length == 1 ? `Deal Rights will automatically be created for ${currentUserChannels[0].FullChannelName}` : "Please create deal rights before saving"}`}</p>
        </>}
      </BossDialog>}
      {openImageBank && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => setOpenImageBank(!openImageBank)} setDataList={setSelectedImage} title={lang.select_image_collection_header} mode={"single"} width={"50vw"} />}

      {showEditForm.Content && <ContentEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadcombo()} filterMediaCategory={props.mediaCategory} />}
      {showTranslateForm && <LanguageTranslator onSubmit={handleLangugaeTranslate} cancelEdit={() => setShowTranslateForm(false)} />}
      {showEditForm.Genre && <GenreEditForm item={{ SID: 0 }} cancelEdit={() => setShowEditForm(false)} refresh={() => loadcombo()} />}
      {/* Image Content Popup */}
      {openImageInput && <MMImageEditForm onClose={() => setOpenImageInput(false)} selectedImageUrl={dataItem.ImageUrl} setDataList={(img) => { setSelectedImageUrl(img); setDataItem({ ...dataItem, ["ImageUrl"]: img }) }} />}
    </div>

  )
}

export default SeriesDetailForm