import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

const MyStatusCell = (props) => {

    const field = props.field;
    const value = props?.title ?? props?.dataItem?.[field] ?? '';

    let newStyle = {
        ...props.style,
        margin: "0px",
        textAlign: props.titleTextAlign ? props.titleTextAlign : "center",
    };

    if(value && value.length > 0 ){
        newStyle = {
          ...props.style,
          padding: "2px 3px 0px 3px",
          backgroundColor: props?.title == 'Complete' ? '#2ba666' : props?.title == 'N/A' ? "grey" : "red" ?? props.tileBackColor ? props.tileBackColor : "rgb(255, 255, 224)",
          height: props.titleHeight ? props.titleHeight : "23px",
          border: '0.05em solid #9a9c92', 
          borderRadius: '4px',
          textAlign: props.titleTextAlign ? props.titleTextAlign : "center",
          color: props.titleColor ? props.titleColor : "white",
          fontWeight: props.titleFontWeight ? props.titleFontWeight : "normal",
          fontSize: props.titleFontSize ? props.titleFontSize : "12px",
          verticalAlign: props.titleVerticalAlignment ? props.titleVerticalAlignment : "center",
          fontFamily: props.titleFontFamily ? props.titleFontFamily : "Roboto",
          letterSpacing: "0.6px",
          margin: "0px",
        }
    }

    return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
    style={props.style}
  >
    <p style={newStyle}>{value}</p>
  </td>
}

export default MyStatusCell;