import classNames from 'classnames';
import React from 'react'
import { Card } from 'react-bootstrap'
import { utility } from '../../../framework/utility/utilityProvider';
import { useTranslation } from "../../../locale/useTranslation";

export const TouramentCard = (props) => {
  const lang = useTranslation();
  const {tournamentData, index, onDeleteDataItem, onEditDataItem,} = props;
    
  return (
    <div>
        
    <Card  className={classNames("m-1", tournamentData.isSelected ?  "media-card-selected": "media-card")} style={{height : '120px'}} onClick={(e)=>props.onSelection(index, tournamentData)}>
            <div style={{ position: 'absolute', right: "10px", top: '0px', display: 'flex', zIndex: "99" }}>
                {!props.MenuModel  && onEditDataItem && <p className="ponter-cursor" title={lang.edit_button_tooltip} onClick={() => onEditDataItem(index, tournamentData)} ><i className="fa fa-pen text-primary" /></p>}
                {/* {!props.MenuModel && onDeleteDataItem && <p className="ponter-cursor" title={lang.delete_button_tooltip} onClick={()=>ConfirmDeleteAlert(()=>onDeleteDataItem(index, {}),()=>{})}><i className="fa fa-trash text-danger" /></p>} */}
              
                {/* {props.MenuModel &&  <CardMenuButton {...props}/>} */}
           
            </div>
            <div className='row'>
                
                <Card.Img src={tournamentData.PosterUrl} className="col-5" style={{ objectFit: "cover", height: "118px", borderTopLeftRadius : '20px', borderBottomLeftRadius:'20px'}} />
                <Card.Body title={tournamentData.Name} className='col-7 pl-0'>
                  <div style={{ fontSize: "15px", fontWeight: '500' }}>{utility.subString(tournamentData.Name, 18)}</div>
                  <Card.Text className='pl-0 pt-0'>{utility.displayArrayInPipe(tournamentData.Channels, "FullChannelName")}</Card.Text>

                  <div style={{ fontSize: "10px"}}>{tournamentData?.TournamentID ?? ''} | {tournamentData?.TournamentType?.Description ?? ''}</div>
                  <div style={{ fontSize: "10px"}}>Start date : {utility.datetimeFormatV2(tournamentData.StartDate)}</div>
                  <div style={{ fontSize: "10px"}}>End date : {utility.datetimeFormatV2(tournamentData.EndDate)}</div>
                </Card.Body>
            </div>
        </Card>

    </div>
  )
}
