/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../API/api";
import { DateOnlyCell, LocalDateTimeColumn } from "./helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "./helpercomponents/CustomGridCells/LookupCell";
import { COLUMNSTYPE, LOCALSTORAGE_KEY } from "../constant/constant";
import { ImageCell } from "./helpercomponents/CustomGridCells/ImageCell";
import { ArrayCell } from "./helpercomponents/CustomGridCells/ArrayCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../utility/utilityProvider";
import { TimeCell } from "./helpercomponents/CustomGridCells/TimeCell";
import { CheckboxCell } from "./helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "./helpercomponents/CustomGridCells/HrefCell";
import { MyToggleButton } from "./helpercomponents/CustomGridCells/MyToggleButton";
import SaveButton from "./helpercomponents/buttons/SaveButton";
import CancelButton from "./helpercomponents/buttons/CancelButton";
import { Loader } from "./helpercomponents/Loader";
import RefreshButton from "./helpercomponents/buttons/RefreshButton";
import { useNavigate } from "react-router-dom";
import ActionButton from "./helpercomponents/buttons/ActionButton";
import { EnumCell } from "./helpercomponents/CustomGridCells/EnumCell";
import { Field } from "@progress/kendo-react-form";
import { CheckBox } from "@mui/icons-material";
import { filter } from "underscore";
import BossDialog from "../../components/BossDialog";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const CollectionSelection = memo((props) => {
  const lang = useTranslation();
  const navigate = useNavigate();

  let wherestatement = props?.wherestatement;
  let andWhereStatement = props?.andWhereStatement;
  let searchByColumn = props?.searchByColumn ?? "Name"
  let showCreate = props?.showCreate ?? false;
  let path = props?.path ?? "";
  let note = props?.note ?? "";

  const [gridData, setGridData] = useState([]);
  const [originalGridData, setoriginalGridData] = useState([]);
  const [dataColumns, setDataColumns] = useState(props.customColumns ?? []);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedState, setSelectedState] = useState(
    props.seletedState ? utility.getSelectedStateCollectionObject(props.seletedState,DATA_ITEM_KEY) : {}
  );
  const [count, setCount] = useState(0);
  const gridRef = useRef();
  const per_page = props.perPage ?? 50;
  var page_take = props.page_take ?? 50;
  // var currentPage = 1
  const [currentPage, setCurrentPage] = useState(1);
  let showSelfCreated = props?.showSelfCreated ?? false;
  const [systemCreatedValue, setSystemCreatedValue] = useState(false);
  let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
  const [filter, setFilter] = useState([]);
  
  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );
  const MyDateCell = (props) => <DateOnlyCell {...props} />;
  const MyArrayCell = (props) => <ArrayCell {...props} />;
  const MyHrefCell = (props) => <HrefCell {...props} />;

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={props.entityname}
      size={"small"}
    />
  );

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  useEffect(() => {
    setIsLoading(true)
    setPage({ skip: 0, take: page_take });
    loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
  }, []);

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, query = [...filter]) => {
    try {

      let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
      let columns = [];
      if(props?.customColumns && props?.customColumns?.length > 0){
        columns = props?.customColumns
      } else {
        columns = await API.getFormData(props.entityname, currentUserId);
      }

      if (props?.data?.length > 0) {
        setoriginalGridData(props?.data);
        setGridData(props?.data);
        setCount(props?.data?.length);
        setDataColumns(props?.customColumns ?? columns);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      let json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: 1, query: wherestatement }, columns, searchText, query);
      setoriginalGridData(json.data);
      setGridData(json.data);
      setCount(json.pagination.total);
      setDataColumns(columns);

    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);

  };


  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);

    }, [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {

    if (props.mode == 'single') {
      toast.info(`${lang.select_any_one_collection_selection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

  }, []);

  //implementing search
  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      onSearchClick();
    }
  }

  const handleServerSideSearch = (e) => {
    setSearchText(() => e.target.value);

    if (e.target.value == '') {
      if (props.data && props.data.length > 0) {
        setGridData(originalGridData)
      } else {
        loaddata({ per_page: 1000 }, wherestatement, andWhereStatement, '');
      }
    }
  };

  const onSearchClick = () => {

    if (searchText != '') {
      if (props.data && props.data.length > 0) {
        let filteredData = originalGridData.filter((x) => {
          return x[searchByColumn].toLowerCase().replace(" ", "").trim().includes(searchText);
        })
        setGridData(filteredData)
      } else {
        loaddata({ per_page: 1000 }, wherestatement, andWhereStatement, searchText);
      }

    } else {

      if (props.data && props.data.length > 0) {
        setGridData(originalGridData)
      } else {
        loaddata({ per_page: 1000 }, wherestatement, andWhereStatement, '');
      }

    }

  }

  const checkIfItemExist = (item) => {

    let isFound = false;
    let existingEntries = props.existingItems ?? [];
    // console.log("checking duplicate")
    // console.log(item.SID);
    // console.log(props);
    if (props.checkDuplicate ?? false) {
      isFound = existingEntries.some(element => {
        console.log("props");
        console.log(props);
        if (element.SID === item.SID) {
          return true;
        }

        return false;
      });
    }
    // console.log("isFound");
    // console.log(isFound);
    return isFound;

  };

  //to sendDataList to RailsEditForm and to close the collection
  const onSelection = () => {
    let msg = `${lang.please_select_first_collection_error_message}`

    let selectedDataList = [];
    //loop for setting the selected obj in array
    gridData.map((obj) => {
      let key = obj.SID;
      //if true means checked
      if (selectedState[key]) {
        // checking duplicate as well
        if (!checkIfItemExist(obj)) {
          selectedDataList.push(obj);
        } else {
          msg = `${lang.already_attached_collection_error_message}`
        }
      }
    })

    if (selectedDataList.length != 0) {
      props.setDataList(selectedDataList);
      props.closeForm();
    } else {
      // alert(msg)
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  //load for infinit scroll

  const loadInfiniteData = async () => {
    setCurrentPage(currentPage + 1);
    if (count > originalGridData.length) {
      let json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage + 1, query: wherestatement }, dataColumns, searchText);
      setoriginalGridData((old) => [...old, ...json.data]);
      setGridData((old) => [...old, ...json.data]);
      setSelectedState({});
    }

  }
  const refreshCollection = () => {
    loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
    //ask
  }

  //for infinite scroll
  const scrollHandler = async (event) => {
    const e = event.nativeEvent;
    if (e.target.className.indexOf('k-grid-content') === -1) {
      return;
    }

    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
      await loadInfiniteData()
    }
  };

  const onRowDoubleClick = (event) => {
    const item = event.dataItem;

    if (!checkIfItemExist(item)) {
      props.setDataList([item], selectedState);
      props.closeForm();
    } else {
      toast.info(`${lang.duplicate_item_selected_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const onChangeSelfCreated = (e) => {
    const { name, checked,value } = e.target;
    if (name === "SelfCreated") {
      setSystemCreatedValue(checked);
      if (checked) {        
        let query = [...filter, ["addBy._id","=", currentUserId]]
        setFilter(query);
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText,query);        
      } else {
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText,[]);
        setFilter([]);
      }
    } else {
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };


  return (
    <BossDialog
      title={props.title ?? `${lang.add_dialog_header}`}
      onClose={props.closeForm}
      width={props.width ?? "75vw"}
      height={props.height ?? "auto"}
      >
      <div>
        <div className="row ml-1 mb-3" >
          <div className="col-6">
            <div className="row">
              <SaveButton title={props.addButtonTitle ?? `${lang.add_button_text}`} style={{ backgroundColor: 'green', borderRadius: "4px" }} onClick={onSelection}></SaveButton>
              <CancelButton onClick={props.closeForm}></CancelButton>
              {showCreate && <ActionButton title={lang.create_new_button_tooltip} name={lang.create_new_button_text} onClick={(e) => { e.preventDefault(); navigate(path) }} ></ActionButton>}

            </div>
          </div>
          <div className="col-6" >
            <div className="input-group input-group mb-1" >
            {showSelfCreated &&  <>
              <lebel style={{ marginTop: "9px"}}> {lang.myrepeatpattern_labe}</lebel>
            <input
                name="SelfCreated"
                type="checkbox"
                onChange={onChangeSelfCreated}
                checked={systemCreatedValue.SelfCreated}
                style={{
                  marginLeft: "10px",
                  textAlign: "right",
                  marginRight: "10px",
                }}
              /> </>}
              <RefreshButton onClick={refreshCollection} style={{ marginRight: "5px", marginTop: "3px" }} />
              <input type="text" className="form-control shadow-inset-2 pt-0 pb-0" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onKeyDown={onSearchEnter} onChange={handleServerSideSearch} />
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} onClick={onSearchClick}>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {isLoading && <Loader height={props?.Height ?? "42vh"} />}
        {!isLoading &&
          <Grid
            data={gridData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}

            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: props.mode ?? "multiple",
            }}
            ref={gridRef}
            resizable={true}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            onRowDoubleClick={onRowDoubleClick}
            // onHeaderSelectionChange={props.mode == 'single' ? () => {} : onHeaderSelectionChange}
            style={{ height: props?.height ?? "42vh" }}
            onScroll={scrollHandler}
          >
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              headerSelectionValue={
                gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
              }
              locked={true}
            />
            {dataColumns.map((column, index) => {
              if (column.type === COLUMNSTYPE.date) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDateCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={TimeCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.name === "SID") {
                return;
                //we are not returning any value for SID column
              } else if (column.type === COLUMNSTYPE.image) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ImageCell}
                    title={column.label}
                    width={column.width ?? 100}
                  />
                );
              } else if (column.type === COLUMNSTYPE.select) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDatalookupCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.toggle) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyCustomToggleButton}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.checkbox) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={CheckboxCell}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.array) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyArrayCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.href) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyHrefCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              } 
              else if (column.type === COLUMNSTYPE.localdatetime) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={LocalDateTimeColumn}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              } 
              else if (column.type === COLUMNSTYPE.enum) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={EnumCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                    sortable={true}
                  />
                );
              } 
              else {
                return (
                  column.type !== COLUMNSTYPE.hidden && (
                    <GridColumn
                      key={index}
                      field={column.name}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  )
                );
              }
            })}
          </Grid>}
      </div>
      <div className="row mt-1">
        <div className="col text-muted" style={{ fontSize: "11px" }}>{`${note}`}</div>
        <div className="col text-right">{`${lang.total_label} : ${count}`}</div>
      </div>
    </BossDialog>
  );
});

export default CollectionSelection;
