/* eslint-disable */

import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { utility } from "../../../utility/utilityProvider";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

//to show item stored in array on collection
export const ArrayCell = (props) => {

  const field = props?.field || "";
  var value = '';
  var displayValue = '';

  if(field.includes('.')){
    //For field value coming as example :- MediaCategory.Duration
    var keys = field.split('.');
    value = props.dataItem[keys[0]] ?
      props.dataItem[keys[0]][keys[1]] ? props.dataItem[keys[0]][keys[1]] : ''
    : '';
  }else{
    value = props.dataItem[field];
  }

  if (props?.format.trim() == 'length') {
    displayValue = value ? value.length : 0
  } else if (value == undefined || value.length == 0) {
    displayValue = '';
  } else {
    //Creating string works only for description key value
    value?.map((obj, index) => {
      if (obj != undefined) {
        displayValue = displayValue + `${obj[props?.format]}` + (index == value?.length - 1 ? "" : ", ");
      } else {
        displayValue = ''
      }
    })
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...useTableKeyboardNavigation(props.id)}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    style={props.style}>
    <div style={{ height: '12px' }}>
      {utility.subString(displayValue, 30)}
    </div>
  </td>;
};