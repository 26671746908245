/* eslint-disable */
import React, { useState, useEffect, useRef, memo, forwardRef, useImperativeHandle } from "react";
import * as API from "../../../framework/API/api";
import * as API_DIGI_SIGN from "../../../framework/API/api_digitalSign";
import { ContextMenu } from "primereact/contextmenu";
import { useTranslation } from "../../../locale/useTranslation";
import { COLUMNSTYPE, CONTENT_TYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, PLAYLIST_SCHEDULE_TYPE, PLAYLIST_STATUS, SOCKET_ACTION, SOCKET_EVENTS } from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import { DateOnlyCell, DateTimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import ArchiveIconCell from "../../../components/ArchiveIconCell";
import { TimeCell, TimeCellWithOutFrames, TimeHyperLinkCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import MyInfoCell from "../../../framework/forms/helpercomponents/CustomGridCells/MyInfoCell";
import { ScheduleTypeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ScheduleTypeCell";
import { SegmentCountCell } from "../../../framework/forms/helpercomponents/CustomGridCells/SegmentCountCell";
import { toast } from "react-toastify";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { getSelectedState } from "@progress/kendo-react-data-tools";
import { PlaylistHelper } from "./PaylistHelper";
import { getter } from "@progress/kendo-data-query";
import contextMenuItem from "../../../components/contextMenuItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyStatusCell from "../../../components/MyStatusCell";
import SchedulePlaylistPopup from "./SchedulePlaylistPopup";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { TimePickerWithFormat } from "../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import digiSocket from "../../../framework/socket/digiSocket";
import useDigiSocket from "../../../framework/socket/useDigiFSocket";
import { EnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import BossDialog from "../../../components/BossDialog";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const EXPAND_FIELD = "expanded";
const INTERNAL = "internal";
const idGetter = getter(DATA_ITEM_KEY);

// Custom Cell for merged cells
const CustomNoRecordsCell = (props) => {
    const { colSpan, dataItem } = props;
    if (dataItem.isDummy) {
        return (
            <div colSpan={colSpan} style={{ position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "500", height: "100%", width: "100%", border: "1px solid black", borderRadius: "10px", color: "grey" }}>
                Drop content here <i className="fa fa-photo-film pl-2" style={{ color: "grey" }} />
            </div>
        );
    }
    return <td>{props.dataItem[props.field]}</td>;
};

const PlaylistMainTreeNew = (props, ref) => {

    const { playlistId } = useParams();

    const treeListRef = useRef(null);

    const [isPlaylistLoading, _setIsPlaylistLoading] = useState(false);

    const [playlistData, _setPlaylistData] = useState([]);
    const playlistDataRef = useRef([]);
    const setPlaylistData = (data) => {
        playlistDataRef.current = data;
        _setPlaylistData({ ...data });
    }

    const [showSchedulePopup, setShowSchedulePopup] = useState(false);
    const [editDurationPopupOpen, setEditDurationPopupOpen] = useState(false);
    const [selectedState, setSelectedState] = useState({});

    const selectedAssetsRef = useRef([]);
    const [selectedAssets, _setSelectedAssets] = useState([]);
    const setSelectedAssets = (data) => {
        selectedAssetsRef.current = data;
        _setSelectedAssets(data);
    }

    const copyAssetsRef = useRef([]);
    const setCopyAssets = (data) => {
        copyAssetsRef.current = data;
    }

    const cutAssetsRef = useRef([]);
    const setCutAssets = (data) => {
        cutAssetsRef.current = data;
    }
    const selectedAssetByContextMenu = useRef(null);
    const selectedHeaderByContextMenu = useRef(null);
    const hcm = useRef(); //FOR PLAYLIST RIGHT CLICK ON HEADER: CONTEXT MENU
    const cm = useRef(); //FOR PLAYLIST RIGHT CLICK ON CHILDREN ELEMENT : CONTEXT MENU

    const lang = useTranslation();
    const playlistScrollRef = useRef();
    const scrollPosition = useRef(0);
    const navigate = useNavigate();
    const [treeListHeight, setTreeListHeight] = useState('100%');

    useDigiSocket(SOCKET_EVENTS.onSocketData, (socketData) => {
        if (socketData.module != MODULE.ALL) return; //no need to filter active user from module 

        switch (socketData.action) {
            case SOCKET_ACTION.DIGI_SCREEN_ON:
            case SOCKET_ACTION.DIGI_SCREEN_OFF:
                loaddata();
                break;
            default:
                console.log('No action found');
                break;
        }
    })

    useEffect(() => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        digiSocket.emit(SOCKET_EVENTS.joinCompany, companyId);
        return () => {
            digiSocket.emit(SOCKET_EVENTS.leaveCompany, companyId);
        }
    }, []);

    useEffect(() => {
        if (isPlaylistLoading == false) {
            treeListRef?.current?.element?.addEventListener('scroll', onTreeViewscroll);
        }

        return () => {
            treeListRef?.current?.element?.removeEventListener('scroll', onTreeViewscroll);
        }
    }, [isPlaylistLoading]);

    useEffect(() => {
        let emptyGridArea = document.getElementsByClassName('k-grid-content k-virtual-content');
        if (emptyGridArea.length > 0) {
            emptyGridArea.item(0)?.addEventListener('dragover', onEmptyGridDragOver);
            emptyGridArea.item(0)?.addEventListener('drop', onEmptyGridDrop);
        }
        return () => {
            emptyGridArea.item(0)?.removeEventListener('dragover', onEmptyGridDragOver);
            emptyGridArea.item(0)?.removeEventListener('drop', onEmptyGridDrop);
        }
    }, [playlistData]);

    // on screen RESOLUTION change
    useEffect(() => {
        let divHeight = document.getElementById('content-scroll')?.offsetHeight;
        setTreeListHeight(divHeight + 'px');
        // listen for resize event
        window.addEventListener('resize', () => {
            let divHeight = document.getElementById('content-scroll')?.offsetHeight;
            setTreeListHeight(divHeight + 'px');
        });

        return () => {
            window?.removeEventListener('resize', () => {
                let divHeight = document.getElementById('content-scroll')?.offsetHeight;
                setTreeListHeight(divHeight + 'px');
            });
        }
    }, []);

    useEffect(() => {
        if (playlistId) {
            loaddata();
        }
    }, [playlistId]);

    useImperativeHandle(ref, () => {
        return {
            onSearch(e) {
                onSearchLocal(e)
            },
            deleteOverlays(selectedOverlayAsset, overlayIds) {
                deleteOverlaysFromAsset(selectedOverlayAsset, overlayIds);
            },
            onCut() {
                onCutCopyAsset(true);
            },
            onCopy() {
                onCutCopyAsset(false);
            },
            onPaste() {
                onPasteAsset();
            },
            onDelete() {
                deletePlaylist(selectedAssetsRef.current);
            },
            onExpand(e) {
                if (e.target.value) {
                    setPlaylistData(playlistDataRef.current.map((p) => ({ ...p, [EXPAND_FIELD]: true })));
                } else {
                    setPlaylistData(playlistDataRef.current.map((p) => ({ ...p, [EXPAND_FIELD]: false })));
                }
            },
            async onPlaylistAction(dataItem, status) {
                await handlePlaylistAction(dataItem, status);
            },
            onSchedulePlaylistClick() {
                onSchedulePlaylistClick();
            }

        };
    }, []);

    useEffect(() => {
        document?.addEventListener("keyup", onKeyUp);
        return () => {
            document?.removeEventListener("keyup", onKeyUp);
        };
    }, []);

    const onEmptyGridDragOver = (e) => {
        e.preventDefault();
    }

    const onEmptyGridDrop = (e) => {
        console.log(e);
        e.preventDefault();
        if (e.toElement.className != "k-grid-content k-virtual-content") {
            return;
        }
        const dataTransfer = JSON.parse(e.dataTransfer.getData("text"));
        let isOverlay = dataTransfer.src == ENTITYNAME.DigitalSignOverlay;
        let isInternal = dataTransfer.src == INTERNAL;
        let isContentGroup = dataTransfer.src == ENTITYNAME.DigitalSignContentGroup;
        let draggedItem = dataTransfer.propsData;

        if (isOverlay) {
            toast.error('Cannot drop overlay');
            return;
        }

        console.log('here');
        console.log(dataTransfer)

        let lstItem = playlistDataRef.current.children[playlistDataRef.current.children.length - 1];

        onDrop(lstItem, draggedItem, isInternal, false, isOverlay, isContentGroup);
    }
    const onTreeViewscroll = (e) => {
        scrollPosition.current = e.target.scrollTop;
    }

    const loaddata = async () => {
        props.setStatusMessage(<><i className="fa fa-spinner fa-spin fa-lg mr-2" /><span>Loading Playlist...</span></>);
        console.log(playlistId);
        // if 0 then creating new playlist

        // Playlist load
        let playlistRes = await API_DIGI_SIGN.getPlaylist(playlistId);
        console.log(playlistRes);
        if (!playlistRes.success) return setPlaylistData(null);
        let playlistData = playlistRes?.data;
        if (playlistData) {
            setPlaylistData(playlistData);
            props.setPlaylistData(playlistData);
            props.setCompanyId(playlistData?.Company_id ? [playlistData.Company_id] : []);
            props.setTotalDevices({
                online: playlistRes.screens.OnlineScreens.length,
                offline: playlistRes.screens.OfflineScreens.length,
            });

        }

        props.setStatusMessage(<><i className="fa fa-check fa-lg" style={{ color: 'green', marginRight: '5px' }} /><span>Playlist Loaded</span></>);
    };

    const onSearchLocal = (e) => {
        const searchWord = e.target.value.toLowerCase();
        console.log(searchWord);
    }

    const onTreeExpandChange = (event) => {
        console.log(event);
        const expanded = event.value;
        // const tree = [...treeListRef.current];
        const itemId = event.dataItem._id;

        const updatedTree = playlistData.map((item) => {
            if (item._id === itemId) {
                return {
                    ...item,
                    [EXPAND_FIELD]: expanded,
                };
            } else {
                return item;
            }
        });
        setPlaylistData(updatedTree);
    };

    const onColumnResize = (event) => {
        if (treeListRef) {
            treeListRef.current.element.style.width = `100%`;
        }
    };

    const Mystatuscell = (props) => (
        <div>
            <MyStatusCell title={utility.getKeyByValue(PLAYLIST_STATUS, props.dataItem.Status)} tileBackColor="#508D4E" />
        </div>
    )


    const gridPreferenceTreeColumn = (col, isHyperLink = false) => {
        let column = {
            key: col._id + Math.random(),
            width: col.width + 'px',
            field: col.name,
            title: col.label,
            cell: col.label == "Status"
                ? Mystatuscell
                : col.label == "Info"
                    ? MyInfoCell
                    : null
        }
        switch (col.type) {
            case COLUMNSTYPE.time:
                if (col.label == "Time" && isHyperLink) {
                    return {
                        ...column,
                        cell: (props) => TimeHyperLinkCell({ ...props, onClick: onGoToSchedule })
                    };
                } else {
                    return {
                        ...column,
                        cell: TimeCell
                    };
                }
            case COLUMNSTYPE.date:
                return {
                    ...column,
                    cell: DateOnlyCell
                };
            case COLUMNSTYPE.MyScheduleTypeCell:
                return {
                    ...column,
                    cell: ScheduleTypeCell
                };
            case COLUMNSTYPE.archive:
                return {
                    ...column,
                    cell: ArchiveIconCell
                }
            case COLUMNSTYPE.segmentCountCell:
                return {
                    ...column,
                    cell: SegmentCountCell
                }
            case COLUMNSTYPE.datetime:
                return {
                    ...column,
                    cell: DateTimeCell
                }
            default:
                return column;
        }
    }

    const onAssetSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);
        let newSelectedAssets = [];

        playlistData.children.map((c) => {
            if (newSelectedState[c._id]) {
                newSelectedAssets.push(c)
            }
        })

        setSelectedAssets(newSelectedAssets);

        if (newSelectedAssets.length == 1) {
            loadOverlays();
        }
    }

    const loadOverlays = () => {
        if (selectedAssetsRef.current.length == 1) {
            props.setSelectedOverlayAssetForGrid(selectedAssetsRef.current[0]);
        }
    }

    const gridRowRenderChild = (tr, props) => {
        const trProps = {
            draggable: true,
            onDrop: (e) => {

                const dataTransfer = JSON.parse(e.dataTransfer.getData("text"));
                let isOverlay = dataTransfer.src == ENTITYNAME.DigitalSignOverlay;
                let isInternal = dataTransfer.src == INTERNAL;
                let isContentGroup = dataTransfer.src == ENTITYNAME.DigitalSignContentGroup;
                let draggedItem = dataTransfer.propsData;

                if (playlistDataRef.current.children.length == 1 && playlistDataRef.current.children[0].isDummy) {
                    if (isOverlay) {
                        toast.error('Cannot drop overlay');
                        return;
                    } else {
                        setPlaylistData({ ...playlistDataRef.current, children: [] })
                    }
                };
                if (dataTransfer.src == INTERNAL && dataTransfer.propsData.some(d => d._id == props.dataItem._id)) {
                    // toast.warn("Can't drop overlay on itself", { position: toast.POSITION.TOP_RIGHT });
                    return;
                }
                onDrop(props.dataItem, draggedItem, isInternal, false, isOverlay, isContentGroup);
            },
            onDragStart: (e) => {
                let propsData = [props.dataItem];
                if (selectedAssetsRef.current.length > 0 && selectedAssetsRef.current.some(item => item._id == props.dataItem._id)) {
                    propsData = selectedAssetsRef.current
                } else {
                    propsData = [props.dataItem];
                }
                e.dataTransfer.clearData();
                e.dataTransfer.setData(
                    "text/plain",
                    JSON.stringify({ src: INTERNAL, propsData })
                );
            },
            onDragOver: (e) => {
                e.preventDefault();
            },
            onContextMenu: (e) => {
                e.preventDefault();
                cm.current?.show(e);
                hcm.current?.hide(e);
                selectedAssetByContextMenu.current = props.dataItem;
                if (selectedAssetsRef.current.length == 0 || !selectedAssetsRef.current.some(item => item._id == props.dataItem._id)) {
                    setSelectedState({ [props.dataItem._id]: true });
                    selectedAssetsRef.current = [props.dataItem];
                }
            },
        };

        if (props.dataItem.isDummy) {
            return React.cloneElement(tr, { ...trProps }, [
                React.cloneElement(
                    tr.props.children[0],
                    {
                        ...tr.props.children[0].props,
                        colSpan: tr.props.children.length,
                        children: (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                No records found
                            </div>
                        ),
                    }
                ),
            ]);
        }
        return React.cloneElement(tr, { ...trProps }, tr.props.children);

    };

    const getContentUrl = (content) => {

        if (content.content_type == CONTENT_TYPE.Video) {
            return "https://media.comicbook.com/files/img/default-movie.png";
        } else if (content.content_type == CONTENT_TYPE.YouTube) {
            return "https://static-00.iconduck.com/assets.00/youtube-icon-512x512-80maysdk.png";
        } else {
            // FOR IMAGE CASE
            return content.url
        }

    }

    const onDrop = async (dstItem, draggedItem, isInternal = true, isDropOnHeader = true, isOverlay = false, isContentGroup = false) => {
        if (isOverlay) {
            await addOverlayToContent(dstItem, draggedItem.map(o => (o._id)));
            return;
        } else if (!isInternal) {
            if (isContentGroup) {
                const contentData = draggedItem.flatMap(({ Content }) =>
                    Content.map((d, i) => ({
                        content: { ...d, url: getContentUrl(d) },
                        playlist_id: isDropOnHeader ? dstItem._id : dstItem.playlist_id,
                        content_id: d._id,
                        overlay: [],
                        sequenceIndex: i,
                        duration: d.duration ?? 10 * 1000,
                    }))
                );
                draggedItem = contentData;
            } else {
                draggedItem = draggedItem.map((d, i) => ({ content: { ...d, url: getContentUrl(d) }, playlist_id: isDropOnHeader ? dstItem._id : dstItem.playlist_id, content_id: d._id, overlay: [], sequenceIndex: i, duration: d.duration ?? 10 * 1000 }));
            }
        } else {
            let playlistDataCopy = playlistDataRef.current;
            draggedItem.map(d => {
                let contentIndex = PlaylistHelper.getPlaylistIndex(d, playlistDataRef.current);
                playlistDataCopy.children.splice(contentIndex, 1);
            });
            setPlaylistData(playlistDataCopy);
        }
        await addPlaylist(dstItem, draggedItem, true);
    }

    const addOverlayToContent = async (dstItem, overlay) => {
        let playlistDataCopy = playlistDataRef.current;
        let playlistDataCopyOld = playlistDataRef.current;
        let contentIndex = PlaylistHelper.getPlaylistIndex(dstItem, playlistDataRef.current);
        let oldOverlays = playlistDataCopy.children[contentIndex].overlay.filter(o => overlay.some(o2 => o != o2));
        playlistDataCopy.children[contentIndex].overlay = [...oldOverlays, ...overlay];
        let updatedDstItem = playlistDataCopy.children[contentIndex];
        let saveData = { _id: updatedDstItem._id, SID: updatedDstItem.SID, playlist_id: updatedDstItem.playlist_id, content_id: updatedDstItem.content_id, sequenceIndex: updatedDstItem.sequenceIndex, overlay: updatedDstItem?.overlay ?? [] };
        setPlaylistData(playlistDataCopy);
        let saveRes = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, saveData);
        if (saveRes.success) {
            setTimeout(() => {
                props.setSelectedOverlayAssetForGrid(playlistDataCopy.children[contentIndex]);
            }, 500);
            toast.success("Overlay added", { position: toast.POSITION.TOP_RIGHT });
        } else {
            setPlaylistData(playlistDataCopyOld);
            toast.error(saveRes.message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const onKeyUp = (e) => {
        e.preventDefault();
        if (e.ctrlKey && e.key.toLowerCase() == "x") {
            onCutCopyAsset(true);
        }
        else if (e.ctrlKey && e.key.toLowerCase() == "c") {
            onCutCopyAsset(false);
        }
        else if (e.ctrlKey && e.key.toLowerCase() == "v") {
            onPasteAsset();
        }
        else if (e.ctrlKey && e.key.toLowerCase() == "z") {
            onUndo();
        }
        else if (e.keyCode == 46 || e.key == "Delete") {
            deletePlaylist(selectedAssetsRef.current);
        }
        else if (e.keyCode == 27 || e.key == "Escape") {
            removeSelection();
        }
        else if (e.keyCode == 116 || e.key == "F5") {
            props.onPlaylistRefresh();
        }
    }

    const onUndo = () => {
        if (cutAssetsRef.current.length == 0) {
            toast.warn('No items to undo', { position: toast.POSITION.TOP_RIGHT });
            return
        }
        let playlistDataCopy = playlistDataRef.current;
        cutAssetsRef.current.map((a) => {
            playlistDataCopy.children.push(a);
        });
        setPlaylistData(playlistDataCopy);
        resetCutCopyItemsToBlank();
        removeSelection();
    }

    const resetCutCopyItemsToBlank = () => {
        setCutAssets([]);
        setCopyAssets([]);
        props.setCopyPasteCount({
            cut: 0,
            copy: 0
        });
    }

    const removeSelection = () => {
        setSelectedAssets([]);
        setSelectedState({});
        props.setSelectedState([]);
    }
    const onCutCopyAsset = (isCut = false) => {
        if (cutAssetsRef.current.length > 0) {
            toast.warn('First paste cut items or undo cut (ctrl + z)', { position: toast.POSITION.TOP_RIGHT });
            return
        }
        if (isCut) {
            setCopyAssets([]);
            setCutAssets(selectedAssetsRef.current);
            props.setCopyPasteCount({
                cut: selectedAssetsRef.current.length,
                copy: 0
            })
            let playlistDataCopy = playlistDataRef.current;
            selectedAssetsRef.current.map((a) => {
                let assetIndex = PlaylistHelper.getPlaylistIndex(a, playlistDataRef.current);
                playlistDataCopy.children.splice(assetIndex, 1);
            });
            setPlaylistData(playlistDataCopy);
            removeSelection();
        } else {
            props.setCopyPasteCount({
                copy: selectedAssetsRef.current.length,
                cut: 0
            })
            setCutAssets([]);
            setCopyAssets(selectedAssetsRef.current);
        }
    }

    const onPasteAsset = () => {
        if (cutAssetsRef.current.length == 0 && copyAssetsRef.current.length == 0) {
            toast.warn('No items to paste', { position: toast.POSITION.TOP_RIGHT });
            return
        }
        if (selectedAssetsRef.current.length > 1 || selectedAssetsRef.current == 0) {
            toast.warn('Please select one item to paste below it', { position: toast.POSITION.TOP_RIGHT });
            return
        }
        const isCut = cutAssetsRef.current.length > 0;
        if (isCut) {
            addPlaylist(selectedAssetsRef.current[0], cutAssetsRef.current, true);
        } else {
            addPlaylist(selectedAssetsRef.current[0], copyAssetsRef.current);
        }

    }

    const deletePlaylist = async (dataToDelete) => {
        if (dataToDelete.length == 0) {
            toast.warn('No items to delete', { position: toast.POSITION.TOP_RIGHT });
            return
        }
        console.log('in delete playlist', dataToDelete);
        let playlistDataCopy = playlistDataRef.current;
        let playlistDataCopyOld = playlistDataRef.current;
        dataToDelete = dataToDelete.map((d) => {
            let assetIndex = PlaylistHelper.getPlaylistIndex(d, playlistDataCopy);
            playlistDataCopy.children.splice(assetIndex, 1);
            return d._id;
        });
        console.log(dataToDelete);
        setPlaylistData(playlistDataCopy);
        let deleteRes = await API.deleteEntities(ENTITYNAME.DigitalSignPlaylistItem, dataToDelete);
        console.log(deleteRes);
        if (deleteRes.length > 0 && deleteRes[0].success) {
            removeSelection();
            toast.success('Assets deleted', { position: toast.POSITION.TOP_RIGHT });
            if (playlistDataCopy.children.length == 0) {
                playlistDataCopy.children = [{ isDummy: true }];
            }
            setPlaylistData(playlistDataCopy);
        } else {
            setPlaylistData(playlistDataCopyOld);
            toast.error(deleteRes.message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const addPlaylist = async (dstItem, dataToAdd, isCut = false) => {
        let playlistDataCopy = playlistDataRef.current;
        let playlistDataCopyOld = playlistDataRef.current;
        let contentIndex = PlaylistHelper.getPlaylistIndex(dstItem, playlistDataRef.current);
        console.log(contentIndex)

        console.log(dataToAdd)
        let modifiedData = dataToAdd.map((d, i) => {
            if (isCut) {
                return { _id: d._id, SID: d.SID, playlist_id: playlistDataCopy._id, content_id: d.content_id, content: d.content, sequenceIndex: i, overlay: d?.overlay ?? [], modified: true, duration: d?.duration };
            } else {
                return { playlist_id: playlistDataCopy._id, content_id: d.content_id, sequenceIndex: i, content: d.content, overlay: d?.overlay ?? [], modified: true, duration: d?.duration };
            }
        });

        playlistDataCopy.children.splice(((contentIndex != null) ? contentIndex + 1 : 0), 0, ...modifiedData);

        if (isCut) {
            modifiedData = playlistDataCopy.children.map((d, i) => {
                return { _id: d._id, SID: d.SID, playlist_id: playlistDataCopy._id, content_id: d.content_id, sequenceIndex: i, overlay: d?.overlay ?? [], duration: d?.duration };
            });
        } else {
            modifiedData = playlistDataCopy.children.map((d, i) => {
                if (d._id || d.SID) {
                    return { _id: d._id, SID: d.SID, playlist_id: playlistDataCopy._id, content_id: d.content_id, sequenceIndex: i, overlay: d?.overlay ?? [], duration: d?.duration };
                } else {
                    return { playlist_id: playlistDataCopy._id, content_id: d.content_id, sequenceIndex: i, overlay: d?.overlay ?? [], duration: d?.duration };
                }
            });
        }
        modifiedData = modifiedData.sort((a, b) => a.sequenceIndex - b.sequenceIndex);
        playlistDataCopy.children = playlistDataCopy.children.map((d, i) => {
            return { ...d, sequenceIndex: i };
        });
        setPlaylistData(playlistDataCopy);
        console.log(modifiedData);
        props.setStatusMessage(<><i className="fa fa-spinner fa-spin fa-lg mr-2" /><span>Saving Playlist...</span></>);
        let saveRes = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, modifiedData);
        if (saveRes.success) {
            saveRes.data.map((d, i) => {
                if (d.message == 'inserted') {
                    playlistDataCopy.children[i]._id = d.response.insertedId;
                }
            });
            props.setStatusMessage(<><i className="fa fa-check fa-lg" style={{ color: 'green', marginRight: '5px' }} /><span>Saved Playlist</span></>);
            setPlaylistData(playlistDataCopy);
        } else {
            props.setStatusMessage(saveRes.message);
            toast.error(saveRes.message, { position: toast.POSITION.TOP_RIGHT });
            setPlaylistData(playlistDataCopyOld);
        }
        resetCutCopyItemsToBlank();
        removeSelection();
    }

    const hideContextMenus = (e) => {
        hcm.current?.hide(e);
        cm.current?.hide(e);
    }

    const deleteOverlaysFromAsset = async (selectedOverlayAsset, overlayIds) => {
        console.log(selectedOverlayAsset);
        console.log(overlayIds);
        let playlistDataCopy = playlistDataRef.current;
        let playlistDataCopyOld = playlistDataRef.current;
        let contentIndex = PlaylistHelper.getPlaylistIndex(selectedOverlayAsset, playlistDataCopy);
        if (contentIndex == null) {
            toast.error('Selected content is not in playlist', { position: toast.POSITION.TOP_RIGHT });
            props.setSelectedOverlayAssetForGrid(null);
            return;
        }

        let updatedOverlays = playlistDataCopy.children[contentIndex].overlay.filter(o => !overlayIds.includes(o));
        playlistDataCopy.children[contentIndex].overlay = updatedOverlays;
        setPlaylistData(playlistDataCopy);
        let res = await API_DIGI_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, playlistDataCopy.children[contentIndex]);
        if (res.success) {
            toast.success('Successfully deleted overlay', { position: toast.POSITION.TOP_RIGHT });
        } else {
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
            setPlaylistData(playlistDataCopyOld);
        }
    }



    const handlePlaylistAction = async (dataItem = playlistDataRef.current, status) => {

        let res = await PlaylistHelper.handlePlaylistAction(dataItem, status);
        if (res.success) {
            loaddata();
        } else {
            console.log(res)
        }

    }

    const onSchedulePlaylistClick = () => {
        setShowSchedulePopup(true);
    }

    const onPlaylistItemDurationChange = (e) => {

        let updatedDuration = utility.convertStringWithFramesToMilliseconds(e.target.value);
        setSelectedAssets([{ ...selectedAssetsRef.current[0], duration: updatedDuration }]);

    }

    const onEditDuration = async () => {

        console.log(selectedAssetsRef.current)

        if (selectedAssetsRef.current[0].duration <= 0) {
            toast.error(`Invalid Duration `, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        var updateRes = await API.saveData(ENTITYNAME.DigitalSignPlaylistItem, {
            _id: selectedAssetsRef.current[0]._id,
            duration: selectedAssetsRef.current[0].duration,
        });

        setEditDurationPopupOpen(false);

        if (updateRes.success) {

            let playlistDataCopy = { ...playlistDataRef.current };

            let index = PlaylistHelper.getPlaylistIndex(selectedAssets[0], playlistDataCopy);
            playlistDataCopy.children[index].duration = selectedAssets[0].duration;
            setPlaylistData(playlistDataCopy);


            toast.success(`Updated successfully `, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else {
            toast.error(`error:` + updateRes.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    }

    return (<>
        <div id="playlistItemView" style={{ height: "100%" }}>
            {
                isPlaylistLoading ? <div className="ml-2 mt-2">
                    <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
                </div> :
                    <div id="content-scroll" ref={playlistScrollRef} className="content-scroll" style={{ height: "100%" }} >
                        {playlistData.length == 0 ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>No Data Found</div> :
                            <Grid
                                style={{
                                    height: "100%",
                                }}

                                data={playlistData?.children?.map((item) => {
                                    return { ...item, overlayCount: "Overlays: " + (item?.overlay?.length ?? 0) };
                                })?.sort((a, b) => a.sequenceIndex - b.sequenceIndex)?.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                })) ?? []}
                                rowRender={gridRowRenderChild}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: true,
                                    cell: false,
                                    mode: {
                                        value: "multiple",
                                        label: "Multiple selection mode",
                                    },
                                }}
                                onSelectionChange={onAssetSelectionChange}
                            >
                                {playlistData?.children?.length > 0 && !playlistData?.children[0]?.isDummy && <GridColumn
                                    title={""}
                                    width={50}
                                    cell={(props) => {
                                        return <div className="mt-4" style={{ textAlign: "center", color: "#484342", cursor: "move" }}><i className="fa fa-bars fa-lg" /></div>
                                    }}
                                />}
                                <GridColumn
                                    title={" "}
                                    field="content.thumbnailUrl"
                                    width={80}
                                    cell={(props) => {
                                        return playlistData?.children?.length == 1 && playlistData?.children[0]?.isDummy ? <CustomNoRecordsCell {...props} /> : <ImageCell {...props} />
                                    }}
                                />
                                <GridColumn title={lang.title_column} field="content.fileName" width={200} />
                                <GridColumn title={lang.description_column} field="content.fileName" />
                                <GridColumn title={lang.duration_column} field="duration" cell={TimeCellWithOutFrames} />
                                <GridColumn title={lang.type_column} field="content.resource_type" />
                                <GridColumn title={lang.overlays_column} field="overlayCount" />

                            </Grid>
                        }
                        <ContextMenu
                            ref={cm}
                            model={[
                                {
                                    label: `${lang.cut_actionbutton_menutext}`,
                                    icon: "fa fa-cut fa-lg",
                                    shortcut: "Ctrl + X",
                                    command: (e) => {
                                        hideContextMenus(e);
                                        onCutCopyAsset(true);
                                    },
                                    template: contextMenuItem
                                },
                                {
                                    label: `${lang.copy_actionbutton_menutext}`,
                                    icon: "fa fa-copy fa-lg",
                                    shortcut: 'Ctrl + C',
                                    command: (e) => {
                                        hideContextMenus(e);
                                        onCutCopyAsset(false);
                                    },
                                    template: contextMenuItem
                                },
                                {
                                    label: `${lang.paste_actionbutton_menutext}`,
                                    icon: "fa fa-paste fa-lg",
                                    shortcut: 'Ctrl + V',
                                    command: (e) => {
                                        hideContextMenus(e);
                                        onPasteAsset();
                                    },
                                    template: contextMenuItem
                                },
                                {
                                    label: `Undo`,
                                    icon: "fa fa-undo fa-lg",
                                    shortcut: 'Ctrl + Z',
                                    command: (e) => {
                                        hideContextMenus(e);
                                        onUndo();
                                    },
                                    template: contextMenuItem
                                },
                                {
                                    label: `${lang.delete_actionbutton_menutext}`,
                                    icon: "pi pi-trash",
                                    shortcut: 'del',
                                    command: (e) => {
                                        hideContextMenus(e);
                                        deletePlaylist(selectedAssetsRef.current);
                                    },
                                    template: contextMenuItem
                                },
                                {
                                    label: `${lang.editduration_actionbutton_menutext}`,
                                    icon: "pi pi-pencil",
                                    command: (e) => {
                                        hideContextMenus(e);

                                        if (!selectedAssetsRef.current || !selectedAssetsRef.current[0]) {
                                            toast.error("Select item to edit duration")
                                            return;
                                        }
                                        if (selectedAssetsRef.current.length > 1) {
                                            toast.error("Select only one Item")
                                            return;
                                        }
                                        if (selectedAssetsRef.current.length == 1 && selectedAssetsRef.current[0].content.content_type == CONTENT_TYPE.Video) {
                                            toast.error("Cannot Edit Duration for Video")
                                            return;
                                        }

                                        console.log(selectedAssetsRef.current)
                                        setEditDurationPopupOpen(true)
                                    },
                                    template: contextMenuItem
                                },
                                {
                                    label: `Show Overlays`,
                                    icon: "pi pi-eye",
                                    command: (e) => {
                                        hideContextMenus(e);
                                        props.setSelectedOverlayAssetForGrid(selectedAssetByContextMenu.current);
                                    },
                                    template: contextMenuItem
                                },
                            ]}
                        />
                    </div>
            }
        </div>
        {showSchedulePopup && <SchedulePlaylistPopup playlistItem={playlistDataRef.current} close={() => setShowSchedulePopup(false)} refresh={loaddata} />}

        {editDurationPopupOpen && (
            <BossDialog
                title={'Edit Duration'}
                width={"300px"}
                onClose={() => setEditDurationPopupOpen(false)}
            >
                <>
                    <EditPageHeader title={""} onSubmit={onEditDuration} onCancel={() => setEditDurationPopupOpen(false)} showTitle={false} />

                    <div className="form-group">
                        <label htmlFor="">{lang.playlist_duration_label} *</label>
                        <TimePickerWithFormat
                            name="Duration"
                            value={selectedAssetsRef.current.length > 0 ? utility.convertMilisecondsToStringWithFrames((selectedAssetsRef.current[0].duration ?? 0)) : '00:00:00:00'}
                            onChange={onPlaylistItemDurationChange}
                            isWithFrames={false}
                            required
                        />

                    </div>
                </>
            </BossDialog>
        )}
    </>);
};

export default memo(forwardRef(PlaylistMainTreeNew));
