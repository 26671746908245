import { useState, useEffect } from "react";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ASSETTYPE, ENTITYNAME, OTTROUTETYPE } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api'
import { toast } from "react-toastify";
import MediaEpisodeAssetEditForm from "../../MediaEpisodeAssetEditForm";
import { useTranslation } from "../../../../locale/useTranslation";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";
import { utility } from "../../../../framework/utility/utilityProvider";
import BossDialog from "../../../../components/BossDialog";



const OttAssesTab = (props) => {

  const none = "N/A";

  const [showEdit, setShowEdit] = useState(false);
  const boxStyle = { margin: "10px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white", wordWrap: "break-word" }
  const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
  const subHeadingStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 0", padding: 0 }
  const subHeadingDetailStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: 0, marginBottom: "25px" }
  const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px 5px" }

  const lang = useTranslation();
  const { mediaEpisode } = props;
  const [ottAsset, setOttAsset] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    setLoading(true);


    let ottAssetRes = await API.getData(ENTITYNAME.OttAsset, { query: [['Source._id', '=', mediaEpisode._id], ['Source.entityName', '=', ENTITYNAME.MediaEpisode]] });
    if (ottAssetRes.success && ottAssetRes.data.length > 0) {
      setOttAsset(ottAssetRes.data[0]);
    }

    setLoading(false);
  }

  const onClose = () => {
    setShowEdit(!showEdit);
  }

  const onSave = async () => {

    if(mediaEpisode?.IsOttAsset){
      if(!isValidAsset()) return; 
    }

    let saveData = {
      ...ottAsset,
      Source: {
        _id: mediaEpisode._id, SID: mediaEpisode.SID, Title: mediaEpisode.Title, MediaCategory: mediaEpisode.MediaCategory, entityName: ENTITYNAME.MediaEpisode
      }
    };


    await API.saveData(ENTITYNAME.MediaEpisode, { _id: mediaEpisode._id, IsOttAsset: ottAsset?.IsOttAsset ?? false });
    delete saveData.IsOttAsset;
    await API.saveData(ENTITYNAME.OttAsset, saveData);

    onClose();

  }

  const isValidAsset = () => {

    if ((ottAsset.routeType == OTTROUTETYPE.Page || ottAsset.routeType.Description == 'Page') && (ottAsset.page_id == "" || ottAsset.page_id == undefined)) {
      toast.error(`${lang.please_select_page}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((ottAsset.routeType == OTTROUTETYPE.Player || ottAsset.routeType.Description == 'Player')
      && (ottAsset.VideoPlaybackUrl == "" || ottAsset.VideoPlaybackUrl == undefined)) {
      toast.error(`${lang.please_select_video_playback_url}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  //asset
  const setAssetEntity = (ottAsset) => {
    setOttAsset(ottAsset);
  }

  console.log(ottAsset);

  return (
    <>
      {/* Genral */}
      <div style={boxStyle}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
          <h1 style={headingStyle}>Ott Asset</h1>
          <RoundButton icon={'pen'} title={'Edit'} onClick={onClose} />
        </div>
        {loading ? <div style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
          <i className="fa fa-spinner fa-spin fa-xl " />
        </div> :
          !mediaEpisode?.IsOttAsset
            ?
            <div>
              <p style={subHeadingDetailStyle}>This assest is not enabled for VOD. Please edit and enable it for Ott Asset.</p>
            </div>
            :
            <div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <div>
                            <h2 style={subHeadingStyle}>{lang.asset_type_label}</h2>
                            <p style={subHeadingDetailStyle}>{utility.getEnumValue( ASSETTYPE, ottAsset?.OttAssetTypeSID) ?? none}</p>
                            <h2 style={subHeadingStyle}>{lang.vodtype_label}</h2>
                            <p style={subHeadingDetailStyle}>{ottAsset?.VodTypes?.Name ?? none}</p>
                            <h2 style={subHeadingStyle}>{lang.access_level_label}</h2>
                            <p style={subHeadingDetailStyle}>{ottAsset?.accessLevel?.Description ?? none}</p>
                            <h2 style={subHeadingStyle}>{lang.provider_label}</h2>
                            <p style={subHeadingDetailStyle}>{ottAsset?.Provider?.Description ?? none}</p>
                        </div>
                        <div>
                            <h2 style={subHeadingStyle}>{lang.route_type_label}</h2>
                            <p style={subHeadingDetailStyle}>{utility.getEnumValue(OTTROUTETYPE, ottAsset?.routeType) ?? none}</p>
                            <h2 style={subHeadingStyle}>{lang.posters_label}</h2>
                            <p style={subHeadingDetailStyle}>{ottAsset?.Posters?.length ?? none}</p>
                            <h2 style={subHeadingStyle}>{lang.videos_label}</h2>
                            <p style={subHeadingDetailStyle}>{ottAsset?.Videos?.length ?? none}</p>
                        </div>
                    </div>
            </div>
        }

      </div>



      {showEdit && <BossDialog
        title={'Edit Ott Asset'}
        onClose={onClose}
        width={"84vw"}
        height={"75vh"}
      >
        <SaveButton onClick={onSave}></SaveButton>
        <CancelButton onClick={onClose}></CancelButton>
        <div className="mt-3"></div>
        <MediaEpisodeAssetEditForm dataItem={{...ottAsset, IsOttAsset: mediaEpisode?.IsOttAsset}} source={ENTITYNAME.MediaEpisode} sourceEntity={mediaEpisode} entityname={ENTITYNAME.OttAsset} setDataEntity={setAssetEntity} />
      </BossDialog>
      }
    </>
  )
}

export default OttAssesTab;