import { useRef, useState } from "react";
import ManageContentHeader from "./manageContentHeader";
import ManageContentMediaCategory from "./manageContentMediaCategory";
import ManageContentFoooter from "./manageContentFooter";

const ManageContent = () => {

    const [filterData, setFilterData] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const footerRef = useRef();

    return (<>
        <ManageContentHeader setFilterData={setFilterData} showExport={showExport} setShowExport={setShowExport} />
        <div style={{ height: "75vh" }}>
            <ManageContentMediaCategory showExport={showExport} setShowExport={setShowExport} filterData={filterData} setSelectedItemCount={footerRef.current?.setSelectedItemCount} />
        </div>
        <ManageContentFoooter ref={footerRef} />
    </>)
}

export default ManageContent;