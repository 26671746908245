/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../../framework/API/api";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, OFFSET, OPERATORS } from "../../../framework/constant/constant";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { UserPreference } from "../../userPreference/UserPreference";
import { utility } from "../../../framework/utility/utilityProvider";
import { DateCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { LookupCell } from "../../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { ScheduleFilter } from "../SchedulingFilter";
import { useTranslation } from "../../../locale/useTranslation";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { SchedulingHelper } from "../helper/SchedulingHelper";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";

export const ScheduleMediaCollectionNew = (props) => {

    const lang = useTranslation();
    //possible props
    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    const offsetTime = offSetHours * 3600000;
    const initialSort = { field: 'SID', dir: 'asc' };
    const [sort, setSort] = useState([initialSort])

    const [selectedState, setSelectedState] = useState([]);// multiple selection grid
    var filterByDate = props.filter ?? true;
    var page_take = props.page_take ?? 50;
    // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
    const [gridDataToShow, setGridDataToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [preferenceState, setPreferenceState] = useState({});
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);

    const [template, setTemplate] = useState([]);
    const [selectedTemplate,setSelectedTemplate] = useState({});

    const [showFilterDropdown,setShowFilterDropDown] = useState(false);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [showTemplateDropDown,setShowTemplateDropDown] = useState(false);

    const [isFilterCleared, setIsFilterCleared] = useState(true);
    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });
    const [total, setTotal] = useState(1);
    const [footerItem, setFooterItem] = useState({
        SelectedItems: 0,
        TotalDuration: 0,
    })



    var blankFilterDataItem = {
        MediaCategory: [],
        MediaCategoryType: [],
        Duration: { ...OPERATORS[0], Condition: 0 },
        Brand: [],
        Product: [],
    }
    const [filterDataItem, setFilterDataItem] = useState(blankFilterDataItem);
    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';


    useEffect(() => {
        loadcombo();
        loaddata(searchText, filterDataItem, { per_page: page.take, current_page: 1, orderby: 'SID', direction: 'asc' })
    }, [props.selectedScheduleDate, props.selectedChannelSID])

    const pageChange = async (e) => {
        var current_page = e.page.skip / e.page.take + 1;
        let sortEvent = sort[0] ?? initialSort

        await loaddata(searchText, filterDataItem, { per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir });
        setPage(e.page);
    };

    const loadcombo = async() => {
        let templateRes = await API.getDataLookup(ENTITYNAME.SchedulingFilterTemplate, { sort: { Name: 1 } });
        setTemplate(templateRes.data);
    }



    const loaddata = async (searchText, filters, pagination) => {
        setIsLoading(true);

        // making selection blank on loading media library
        props.setSelectedMedia([]);
        setFooterItem({ SelectedItems: 0, TotalDuration: 0 })
        setSelectedState([])

        var payload = SchedulingHelper.getPayload(props.selectedScheduleDate, props.selectedChannelSID, offsetTime)

        try {
            var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            var columns = await API.getFormData(props.entityname, currentUserId);
            var json = await API.loadSchedulingMediaLibrary(searchText, filters, payload, filterByDate, pagination);
            setDataColumns(columns);
            setGridDataToShow(json.data);
            setIsLoading(false);
            setTotal(json.pagination.total);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            setIsNoRecords(true);
        }
    }


    //Setting Preference
    const setPreference = (data) => {
        ///TODO: need to implement
    }

    const onCloseUserPrefence = () => {
        let sortEvent = sort[0] ?? initialSort
        loaddata(searchText, filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        setShowPreferences(!showPreferences);

    }

    const MyDateCell = (props) => <DateCell {...props} />;
    const MyArrayCell = (props) => <ArrayCell {...props} />;

    const MyTimeCell = (props) => <TimeCell {...props} />;

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const MyImageCell = (props) => <ImageCell {...props} />;

    const onFilterSearchChange = (e) => {
        let sortEvent = sort[0] ?? initialSort
        setSearchText(() => e.target.value);

        if (e.target.value == '') {
            loaddata('', filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        }
    };

    const onSearchClick = () => {
        let sortEvent = sort[0] ?? initialSort

        if (searchText && searchText != '') {
            loaddata(searchText.trim(), filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        } else {
            loaddata('', filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        }

    }

    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // handleServerSideSearch(event);
            onSearchClick();
        }
    }





    const onSelectionChange = (event) => {

        const ctrlKey = event.ctrlKey || event.metaKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex];
        const shiftKey = event.shiftKey;

        var newSelectedData = [];

        if (ctrlKey) {
            let alreadySelected = selectedState.some(item => item._id == dataItem._id);
            if (alreadySelected) {
                newSelectedData = selectedState.filter(item => item._id != dataItem._id)
            } else {
                newSelectedData = [...selectedState, dataItem]
            }
        } else if (shiftKey) {
            let start = Math.min(rowIndex, selectedState.length > 0 ? dataItems.findIndex(x => x._id == selectedState[0]._id) : 0);
            let end = Math.max(rowIndex, selectedState.length > 0 ? dataItems.findIndex(x => x._id == selectedState[0]._id) : 0);
            newSelectedData = dataItems.slice(start, end + 1);
        } else {
            let alreadySelected = selectedState.length == 1 && selectedState[0]._id == dataItem._id ? true : false
            if (alreadySelected) {
                newSelectedData = []
            } else {
                newSelectedData = [dataItem]
            }
        }
        setSelectedState(newSelectedData);
        props.setSelectedMedia(newSelectedData)
        setFooterItem({ SelectedItems: newSelectedData.length, TotalDuration: newSelectedData.reduce((acc, x) => acc + x.Duration, 0) })

    };

    const GridRowRenderMediaLibrary = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                console.log("Drag start from media library")
                const propsData = [props.dataItem]

                // On Media Library Drag Start, if One Item Is Selected, We are setting drag item as selected item
                if (selectedState && selectedState.length == 1) {
                    setSelectedState(propsData);
                }

                e.dataTransfer.clearData();
                if (selectedState.length > 1) {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.MediaEpisode, propsData: selectedState }));

                } else {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.MediaEpisode, propsData }));
                }

                console.log("Drag end from media library");
                // set drag image
                let img = document.createElement('drag-ghost');
                img.id = "drag-ghost";
                let data = props.dataItem.Title.toString().substring(0, 10) + " - " + props.dataItem.AssetId.toString().substring(0, 10);
                img.textContent =
                    selectedState.length > 1 ? (selectedState.length.toString() + " Selected - " + data) : data;
                img.style.opacity = 1;
                img.style.whiteSpace = "pre-line";
                img.style.color = 'black';
                img.style.backgroundColor = 'white';
                document.body.appendChild(img);
                e.dataTransfer.setDragImage(img, 0, 0);

            },
            onDragOver: e => {
                e.preventDefault();
            },
            onDragEnd: e => {
                console.log("Drag end from media library")
                // remove drag image
                let img = document.getElementById("drag-ghost");
                if (img)
                    document.body.removeChild(img);

            }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const toGetFilteredData = (data) => {
        console.log(data);
        let sortEvent = sort[0] ?? initialSort
        var finalData = {
            ...data,
        }
        setFilterDataItem(finalData);

        loaddata(searchText, finalData, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
    }

    const onRefresh = () => {
        setIsLoading(true);
        setSort([initialSort])
        var current_page = page.skip / page.take + 1;
        loaddata(searchText, filterDataItem, { per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" });
    }

    const onSortChange = (e) => {
        setSort(e.sort)

        let sortEvent = e.sort[0] ?? initialSort
        console.log(sortEvent);
        setPage({
            skip: 0,
            take: page_take,
        })
        loaddata(searchText, filterDataItem, { per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
    }

    const onFilterTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
        console.log(e.target.value)
        let filterDataItem = {
            MediaCategory: e.target.value.FilterModel.MediaCategory,
            MediaCategoryType: e.target.value.FilterModel.MediaCategoryType,
            Brand: e.target.value.FilterModel.Brand,
            Product: e.target.value.FilterModel.Product,
            Duration: { ...e.target.value.FilterModel.Duration, Condition: e.target.value.FilterModel.Duration.Condition },
            // { ...dataItem.Duration, Condition: dataItem?.Condition && typeof dataItem?.Condition == 'string' ? utility.convertStringWithFramesToMilliseconds(dataItem.Condition) : dataItem?.Condition },
            IsDummy: e.target.value.FilterModel.IsDummy ?? false,
            CreatedFrom: e.target.value.FilterModel.CreatedFrom,
            CreatedTo: e.target.value.FilterModel.CreatedTo,
        }
        toGetFilteredData(filterDataItem);
    }

    const onShowFilterTemplate = () => {

        console.log(showFilterDropdown)
   
        if(showFilterDropdown) {
            toGetFilteredData({});
            setIsFilterCleared(true);
            setSelectedTemplate({});
        }
        
        setShowFilterDropDown(!showFilterDropdown);
    }

    const onClickAdvancedFilter = (edit = false) => {
        console.log(edit)
        if(edit && selectedTemplate && Object.keys(selectedTemplate).length == 0){
            toast.error(lang.please_select_template_to_edit_error_message, { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        setFilterPopupOpen(true);
        setShowTemplateDropDown(edit);
        setSelectedTemplate(edit ? selectedTemplate : {});
        setFilterDataItem({});

    }

    return (
        <div style={{
            position: 'absolute',
            left: 0,
            height: showFilterDropdown ? "88%" : "100%",
            width: '100%',
            padding: '5px',
            marginTop: '-12px',
        }}>

            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">
                    {/* <FilterRoundButton onClick={() => { setFilterPopupOpen(true) }} /> */}
                    <PreferenceRoundButton onClick={() => { setShowPreferences(!showPreferences) }} />
                    <RefreshButton onClick={onRefresh} />
                </div>
            </div>
            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group martb" >
                        <input
                            type="search"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="search"
                            aria-label="type 2 or more letters"
                            placeholder={lang.search_button_text}
                            onKeyDown={onSearchEnter}
                            onChange={onFilterSearchChange}
                            onFocus={(e) => {
                                props.setOutSideFocus(true);
                            }}
                            onBlur={(e) => {
                                props.setOutSideFocus(false);
                            }}
                        />

                        <button title={showFilterDropdown ? "Clear Filters" : "Filters"} style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "-1px" }} onClick={onShowFilterTemplate} aria-label="Advanced search options">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={!showFilterDropdown ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                    <i style={{ margin: "5px 0px", color: !showFilterDropdown ? "black" : "white" }} className="fa fa-filter" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>
            {showFilterDropdown && <div className="flex-container mb-2">
                <div className="flex-container-reverse flex-item-auto">

                    <button title="Advanced Search" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter()} aria-label="Advanced Search">
                        <div className="input-group-append" >
                            <span className="input-group-text" style={isFilterCleared ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                <i style={{ margin: "5px 0px", color: isFilterCleared ? "black" : "white" }} className="fa fa-list" />
                            </span>
                        </div>
                    </button>

                    {selectedTemplate && Object.keys(selectedTemplate).length > 0 && <button title="Edit Filter Template" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter(true)} aria-label="Edit Template">
                        <div className="input-group-append" >
                            <span className="input-group-text" style={{ backgroundColor: "white" }}>
                                <i style={{ margin: "5px 0px", color:  "black" }} className="fa fa-pencil" />
                            </span>
                        </div>
                    </button>}

                    <DropDownList
                        data={template}
                        name={"Template"}
                        textField="Name"
                        dataItemKey="_id"
                        value={selectedTemplate}
                        onChange={onFilterTemplateChange}
                        title="Filter Template"
                    />
                </div>
            </div>}

            {isLoading && <div className="ml-1">
                <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
            </div>}
            {/* {isLoading && <img style={{margin:"0px 500px"}} src="https://media4.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=ecf05e470dz4nwx86m2071l2fgqc0sydk78pv7hhh5fd8lew&rid=giphy.gif&ct=g"/>} */}
            {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}

            {!isLoading && !isNoRecords &&
                <Grid
                    className="ScheduleMediaCollection"
                    data={gridDataToShow?.map(item => {
                        item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                        return item;
                    })}
                    sort={sort}
                    sortable={true}
                    onSortChange={onSortChange}
                    rowRender={GridRowRenderMediaLibrary}
                    reorderable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: false,
                        mode: {
                            value: 'multiple',
                            label: 'Multiple selection mode'
                        },
                    }}
                    onSelectionChange={onSelectionChange}
                    pageable={true}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                >
                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDateCell}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyTimeCell}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyImageCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={140}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyArrayCell}
                                    title={column.label}
                                    width={140}
                                    format={column.format ?? "Description"} //display item in object
                                    sortable={false}
                                />
                            );
                        } else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={140}
                                        sortable={true}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>}

            <div className='row' style={{ display: "flex" }}>
                <div style={{ marginLeft: "20px" }}><p>{'Selected Items'} : {footerItem.SelectedItems}</p></div>
                <div style={{ marginLeft: "20px" }}><p>{'Total Duration'} : {utility.convertMilisecondsToStringWithFrames(footerItem.TotalDuration)}</p></div>
            </div>

            {showPreferences && <UserPreference entityName={'mediaepisode'} data={preferenceState} setState={setPreference} handleClose={onCloseUserPrefence} />}
            {filterPopupOpen && <ScheduleFilter data={filterDataItem} showTemplate={showTemplateDropDown} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} toGetFilterDataItem={toGetFilteredData} isFilterCleared={setIsFilterCleared} closeFilterPopup={() => setFilterPopupOpen(false)} refresh={onRefresh} onClear={() => { }} loadcombo={loadcombo} />}

        </div>
    );
};
