/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../locale/useTranslation";
import * as API_DIGITAL_SIGN from '../../../framework/API/api_digitalSign';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { ENTITYNAME, LOCALSTORAGE_KEY } from "../../../framework/constant/constant";
import BossDialog from "../../../components/BossDialog";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import DigitalSignUserForm from "../user/DigitalSignUserForm";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { utility } from "../../../framework/utility/utilityProvider";

const DigitalSignCompanyForm = (props) => {

    const lang = useTranslation();
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    let blankDataItem = {
        companyName: "",
        Plan: {
            Name: 'Enterprise',
            Tenure: 0,
            screens: 0,
            storage: 0
        },
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const selectedIndexRef = useRef(0);
    const setSelectedIndexRef = (data) => {
        selectedIndexRef.current = data;
    }  
    const [showUserForm, setShowUserForm] = useState(false);

    useEffect(() => {
        pageRender();
    }, []);

    const pageRender = async () => {
        await loadcombo()
        if(props.item.SID) await loadEditData();
    }

    const onChange = (e) => {
        if(e.target.name == "Plan") {
            setDataItem({...dataItem, [e.target.name]: {...dataItem.Plan, [e.target.id]: e.target.value}});
        }else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const loadcombo = async () => {
    }

    const loadEditData = async () => {
        setDataItem({
            _id: props?.item?._id,
            SID: props?.item?.SID,
            companyName: props?.item?.Name,
            Plan: props?.item?.Plan,
        })
        setGridData(props?.item?.Users ?? []);

    }

    const isValid = () => {
        if (dataItem.companyName == undefined || dataItem.companyName == "") {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        console.log(dataItem);
        if (dataItem?.Plan?.Name == undefined || dataItem?.Plan?.Name == "") {
            toast.error(lang.please_enter_plan_name_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem?.Plan?.Tenure == undefined || dataItem?.Plan?.Tenure <= 0) {
            toast.error(lang.plan_tenure_should_be_greater_than_zero_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem?.Plan?.screens == undefined || dataItem?.Plan?.screens <= 0) {
            toast.error(lang.plan_screens_should_be_greater_than_zero_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem?.Plan?.storage == undefined || dataItem?.Plan?.storage <= 0) {
            toast.error(lang.plan_storage_should_be_greater_than_zero_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.length == 0) {
            toast.error(lang.please_add_atleast_one_user_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (!isValid()) return;

        let users = gridData && gridData.length > 0 ? gridData.map(item=> { delete item.index; return item }) : [];  
        let finalData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            companyName: dataItem.companyName,
            Plan: {
                name: dataItem.Plan?.Name,
                Tenure: parseInt(dataItem.Plan?.Tenure),
                screens: parseInt(dataItem.Plan?.screens),
                storage: parseInt(dataItem.Plan?.storage),
            },
            Users: users,
            parentCompany_id: company?._id ?? "",
        }
        console.log(finalData);
        const res = await API_DIGITAL_SIGN.saveDigitalSignCompany(finalData);
        console.log(res.data);
        if(res.success){
            const companyData = res.data;
            let companies = [...utility.getValue(LOCALSTORAGE_KEY.COMPANIES), companyData];
            companies = companies.filter(x => x._id !== company._id);
            utility.setValue(LOCALSTORAGE_KEY.COMPANIES, companies);
            props.cancelEdit();
            props.refresh();
        }else{
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
       
    }

    const setUserData = (item) => {
        setGridData((prevGridData) => {
            let updatedData = [...prevGridData];
            if (item.index !== undefined && item.index !== null) {
                updatedData[item.index] = item;  // Update the item at the given index
            } else {
                updatedData.push(item);  // Add a new item
            }
            return updatedData;
        });
    }

    const borderStyle = {
        border: "1px solid #ced4da",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#FAFAFA"
    }

    const deleteSelectedValue = (index) => {
        let removeData = [...gridData.slice(0, index), ...gridData.slice(index + 1, gridData.length)]
        setGridData(removeData);
    }

    const editSelectedValue = (item) => {
        setSelectedIndexRef(item)
        setShowUserForm(true);
    }

    const MyCommandCell = (props) => {
        return <td>
            <RoundButton icon="trash" onClick={() => deleteSelectedValue(props.dataIndex)} />
            <RoundButton icon="pencil" onClick={() => editSelectedValue({...props.dataItem, index: props.dataIndex})} />
        </td>
    }

    return (
        <>
        <BossDialog
            title={ dataItem?.companyName != "" ? dataItem?.companyName : lang.add_new_company_dialog_header}
            width="770px"
            onClose={props.cancelEdit}
        >
            <div className="row">
                <div className="col-12">
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                    <div className="row mt-2">
                        <div className="col-12">
                            <label>{lang.name_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="companyName" value={dataItem.companyName} onChange={(e) => onChange(e)} />
                        </div>
                    </div>
                    <div className="mt-2">{lang.plan_label} *</div>
                    <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                        <div className="row">
                            <div className="col-6">
                                <label>{lang.name_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="Plan"  id="Name" value={dataItem?.Plan?.Name} onChange={(e) => onChange(e)} />
                            </div>
                            <div className="col-6">
                                <label>{lang.tenure_label} (Days) *</label>
                                <input type="number" className="form-control form-control-sm" name="Plan" id="Tenure" value={dataItem?.Plan?.Tenure} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.screens_label} *</label>
                                <input type="number" className="form-control form-control-sm" name="Plan"  id="screens" value={dataItem?.Plan?.screens} onChange={(e) => onChange(e)} disabled={props.item.SID > 0}/>
                            </div>
                            <div className="col-6">
                                <label>{lang.storage_label} MB *</label>
                                <input type="number" className="form-control form-control-sm" name="Plan"  id="storage" value={dataItem?.Plan?.storage} onChange={(e) => onChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <AddRoundButton style={{ marginBottom: "8px" }} title={lang.add_user_button_tooltip} onClick={()=>setShowUserForm(true)}/>
                            <div className="float-right mt-1">{lang.users_label}</div>
                            <Grid data={gridData} style={{height: '280px'}}>
                                <GridColumn cell={MyCommandCell} width="100px" />
                                <GridColumn field="name" title={lang.name_label} />
                                <GridColumn field="email" title={lang.email_column} />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            {showUserForm && <DigitalSignUserForm selectedItem={selectedIndexRef.current} closeForm={() => setShowUserForm(false) } setUserData={setUserData}/>}
        </BossDialog>
        </>
    )
}

export default DigitalSignCompanyForm;