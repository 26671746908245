/* eslint-disable */
import moment from "moment";
import { toast } from "react-toastify";
import * as API from "../API/api";
import { AllowedFramesPerSecond, COMPANYTYPE, CONTENTTYPE, CONTENT_TYPE, ENTITYNAME, FrameEquivalentOfMilliSecond, LOCALSTORAGE_KEY, LOGEVENT, MODULE, ORIENTATION, OTT_POSTER_TYPE, PRODUCTS, RAILTYPE, ROLETYPE } from "../constant/constant";
import { useTranslation } from "../../locale/useTranslation";
import { ConfirmAlertOk, ConfirmLogoutAlert } from "../../ConfirmAlert";
import socket from "../socket/socket";
import { PlanningHelper } from "../../modules/Planning/helper/PlanningHelper";
const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const utility = {};

var MONTHNAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

//Is valid email id
//working
utility.isValidEmail = function (email) {
    var exp = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return exp.test(email);
};

//Is Numeric Number
//working
utility.isValidNumber = function (text) {
    var exp = /\D/; //If cotain any non numeric item 
    return !exp.test(text);
};

//working
utility.isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

// function for validating Start/End time
//working
utility.isValidTime = function (time) {
    var regexp = new RegExp(/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/);
    var isValid = regexp.test(time);
    return isValid;
};
// function for validating Nominal/ Actual Duration
//working
utility.isValidDuration = function (duration) {
    var regexp = new RegExp(/^([0-1]?[0-9]|2[0-4]):([0-5][0-9]):([0-5][0-9]):([0-2][0-5])?$/);
    var isValid = regexp.test(duration);
    return isValid;
};

//not working
utility.getAccessToken = function () {
    return JSON.parse(localStorage.getItem('token'));
};

utility.getDbName = function () {
    let instance = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY.INSTANCE));
    return instance?.dbName == null ? undefined : instance?.dbName;
};

//not working
utility.setAccessToken = function (token) {
    localStorage.setItem('token', JSON.stringify(token));
};


//not working
utility.getValue = function (key) {
    return JSON.parse(localStorage.getItem(key));
};

utility.getSeassionValue = function (key) {
    return JSON.parse(sessionStorage.getItem(key));
};

utility.setSessionValue = function (key, value) {

    if (value == undefined) {
        value = null;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
};

//not working
utility.setValue = function (key, value) {

    if (value == undefined) {
        value = null;
    }

    localStorage.setItem(key, JSON.stringify(value));
};

//not working
utility.clearLocalStorage = function () {
    localStorage.clear();
};

utility.deleteLocalStorageItem = function (key) {
    localStorage.removeItem(key);
};
utility.deleteSessionStorageItem = function (key) {
    sessionStorage.removeItem(key);
};
//working
utility.sumOfListColumn = function (list, prop) {
    var total = 0;
    var len = list.length;

    for (var i = 0; i < len; i++) {
        total += parseFloat(list[i][prop] == null ? 0 : list[i][prop]);

    }
    return total;
};

//date functions
//working
utility.parseDate = function (dateString) {
    try {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        var date = dateString.split("-");
        var monthString = date[1].toString().trim();
        var d = parseInt(date[0], 10),
            m = parseInt(monthNames.indexOf(monthString), 10),
            y = parseInt(date[2], 10);
        return new Date(y, m, d);
    }
    catch (err) {
        console.log("Error in paseDate - " + err);
        return "";
    }
};
//working
utility.dateToString = function (finalDate) {

    if (!(finalDate instanceof Date && !isNaN(finalDate))) {
        try {
            //try to convert in date
            finalDate = utility.parseDate(finalDate);

            if (!(finalDate instanceof Date && !isNaN(finalDate)))
                return "";

        }
        catch (err) {
            console.log("Error in dateToString - " + err);
            return "";
        }
    }

    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    return finalDate.getDate() + "-" + monthNames[finalDate.getMonth()] + "-" +
        finalDate.getFullYear();


};


//working
utility.datetimeToString = function (finalDate) {

    if (!(finalDate instanceof Date && !isNaN(finalDate))) {
        try {
            //try to convert in date
            finalDate = utility.parseDate(finalDate);

            if (!(finalDate instanceof Date && !isNaN(finalDate)))
                return "";

        }
        catch (err) {
            console.log("Error in dateToString - " + err);
            return "";
        }
    }

    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    return finalDate.getDate() + "-" + monthNames[finalDate.getMonth()] + "-" +
        finalDate.getFullYear() + " " + finalDate.getMinutes;


};
//working
utility.stringToDate = function (value) {

    var trydate = (new Date(value));

    //alert(angular.isDate(trydate) + " " + trydate);

    if ((trydate instanceof Date && !isNaN(trydate)) && trydate.toString().toLowerCase() !== 'invalid date')
        return trydate;


    if (typeof value === "string") {
        var map = {
            Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
            Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
        };
        var input = value.split('-');
        return (new Date(input[2], map[input[1]], input[0]));

    }

    return null;
};

//working
utility.dateDiff = function (datepart, fromdate, todate) {
    datepart = datepart.toLowerCase();
    var diff = todate - fromdate;
    var divideBy = {
        w: 604800000,
        d: 86400000,
        h: 3600000,
        n: 60000,
        s: 1000
    };

    return Math.floor(diff / divideBy[datepart]);
};

utility.GetDatafromList = function (list, searchKey, searchValue) {

    return list.filter(obj => obj[searchKey] === searchValue);

};

utility.playSound = function () {
    var x = document.getElementById("myAudio");
    x.play();

};
utility.parseAdjust = (eventDate) => {
    if (eventDate && eventDate.length === 8) {
        const date = new Date(
            2000,
            2,
            10,
            eventDate.slice(0, 2),
            eventDate.slice(3, 5),
            eventDate.slice(6, 8)
        );
        return date;
    } else {
        const date = new Date(eventDate);
        return date;
    }
};

//to convert the date format into YYYY-MM-DD hh:mm:ss
utility.datetimeFormat = (date) => {
    let converted = moment(new Date(date)).utc().format("YYYY-MM-DD HH:mm:ss");
    return converted;
};

//to convert the date format into YYYY-MM-DD hh:mm:ss
utility.datetimeFormatV2 = (date) => {
    let converted = moment(new Date(date)).utc().format("YYYY-MM-DD");
    return converted;
};

utility.getLocalDateTimeToUtcMiliseconds = (date) => {

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).getTime();
};

utility.addTimeToDateInMilliseconds = (dateInMilliseconds, hours, minutes, seconds, milliseconds) => {

    var returnValue = dateInMilliseconds;
    if (hours > 0) returnValue = returnValue + (hours * 60 * 60 * 1000);
    if (minutes > 0) returnValue = returnValue + (minutes * 60 * 1000);
    if (seconds > 0) returnValue = returnValue + (seconds * 1000);
    if (milliseconds > 0) returnValue = returnValue + milliseconds;
    return returnValue;
};



utility.getDateMilisecondFromExcelDateTimeMilisecond = (excelDate) => {
    var date = new Date(moment(excelDate));
    var neDate = new Date(date.setSeconds(date.getSeconds() + 10));
    // console.log((new Date(Date.UTC(neDate.getFullYear(), neDate.getMonth(), neDate.getDate()))));
    return (new Date(Date.UTC(neDate.getFullYear(), neDate.getMonth(), neDate.getDate()))).getTime();
}

utility.getTimeMilisecondFromExcelDateTimeMilisecond = (ms) => {
    var d = moment(ms);
    var hour = d.hours();
    var minutes = d.minutes();
    var seconds = d.seconds();
    // console.log(hour + ":" + minutes + ":" + seconds);
    return hour * 3600000 + minutes * 60000 + seconds * 1000
}


utility.removeTimeFromDateTime = (datetime) => {

    return new Date(
        datetime.getFullYear(),
        datetime.getMonth(),
        datetime.getDate()
    );
};

//to convert the data into YYYY-MM-DD hh:mm:ss:ff
utility.convertTime = function (input, fps) {
    var pad = function (input) {
        return input < 10 ? "0" + input : input;
    };
    fps = typeof fps !== "undefined" ? fps : 24;
    return [
        pad(Math.floor(input / 3600)),
        pad(Math.floor((input % 3600) / 60)),
        pad(Math.floor(input % 60)),
        pad(Math.floor((input * fps) % fps)),
    ].join(":");
};

utility.randomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

//for adding duration
utility.stringWithFramesToTimeSpan = function (time) {
    //hh:mm:ss:ff
    let seconds = 0;
    let frames = 0;
    let minutes = 0;
    let hours = 0;
    let a = time.split(':');

    let totalMiliseconds = 0;

    hours = parseInt(a[0]);
    minutes = parseInt(a[1]);
    seconds = parseInt(a[2]);
    frames = parseInt(a[3]);

    if (seconds > 59) {
        seconds = 59;
    }

    if (minutes > 59) {
        minutes = 59;
    }

    if (hours > 23) {
        hours = hours - 24;
    }



    if (a.length > 2) {
        var sec = (hours * 60 * 60) + minutes * 60 + seconds;
        totalMiliseconds = (sec * 1000) + frames * FrameEquivalentOfMilliSecond;
    }

    return moment(new Date(totalMiliseconds)).utc().format("HH:mm:ss.sss");
}

//
utility.addDurationInDateTime = function (date, time) {
    //hh:mm:ss:ff
    let seconds = 0;
    let frames = 0;
    let minutes = 0;


    let a = time.split(':');
    let hours = parseInt(a[0]);
    minutes = parseInt(a[1]);
    seconds = parseInt(a[2]);
    frames = parseInt(a[3]);

    var sec = hours * 60 * 60 + minutes * 60 + seconds;
    var msec = sec * 1000 + frames * 40;



    return new Date(date.getTime() + msec)
}

//
utility.timeSpanToStringWithFrames = function (time) {

    let returnValue = '';

    // let source = utility.stringWithFramesToTimeSpan(time);

    let [hours, minutes, seconds, millisecondes] = time.split(':');

    if (time !== null) {

        returnValue = `${hours}:${minutes}:${seconds}:${millisecondes ? millisecondes : "00"}`;

    } else {
        returnValue = "00:00:00:00";
    }

    return returnValue;
}

//function to convert the time (in string format) into milliseconds full HH:mm:ss:ff
utility.convertStringWithFramesToMilliseconds = function (time) {
    const [hours, minutes, seconds, frames] = time.split(':');

    let hoursToMilliseconds = parseInt(hours) * 60 * 60 * 1000;
    let minutesToMilliseconds = parseInt(minutes) * 60000;
    let secondsToMilliseconds = parseInt(seconds) * 1000;

    let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds + (parseInt(frames) * FrameEquivalentOfMilliSecond);

    return convertedMilliseconds;
}

utility.convertStringTimeToMilliseconds = function (time) {
    const [hours, minutes, seconds] = time.split(':');

    let hoursToMilliseconds = parseInt(hours) * 60 * 60 * 1000;
    let minutesToMilliseconds = parseInt(minutes) * 60000;
    let secondsToMilliseconds = parseInt(seconds) * 1000;

    let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds;

    return convertedMilliseconds;
}
//function to convert the time (in string format) into milliseconds only HH:mm
utility.convertShortTimeStringToMilliSeconds = function (time) {
    const [hours, minutes] = time.split(':')

    let hoursToMilliseconds = parseInt(hours) * 60 * 60 * 1000;
    let minutesToMilliseconds = parseInt(minutes) * 60000;
    let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds;

    return convertedMilliseconds;
}
//HH:mm:ss:ff
utility.validateTimeString = function (timestring) {

    if (!timestring || !timestring.includes(':')) return "00:00:00:00";

    timestring = timestring.replaceAll('_', '0')

    var time = timestring.split(":");

    var hours = time[0] ? time[0] : '00';
    var min = time[1] ? time[1] : '00';
    var sec = time[2] ? time[2] : '00';
    var frame = time[3] ? time[3] : '00';

    hours = hours > 23 ? 23 : hours;
    min = min > 59 ? 59 : min;
    sec = sec > 59 ? 59 : sec;
    frame = frame > AllowedFramesPerSecond ? AllowedFramesPerSecond : frame;

    return hours + ":" + min + ":" + sec + ":" + frame;
}
//HH:mm
utility.validateShortTimeString = function (timestring) {

    if (!timestring || !timestring.includes(':')) return "00:00";

    timestring = timestring.replaceAll('_', '0')
    var [hours, min] = timestring.split(":");
    hours = hours > 23 ? 23 : hours;
    min = min > 59 ? 59 : min;

    return hours + ":" + min;
}

//want both time value in string format ( "HH:mm:ss:SS")
utility.calculateDuration = (startTime, endTime) => {

    const [hours, minutes, seconds, ms] = startTime.split(':');
    const [ehours, eminutes, eseconds, ems] = endTime.split(':');

    let start = new Date('2000', '1', '1', hours, minutes, seconds, ms).getTime();
    let end = new Date('2000', '1', '1', ehours, eminutes, eseconds, ems).getTime();

    let duration = '';
    let actualDuration = '';
    // console.log(new Date(end - start).toISOString().slice(11, 19));
    if (startTime.length === 11 && endTime.length === 11) {
        duration = new Date(end - start).toISOString();
        actualDuration = duration.slice(11, 19) + ':' + duration.slice(21, 23);
    }
    console.log(`${actualDuration}`);
    return `${actualDuration}`;
}

//function returns time from milliseconds
utility.convertMilisecondsToStringWithFrames = function (ms, isWithFrame = true) {
    // console.log(ms);
    var milli = parseInt(ms)
    const date = new Date(milli)
    var hours = date.getUTCHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var minutes = date.getUTCMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var seconds = date.getUTCSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var millisecondes = date.getUTCMilliseconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var frames = (parseInt(millisecondes / 40)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    return isWithFrame ? hours + ':' + minutes + ':' + seconds + ':' + frames : hours + ':' + minutes + ':' + seconds;

};

utility.convertMilisecondsToDateTimeString = function (ms) {
    var milli = parseInt(ms)
    const date = new Date(milli)
    var day = date.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var month = (date.getUTCMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var year = date.getUTCFullYear()
    var hours = date.getUTCHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var minutes = date.getUTCMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var seconds = date.getUTCSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var millisecondes = date.getUTCMilliseconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var frames = (parseInt(millisecondes / 40)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds + ':' + frames

    return result

};


utility.convertMilisecondsToFormattedDateTimeString = function (ms) {
    var milli = parseInt(ms)
    const date = new Date(milli)

    var day = date.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var month = MONTHNAMES[date.getUTCMonth()];
    var hours = date.getUTCHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var minutes = date.getUTCMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })

    return day + ' ' + month + ' - ' + hours + ':' + minutes;
};

utility.convertMilisecondsToDateString = function (ms) {
    let milli = parseInt(ms);
    const date = new Date(milli)
    return moment(date).format("YYYY-MM-DD")
};

//function returns time from milliseconds
utility.convertMilisecondsToShortTimeString = function (ms) {
    var milli = parseInt(ms)
    const date = new Date(milli)
    var hours = date.getUTCHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    var minutes = date.getUTCMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
    const result = hours + ':' + minutes

    return result

};

utility.numbersToWords = function (num) {


    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];


    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
}

//to check the duplicate entry in db
utility.checkDuplicate = async (entityName, dataItem) => {
    var tempItem = { ...dataItem };
    var objKey = Object.keys(tempItem);
    var checkKey = ['Title', 'Name', 'Description', 'Accountname', 'TimeRange', 'name'];

    var checkQuery = [['SID', '!=', dataItem.SID]];

    checkKey.map(async (key) => {
        if (objKey.includes(key)) {
            checkQuery.push([key, '=', dataItem[key]])
            return;
        } else {
            console.log(`${key} not present`);
        }
    });

    console.log(checkQuery);

    var res = await API.getData(entityName, { query: checkQuery });
    if (res.data.length > 0) {
        console.log(res.data);
        return false;
    }

    return true;
}

utility.setToSunday = (date) => {
    var day = date.getDay();
    if (day == 0) {
        return date.setUTCHours(0, 0, 0, 0);
    } else {
        return new Date(new Date().setDate(date.getDate() - day)).setUTCHours(0, 0, 0, 0);
    }
}

utility.setToSaturday = (date) => {
    var day = date.getDay();
    if (day == 6) {
        return date.setUTCHours(23, 59, 59, 99);
    } else {
        if (day == 0) {
            return new Date(new Date().setDate(date.getDate() + 6)).setUTCHours(23, 59, 59, 99);
        }
        return new Date(new Date().setDate(date.getDate() + (6 - day))).setUTCHours(23, 59, 59, 99);
    }
}

// Block Routing based on user right

utility.isCurrentCompanyModuleEnabled = (modulesid, company) => {

    const allModules = utility.getValue(LOCALSTORAGE_KEY.ALLMODULES);
    const module = allModules != undefined && allModules.length > 0 ? allModules.find(x => x.SID == modulesid) : undefined;

    if (module == undefined) return false;

    return company.Products.some((x) => module && module.Products.includes(x));
}

utility.isCurrentCompanyModuleEnabledByPath = (location, company) => {

    const allModules = utility.getValue(LOCALSTORAGE_KEY.ALLMODULES);
    const module = allModules != undefined && allModules.length > 0 ? allModules.find(x => x.path != undefined && location.pathname.toLowerCase().includes(x.path.toLowerCase())) : undefined;

    if (module == undefined) return false;

    return company.Products.some((x) => module && module.Products.includes(x));
}



utility.hasModuleRight = (modulesid) => {

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    let isDigitalSignage = utility.isAutomateB();

    if (user.RoleType && user.RoleType.ID == ROLETYPE.User && modulesid == MODULE.USERS && !isDigitalSignage) return false;

    if (user.RoleType && user.RoleType.ID == ROLETYPE.SuperAdmin) return true;

    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    if ((user.RoleType && user.RoleType.ID == ROLETYPE.Admin)) {
        return utility.isCurrentCompanyModuleEnabled(modulesid, company);
    }

    let module = user.Module.length > 0 ? user.Module.find((x) => x.SID == modulesid) : null;

    if (!module || module.Products == undefined || module.Products == null) return false;

    //CHECK COMPANY PRODUCT
    let isValidCompanyModule = company.Products.some((x) => module && module.Products.includes(x));

    //CHECK VALID MODULE
    let isValidModule = modulesid != undefined && user.Module != undefined && user.Module.length > 0 && user.Module.some(x => x.SID == modulesid);

    return isValidCompanyModule && isValidModule

}

utility.getRandomColor = () => {

    return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

utility.getUserMenu = (menuModel) => {

    let finalMenuModel = [];

    menuModel.map((item) => {

        if (item.type === 0 && utility.hasModuleRight(item.modulesid)) {
            finalMenuModel.push(item);
        }
        else if (item.type === 1) {

            let parentMenu = { ...item, subMenu: [] };

            item.subMenu.map((subMenu) => {

                if (subMenu.type === 0 && utility.hasModuleRight(subMenu.modulesid)) {
                    parentMenu.subMenu.push(subMenu);
                } else if (subMenu.type === 1 && utility.hasModuleRight(subMenu.modulesid)) {

                    //MODULE RIGHTS FOR SUB_SUBMENU 
                    let subSubMenuArray = [];
                    if (subMenu.subMenu && subMenu.subMenu.length > 0) {
                        subMenu.subMenu.map((subMenu) => {
                            if (subMenu.type === 0 && utility.hasModuleRight(subMenu.modulesid)) {
                                subSubMenuArray.push(subMenu);
                            }
                        })
                    }

                    parentMenu.subMenu.push({ ...subMenu, subMenu: subSubMenuArray });

                }

            })

            if (parentMenu.subMenu.length > 0) {
                finalMenuModel.push(parentMenu);
            }

        }
    });

    return finalMenuModel
}

utility.getDataInsideObject = (object, field) => {

    //example
    // var object = { a: { b: { c: 1 } } };
    // var field = 'a.b.c';
    // var result = utility.getDataInsideObject(object, field);
    // it will give value of c

    if (object && field) {
        let fieldArray = field.split('.');
        let tempObject = { ...object };
        let isValid = true;

        fieldArray.map((field) => {
            if (Object.hasOwn(tempObject, field) && isValid) {
                tempObject = tempObject[field];
            } else {
                isValid = false;
            }
        })

        return isValid ? tempObject : null;
    }
    return null;
}

utility.getObjectWithNestedKeySperatedByDot = (dataItem, nestedKey, value) => {

    //example
    // var dataItem = { a: { b: { c: 1 } } };
    // var nestedKey = 'a.b.c';
    // var value = 2;
    // var result = utility.getObjectWithNestedKeySperatedByDot(dataItem, nestedKey, value);
    //it will add value in nested key

    let keyArray = nestedKey.split('.');

    let object = dataItem;

    if (keyArray.length == 0) {
        return {}
    }
    else if (keyArray.length == 1) {
        object[keyArray[0]] = value
    }
    else if (keyArray.length == 2) {
        object[keyArray[0]] = { ...object[keyArray[1]], [keyArray[1]]: value }
    }
    else if (keyArray.length == 3) {
        object[keyArray[0]] = { ...object[keyArray[1]], [keyArray[1]]: { ...object[keyArray[2]], [keyArray[2]]: value } }
    }
    else if (keyArray.length == 4) {
        object[keyArray[0]] = { ...object[keyArray[1]], [keyArray[1]]: { ...object[keyArray[2]], [keyArray[1]]: { ...object[keyArray[3]], [keyArray[3]]: value } } }
    } else {
        object = {};
    }

    return object;

}

utility.subString = (dataItem, length) => {
    var str = dataItem?.toString();
    var res = str?.substring(0, length);
    return str?.length > length ? res + "..." : res;
}

utility.getFirstAndLastDateOfMonth = () => {

    //set deafult date to be to 1 and the last date of the month
    const currentYear = (new Date()).getFullYear();
    const currentMonth = (new Date()).getMonth();
    const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    var firstDate = moment(new Date(currentYear, currentMonth, 1)).format("YYYY-MM-DD")
    var lastDate = moment(new Date(currentYear, currentMonth, daysInCurrentMonth)).format("YYYY-MM-DD")

    return { FirstDate: firstDate, LastDate: lastDate }

}

utility.isValidDate = (date) => {
    return moment(new Date(date)).isValid();
}

utility.removeDuplicate = (arr, key) => {

    const uniqueIds = [];

    const unique = arr.filter(element => {
        const isDuplicate = uniqueIds.includes(element[key]);

        if (!isDuplicate) {
            uniqueIds.push(element[key]);
            return true;
        }
        return false;
    });


    return unique;
}


utility.lockUnlockPlaylist = async (channel, date, moduleID, lock = true) => {

    if (lock) {
        const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
        const data = {
            date: date,
            moduleID: moduleID,
            user: {
                _id: userData._id,
                name: userData.name
            },
            channel: channel,
            timestamp: Date.now()
        }



        let query = [['channel._id', '=', channel._id], ['date', '=', date], ['moduleID', '=', moduleID]];
        const checkIfExists = await API.getData(ENTITYNAME.PLAYLIST_LOCK_STATUS, { query: query });
        if (checkIfExists.success && checkIfExists.data && checkIfExists.data.length == 0) {
            const result = await API.saveData(ENTITYNAME.PLAYLIST_LOCK_STATUS, data);
            return result;
        }
        else {
            return "Already Locked";
        }
    } else {
        const query = [
            ['channel._id', '=', channel._id],
            ['date', '=', date],
            ['moduleID', '=', moduleID]
        ];

        const result = await API.deleteDataByQuerry(ENTITYNAME.PLAYLIST_LOCK_STATUS, query);
        return result;
    }

}

utility.getLockedPlaylist = async (date, moduleID) => {
    var query = [];
    switch (moduleID) {
        case MODULE.PLANNING:
            let startDate = new Date(date);
            let endDate = new Date(date);
            startDate.setDate(startDate.getDate() - 7);
            startDate = startDate.getTime();
            endDate.setDate(endDate.getDate() + 7);
            endDate = endDate.getTime();
            query = ['date', '>=', startDate, 'date', '<=', endDate];
            break;
        case MODULE.SCHEDULING:
            query = ['date', '=', date];
            break;
        default:
            query = ['date', '>=', startDate, 'date', '<=', endDate];
            break;
    }
    const data = await API.getData(ENTITYNAME.PLAYLIST_LOCK_STATUS, { query: query });
    return data;
}

utility.displayArrayInPipe = (array, key) => {
    if (Array.isArray(array)) {
        return array?.map((data, i) => {
            if (i + 1 < array.length) {
                return data[key] + " | ";
            }
            else {
                return data[key];
            }
        }) ?? 'N/A'
    } else {
        return 'N/A';
    }

}

//can modify for planning and schedule lock
// utility.canModify = (lockedPlaylist, channelID, date) => {

//     if (!lockedPlaylist && lockedPlaylist.length == 0) return { canModify: true, lockedBy: { _id: 0, name: "none" } };

//     lockedPlaylist = lockedPlaylist.filter(x => x.channel._id == channelID && x.date == date);

//     if (!lockedPlaylist && lockedPlaylist.length == 0) return { canModify: true, lockedBy: { _id: 0, name: "none" } };

//     return { canModify: false, lockedBy: lockedPlaylist[0].user };

// }

utility.canModify = (lockedPlaylist, moduleID, channelID, date) => {


    var isSelfLocked = false;
    var selfName = "You";

    const defaultOutput = { canModify: true, lockedBy: { _id: 0, name: "none" }, isSelfLocked: false, fromSchedule: false };
    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

    if (!lockedPlaylist || lockedPlaylist.length == 0) return defaultOutput;

    switch (moduleID) {

        case MODULE.PLANNING:

            isSelfLocked = lockedPlaylist.some(x => x.channel._id == channelID && x.date == date && x.user._id == userData._id && x.moduleID == MODULE.PLANNING);

            let isFromSchedule = lockedPlaylist.some(x => x.channel._id == channelID && x.date == date && x.moduleID == MODULE.SCHEDULING);

            lockedPlaylist = lockedPlaylist.filter(x => x.channel._id == channelID && x.date == date && x.user._id != userData._id);

            if (!lockedPlaylist || lockedPlaylist.length == 0) return { canModify: true, lockedBy: { _id: 0, name: isSelfLocked ? selfName : "none" }, isSelfLocked: isSelfLocked, fromSchedule: isFromSchedule };

            return { canModify: isSelfLocked, lockedBy: { ...lockedPlaylist[0]?.user, name: isSelfLocked ? selfName : lockedPlaylist[0]?.user?.name }, isSelfLocked: isSelfLocked, fromSchedule: isFromSchedule }

        case MODULE.SCHEDULING:

            isSelfLocked = lockedPlaylist.some(x => x.channel._id == channelID && x.date == date && x.user._id == userData._id && x.moduleID == MODULE.SCHEDULING);

            lockedPlaylist = lockedPlaylist.filter(x => x.channel._id == channelID && x.date == date && x.moduleID == MODULE.SCHEDULING && x.user._id != userData._id);

            if (!lockedPlaylist || lockedPlaylist.length == 0) return { canModify: true, lockedBy: { _id: 0, name: isSelfLocked ? selfName : "none" }, isSelfLocked: isSelfLocked, fromSchedule: true };

            return { canModify: isSelfLocked, lockedBy: { ...lockedPlaylist[0]?.user, name: isSelfLocked ? selfName : lockedPlaylist[0]?.user?.name }, isSelfLocked: isSelfLocked, fromSchedule: true }

        default:
            break;

    }

    return defaultOutput;
}

utility.GetFreshMaxLineNo = async (campaignId) => {

    let lineNo = 0;
    let resBooking = await API.getData(ENTITYNAME.Booking, { query: ['Campaign_Id', '=', campaignId] });

    if (resBooking.data.length > 0) {
        // sorting in descending order by line no to get highest line no
        resBooking.data.sort((a, b) => parseInt(b.LineNumber) - parseInt(a.LineNumber));
        lineNo = resBooking.data[0].LineNumber;

    }
    return lineNo + 1;
}

utility.checkLinesWithZeroSpots = (data, days) => {
    let hasNoSpotsInLine = [];

    data.map((line) => {
        let data = days.filter(x => {
            if (line[x] != undefined && line[x].daySpots != undefined) {
                return line[x].daySpots > 0
            } else {
                return line[x] != undefined && line[x] > 0
            }
        });
        if (data.length == 0) {
            hasNoSpotsInLine.push(line);
        }
    });

    if (hasNoSpotsInLine.length > 0) {
        toast.warning(`${hasNoSpotsInLine.map((x) => x.LineNumber).join(',')} line number has no spots in it. Atleast add one spot !!`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }

    return true;
}

utility.checkSpotsInCampaignDateRange = (data, days, campaign) => {
    let inValidSpotDates = [];

    data.map((line) => {
        days.map(x => {
            let spot = 0;

            if (line[x] != undefined && line[x].daySpots != undefined) {
                spot = line[x].daySpots;
            } else if (line[x] != undefined) {
                spot = line[x];
            }
            const [date, day] = x.split(' ');
            let spotDate = (new Date(date)).getTime();

            if (spot > 0 && (campaign.PeriodFrom > spotDate || campaign.PeriodTo < spotDate)) {
                inValidSpotDates.push(x);
            }
        });

    });

    if (inValidSpotDates.length > 0) {
        toast.warning(`Please book spots within campaign date range : 
            ${moment(new Date(campaign.PeriodFrom)).format('DD/MM/YYYY')} - ${moment(new Date(campaign.PeriodTo)).format('DD/MM/YYYY')}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }

    return true;
}

utility.getMediaCategroyTypeWithMediaCategory = async () => {

    const mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 }, query: ["Archive", "=", false] });
    const mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 }, query: ["Archive", "=", false] });

    let data = mediaCategoryType.data.map((x) => {

        let mediaCategoryItem = mediaCategory.data.find((y) => y.SID == x.MediaCategorySID) ?? {};
        return { ...x, MediaCategory: mediaCategoryItem }

    })

    return data;
}

utility.hasModuleRights = (module) => {

    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

    return (userData?.RoleType?.ID == ROLETYPE.SuperAdmin) || ((userData?.Module ?? []).some(x => x.SID == module));

}

utility.getDefaultItem = (data) => {

    return data.length > 0 ? data.some(x => x?.IsDefault) ? data.find(x => x?.IsDefault) : data[0] : {};
}

utility.getDefaultItems = (data) => {

    return data.length > 0 ? data.some(x => x?.IsDefault) ? data.filter(x => x?.IsDefault) : [data[0]] : [];
}

utility.isValidChannel = (data) => {

    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

    return userData.RoleType.ID == ROLETYPE.SuperAdmin ? data : data?.filter(x => userData?.Channels.find(y => y == x?._id));

}

utility.checkDuplicateInData = (selectedData, gridData, field = "Description") => {

    const lang = useTranslation();

    let duplicate = []
    let newData = []
    selectedData.map((obj) => {
        if (obj && obj.SID) {
            let Temp = gridData.filter((item) => item.SID == obj.SID);
            if (Temp.length > 0) {
                duplicate = [...duplicate, ...Temp];
            } else {
                newData = [...newData, obj];
            }
        }
    })
    if (duplicate.length != 0) {
        let temp = duplicate.map((obj) => obj[field]);
        let msg = temp.join(", ");
        toast.info(utility.subString(msg, 50) + ` ${lang.already_exists_global_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });

    }

    return newData;
}

utility.getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

utility.getSumOfAColumnValueFromList = (list, key) => {
    return list.reduce((acc, o) => acc + parseInt(o[key]), 0);
}

utility.addDays = function (date, days) {
    date.setDate(date.getDate() + days);
    return date;
}

utility.getPaddedString = (value, length, padLeft, padChar) => {
    var str = value;
    var numberString = length.toString().padStart(padChar, '0');
    return padLeft ? `${str}${numberString}` : `${numberString}${str}`;
}

utility.enumObj = (data, value) => {
    return { "_id": data, "Description": Object.keys(value).find(key => value[key] === data) };
}

utility.getApplicationConfigurationByKey = (key) => {
    const applicationConfiguration = utility.getValue(LOCALSTORAGE_KEY.applicationConfiguration);
    return applicationConfiguration?.find(x => x.Key == key)?.Value ?? null;
}

utility.checkTokenExpire = (data) => {
    if (data && data.isValidToken == false) {
        ConfirmLogoutAlert(
            () => {
                localStorage.clear();
                window.location.href = '/';
            }, // on Click yes
            `${"Log Out"}`, //title
            `${"Login Token Expired. Please login again"}`// message
        )
    }
}

utility.checkIsLicenseExpire = (data) => {
    if (data && data.isLicenseExpired == true) {
        ConfirmLogoutAlert(
            () => {
                localStorage.clear();
                window.location.href = '/';
            }, // on Click yes
            `${"Log Out"}`, //title
            `${"License Expired. Please renew your license!"}`// message
        )
    }
}

utility.checkSessionExpire = () => {

    const baseURL = window.location.protocol + window.location.host;

    const versionRes = utility.getValue(LOCALSTORAGE_KEY.applicationVersion);
    if (versionRes?.IsDigitalSignage || baseURL.includes('app.automateb') || baseURL.includes('cms.automateb')) {
        return;
    }

    var timeout = utility.getValue(LOCALSTORAGE_KEY.sessiontimeout);
    var userData = utility.getValue(LOCALSTORAGE_KEY.userData);

    if (timeout && timeout < (new Date()).getTime()) {
        ConfirmLogoutAlert(
            () => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/';
                socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
            }, // on Click yes
            `${"Log Out"}`, //title
            `${"Session Expired. Please login again"}`// message
        )
    }
    else {
        // updating session timeout
        let company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
        // setting first session timeout
        var sessiontimeout = (new Date()).getTime() + (company.SessionTimeOut ?? 3600000);
        utility.setValue(LOCALSTORAGE_KEY.sessiontimeout, sessiontimeout); // 1 hour, it will come from login
    }
}

utility.getPosterUrl = (posters, posterType) => {

    if (!posters || typeof posters == 'string' || posters.length == 0) {
        return posters
    }

    var poster = posters.find((x) => x.OttPosterType.SID == posterType);

    return poster ? poster.Url : posters[0].Url

}

utility.getValidPosterFromRail = (railType) => {
    switch (railType) {
        case RAILTYPE.Rail:
            return OTT_POSTER_TYPE.Portrait;
        case RAILTYPE.CircleRail:
            return OTT_POSTER_TYPE.Circle;
        case RAILTYPE.PotraitRail:
            return OTT_POSTER_TYPE.Portrait;
        case RAILTYPE.LandscapeRail:
            return OTT_POSTER_TYPE.Landscape;
        case RAILTYPE.SquareRail:
            return OTT_POSTER_TYPE.Square;
        default:
            return OTT_POSTER_TYPE.Free;
    }
}

utility.getUtilitySubMenuList = (lang, user) => {

    var utilitySubMenu = [
        // {
        //     type: 0,
        //     link: 'DataMigration',
        //     title: lang.data_migration_menu,
        //     icon: 'fa fa-lg fa-fw fa-calendar-day',
        //     modulesid: MODULE.DATA_MIGRATION,
        //     name: lang.data_migration_menu
        // },
        {
            type: 0,
            link: 'FormFields',
            title: lang.form_fields_menu,
            modulesid: MODULE.FORM_FIELD,
            name: lang.form_fields_menu
        },
        {
            type: 0,
            link: "AuditLog",
            title: lang.audit_log_menu,
            modulesid: MODULE.AUDITLOG,
            name: lang.audit_log_menu
        },
        {
            type: 0,
            link: "Workflow",
            title: lang.workflow_menu,
            modulesid: MODULE.WORKFLOW,
            name: lang.workflow_menu
        },
    ]

    if (user.RoleType.ID == ROLETYPE.SuperAdmin) {
        utilitySubMenu.push(
            {
                type: 0,
                link: 'instances',
                title: lang.instances_sub_menu,
                modulesid: MODULE.INSTANCE,
                name: lang.instances_sub_menu
            },
            {
                type: 0,
                link: 'company',
                title: lang.company_menu,
                modulesid: MODULE.COMPANY,
                name: lang.company_menu
            },
            {
                type: 0,
                link: 'applicationConfiguration',
                title: lang.application_configuration_menu,
                modulesid: MODULE.APPLICATION_CONFIGURATION,
                name: lang.application_configuration_menu
            },
            {
                type: 0,
                link: 'Module',
                modulesid: MODULE.MODULE_SUB_MENU,
                name: lang.module_sub_menu
            }
        )
    }

    return utilitySubMenu;

}

utility.convertStringDatetoMilliseconds = (date) => {
    let strDate = utility.parseDate(date);
    let newDate = new Date(strDate);
    return (new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()))).getTime();
}

//INGESTION MODULE HELPER FUNCTIONS
utility.parseDate = (dateString) => {
    let dateParts;

    // Check for the different formats and split accordingly
    if (dateString.includes('-')) {
        dateParts = dateString.split('-');
    } else if (dateString.includes('/')) {
        dateParts = dateString.split('/');
    } else {
        console.error("Unsupported date format");
        return null;
    }

    let day, month, year;

    // Determine the date format and parse it accordingly
    if (dateParts[0].length === 4) {
        // Format is YYYY-MM-DD or YYYY/MM/DD
        year = parseInt(dateParts[0], 10);
        month = parseInt(dateParts[1], 10);
        day = parseInt(dateParts[2], 10);
    } else {
        // Format is DD-MM-YYYY or DD/MM/YYYY
        day = parseInt(dateParts[0], 10);
        month = parseInt(dateParts[1], 10);
        year = parseInt(dateParts[2], 10);
    }

    return `${year}-${month}-${day}`;
}

utility.convertExcelDateAndTimeInMilliseconds = function (value, type, format) {

    if (format == 'Milliseconds') {
        return value;
    } else if (format == 'Seconds') {
        return value * 1000;
    } else if (format == 'Minutes') {
        return value * 60 * 1000;
    } else {
        //CHECK IF IT IS DATE, TIME OR STRING
        if (value != undefined && typeof value == 'string' && value.includes('T') && value.includes('Z')) {
            if (type == 'date') {
                return utility.getDateMilisecondFromExcelDateTimeMilisecond(value);
            } else {
                return utility.getTimeMilisecondFromExcelDateTimeMilisecond(value);
            }
        }

        if (type == 'date') {
            //CHECK IF IT IS DATE TIME STIRNG
            if (format.includes("HH:mm:ss")) {
                let [date, time] = value.split(' ');
                let newDate = utility.convertStringDatetoMilliseconds(date) + utility.convertStringTimeToMilliseconds(time);
                return newDate;
            } else {
                return utility.convertStringDatetoMilliseconds(value);
            }
        } else {
            let timeFormatCheck = value.split(':');
            let timeWithFrames = timeFormatCheck.length == 2 ? `${value}:00:00` : timeFormatCheck.length == 3 ? `${value}:00` : value;
            return utility.convertStringWithFramesToMilliseconds(timeWithFrames);
        }
    }


}

utility.checkValidImportFile = function (fileColumns, mappedKeysColumns) {

    let tempColumns = [];

    if (mappedKeysColumns.length == 0) {
        return false;
    }

    for (let i = 0; i < mappedKeysColumns.length; i++) {
        const column = mappedKeysColumns[i];
        if (fileColumns.includes(column)) {
            tempColumns.push(column);
        }
    }

    if (tempColumns.length == mappedKeysColumns.length) {
        return true;
    } else {
        return false;
    }

}

utility.isUserAuthorizeChannelRights = function (channels) {
    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    if (userData.RoleType.ID == ROLETYPE.SuperAdmin || userData.RoleType.ID == ROLETYPE.Admin) {
        return true;
    } else {
        return channels.every(x => userData.Channels.includes(x._id));
    }
}

utility.getWeekStartEndDates = function (date) {
    let startDate = utility.setToSunday(date);
    let endDate = utility.setToSaturday(date);
    return { startDate, endDate };
}

utility.convertMilisecondsToLocalDateTime = function (ms) {
    let date = new Date(parseInt(ms));
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let seconds = date.getSeconds().toString().padStart(2, '0');

    let utcDateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return utcDateTimeString;
}

utility.getEnumValue = function (enumValue, value) {
    return Object.keys(enumValue).find(key => enumValue[key] === value);
}

utility.getCurrentDate = function () {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}

utility.maskStringToTimeFormat = function (input) {
    let parts = input.split(':');
    let value = "";
    let isValidTime = true;
    if (parts.some((x) => !utility.checkValidNumber(x))) {
        return { value: value, isValidTime: false };
    }
    //hh:mm:ss:ff
    if (parts.length === 1) {
        value = `00:${parts[0].toString().padStart(2, '0')}:00:00`;
    } else if (parts.length === 2) {
        value = `00:${parts[0].toString().padStart(2, '0')}:${parts[1].toString().padStart(2, '0')}:00`;
    } else if (parts.length === 3) {
        value = `${parts[0].toString().padStart(2, '0')}:${parts[1].toString().padStart(2, '0')}:${parts[2].toString().padStart(2, '0')}:00`;
    } else {
        value = "";
    }

    return { value: value, isValidTime: isValidTime };
}

//FUNCTION TO CHECK VALID NUMBER
utility.checkValidNumber = function (value) {
    if (!isNaN(value)) {
        return true;
    } else {
        return false;
    }
}

utility.prepareSaveData = function (entityName, saveData) {
    if (entityName == ENTITYNAME.DigitalSignContent || entityName == ENTITYNAME.DigitalSignScreenGroup || entityName == ENTITYNAME.DigitalSignScreen || entityName == ENTITYNAME.DigitalSignOverlay || entityName == ENTITYNAME.DigitalSignPlaylist || entityName == ENTITYNAME.DigitalSignPlaylistItem || entityName == ENTITYNAME.DigitalSignUnknownScreen) {
        if (Array.isArray(saveData)) {
            let arrayData = saveData.map((x) => { return { ...x, Company_id: x.Company_id ? x.Company_id : utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id } })
            return arrayData
        } else {
            return { ...saveData, Company_id: saveData.Company_id ? saveData.Company_id : utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id }
        }
    }

    return saveData;
}

utility.groupedBy = function (arr, key) {
    const intermediate = arr.reduce((acc, item) => {
        const value = item[key];
        if (!acc[value]) {
            acc[value] = [];
        }
        acc[value].push(item);
        return acc;
    }, {});

    const groupedByData = Object.keys(intermediate).map(groupKey => ({
        key: groupKey,
        Value: intermediate[groupKey]
    }));

    return groupedByData;
};


utility.convertCamelCaseToWords = function (camelCaseStr) {
    return camelCaseStr?.replace(/([A-Z])/g, ' $1').trim();
}

utility.convertFirstLetterToUpperCase = function (str) {
    return str?.replace(/^\w/, c => c.toUpperCase());
}

utility.getSelectedStateCollectionObject = function (array, key = '_id') {
    let obj = {};
    if (array.length > 0) {
        array.map((x) => {
            obj[x[key]] = true
        });
    }
    return obj;
}

utility.getDigitalSignageMenu = function (isDigitalSignProduct = false, lang, user) {

    let local = [
        {
            type: 0,
            link: 'digitalsignDashboard',
            title: lang.digital_sign_dashboard_sub_menu,
            modulesid: MODULE.DIGITAL_SIGN_DASHBOARD,
            name: lang.digital_sign_dashboard_sub_menu,
            icon: 'fa fa-lg fa-fw fa-tachometer-alt'
        },
        {
            type: 0,
            link: 'digitalsignScreen',
            title: lang.screen_sub_menu,
            modulesid: MODULE.DIGITAL_SIGN_SCREEN,
            name: lang.screen_sub_menu,
            icon: 'fa fa-lg fa-fw fa-desktop'
        },
        {
            type: 0,
            link: 'digitalSignContent',
            title: lang.digital_sign_content_sub_menu,
            modulesid: MODULE.DIGITAL_SIGN_CONTENT,
            name: lang.digital_sign_content_sub_menu,
            icon: 'fa fa-lg fa-fw fa-clipboard'
        },
        {
            type: 0,
            link: 'Playlist',
            title: lang.playlist_sub_menu,
            modulesid: MODULE.PLAYLIST,
            name: lang.playlist_sub_menu,
            icon: 'fa fa-lg fa-fw fa-play'
        },
        {
            type: 0,
            link: 'digitalSignBoardOverlay',
            title: lang.digital_sign_overlay_sub_menu,
            modulesid: MODULE.DIGITAL_SIGN_OVERLAY,
            name: lang.digital_sign_overlay_sub_menu,
            icon: 'fa fa-lg fa-fw fa-code-fork'
        },
        {
            type: 0,
            link: 'digitalSignScreenGroup',
            title: lang.digital_sign_screen_group_sub_menu,
            modulesid: MODULE.DIGITAL_SIGN_SCREEN_GROUP,
            name: lang.digital_sign_screen_group_sub_menu,
            icon: 'fa fa-lg fa-fw fa-object-ungroup'
        },
        {
            type: 0,
            link: 'digitalSignContentGroup',
            title: lang.digital_sign_content_group_sub_menu,
            modulesid: MODULE.DIGITAL_SIGN_CONTENT_GROUP,
            name: lang.digital_sign_content_group_sub_menu,
            icon: 'fa fa-lg fa-fw fa-object-ungroup'
        }
    ];

    let digitalSignageMenu = [];

    if (isDigitalSignProduct && user?.Module?.length > 0) {
        user.Module.forEach(module => {
            switch (module.SID) {
                case MODULE.DIGITAL_SIGN_ADMIN_DASHBOARD:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignAdminDashboard',
                        title: lang.digital_sign_admin_dashboard_menu,
                        modulesid: MODULE.DIGITAL_SIGN_ADMIN_DASHBOARD,
                        name: lang.digital_sign_admin_dashboard_menu,
                        icon: 'fa fa-lg fa-fw fa-tachometer-alt'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_DASHBOARD:
                    digitalSignageMenu.unshift({
                        type: 0,
                        link: 'digitalsignDashboard',
                        title: lang.digital_sign_dashboard_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_DASHBOARD,
                        name: lang.digital_sign_dashboard_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-tachometer-alt'
                    });
                    break;
                case MODULE.PLAYLIST:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'Playlist',
                        title: lang.playlist_sub_menu,
                        modulesid: MODULE.PLAYLIST,
                        name: lang.playlist_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-play'
                    },);
                    break;
                case MODULE.DIGITAL_SIGN_SCREEN:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalsignScreen',
                        title: lang.screen_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_SCREEN,
                        name: lang.screen_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-desktop'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_SCREEN_GROUP:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignScreenGroup',
                        title: lang.digital_sign_screen_group_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_SCREEN_GROUP,
                        name: lang.digital_sign_screen_group_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-object-ungroup'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_CONTENT:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignContent',
                        title: lang.digital_sign_content_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_CONTENT,
                        name: lang.digital_sign_content_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-clipboard'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_OVERLAY:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignBoardOverlay',
                        title: lang.digital_sign_overlay_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_OVERLAY,
                        name: lang.digital_sign_overlay_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-code-fork'
                    });
                    break;
                case MODULE.USERS:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'users',
                        modulesid: MODULE.USERS,
                        name: lang.users_sub_menu,
                        title: lang.users_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-users'
                    });
                    break;
                case MODULE.INSTANCE:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'instances',
                        title: lang.instances_sub_menu,
                        modulesid: MODULE.INSTANCE,
                        name: lang.instances_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-server'
                    });
                    break;
                case MODULE.COMPANY:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'company',
                        title: lang.company_menu,
                        modulesid: MODULE.COMPANY,
                        name: lang.company_menu,
                        icon: 'fa fa-lg fa-fw fa-building'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_COMPANY_PLANS:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalsignCompanyPlans',
                        title: lang.digital_sign_company_plans_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_COMPANY_PLANS,
                        name: lang.digital_sign_company_plans_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-list'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_PLANS:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignPlans',
                        title: lang.digital_sign_plans_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_PLANS,
                        name: lang.digital_sign_plans_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-bars'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_CONTENT_GROUP:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignContentGroup',
                        title: lang.digital_sign_content_group_sub_menu,
                        modulesid: MODULE.DIGITAL_SIGN_CONTENT_GROUP,
                        name: lang.digital_sign_content_group_sub_menu,
                        icon: 'fa fa-lg fa-fw fa-object-ungroup'
                    });
                    break;
                case MODULE.DIGITAL_SIGN_COMPANY:
                    digitalSignageMenu.push({
                        type: 0,
                        link: 'digitalSignCompany',
                        title: lang.company_menu,
                        modulesid: MODULE.DIGITAL_SIGN_COMPANY,
                        name: lang.company_menu,
                        icon: 'fa fa-lg fa-fw fa-building'
                    });
                    break;
            }
        });
    }

    let orderData = [
        'digitalSignAdminDashboard',
        'digitalsignDashboard',
        'digitalSignCompany',
        'digitalsignScreen',
        'digitalSignContent',
        'Playlist',
        'digitalSignBoardOverlay',
        'digitalSignScreenGroup',
        'digitalSignContentGroup',
        'users',
        'instances',
        'company',
        'digitalsignCompanyPlans',
        'digitalSignPlans',
    ]

    let finalMenu = digitalSignageMenu.length > 0 ? digitalSignageMenu.sort((a, b) => orderData?.indexOf(a?.link) - orderData.indexOf(b?.link)) : [];

    return isDigitalSignProduct ? finalMenu :
        [{
            type: 1,
            title: lang.digital_sign_menu,
            icon: 'fa fa-lg fa-fw fa-google-wallet ',
            modulesid: MODULE.DIGITAL_SIGN_MENU,
            name: lang.digital_sign_menu,
            subMenu: local
        }];
}

utility.setLoginData = async function (res) {

    let lang = useTranslation();

    utility.setValue(LOCALSTORAGE_KEY.IS_ENTERPRISE_HAVE_COMPANIES, false);
    utility.setValue(LOCALSTORAGE_KEY.token, res?.token);
    utility.setAccessToken(res?.token);
    
    //COMPANY DATA
    utility.setValue(LOCALSTORAGE_KEY.COMPANY, res?.data?.Company);

    //Compnies
    let companies = [res?.data?.Company];

    // if enterprise login
    if(res?.data?.Company.CompanyType == COMPANYTYPE.ENTERPRISE){
        // get all companies
       let companiesRes = await API.getDataLookup(ENTITYNAME.Company, {query : [["ParentCompany_id", "=", res?.data?.Company?._id], ["CompanyType", "=", COMPANYTYPE.COMPAMY]]});
       if(companiesRes.success && companiesRes.data?.length > 0){
        companies = companiesRes.data;
       }else{
        utility.setValue(LOCALSTORAGE_KEY.IS_ENTERPRISE_HAVE_COMPANIES, true);
       }
    }

    // setting in local storage
    utility.setValue(LOCALSTORAGE_KEY.COMPANIES, companies);

    // setting first session timeout
    let sessiontimeout = (new Date()).getTime() + (res?.data?.Company?.SessionTimeOut ?? 3600000);
    utility.setValue(LOCALSTORAGE_KEY.sessiontimeout, sessiontimeout);

    //INSTANCE
    utility.setValue(LOCALSTORAGE_KEY.INSTANCE, res?.data?.Instance ?? {});

    if (!res?.data?.Company || !res?.data?.Company?.Products || res?.data?.Company?.Products?.length == 0 || !Object.keys(PRODUCTS).map((x) => PRODUCTS[x]).some((x) => res?.data?.Company?.Products?.includes(x))) {
        ConfirmAlertOk(
            () => { },
            lang.warning_label,
            lang.there_is_no_product_assigned_to_your_company_kindly_contact_team_BMS_label,
        )
        return;
    }

    if (res?.data?.Module?.length == 0 && (res?.data?.RoleType?.ID ?? ROLETYPE.User) == ROLETYPE.User) {
        ConfirmAlertOk(
            () => { },
            lang.warning_label,
            lang.you_dont_have_any_module_access_please_contact_your_admin_label,
        )
        return;
    }
    utility.setValue(LOCALSTORAGE_KEY.userData, { ...res?.data, color: utility.getRandomColor() })
    utility.setValue(LOCALSTORAGE_KEY.user_id, res?.data?._id);

    await PlanningHelper.loadPlanningPrefrence();
    let applicationConfiguration = await API.getEntitiesWithSearch(ENTITYNAME.ApplicationConfiguration);
    utility.setValue(LOCALSTORAGE_KEY.applicationConfiguration, applicationConfiguration?.data);

    let userData = {
        _id: res?.data?._id,
        SID: res?.data?.SID,
        name: res?.data?.name,
        Email: res?.data?.Email,
        username: res?.data?.username,
    }
    let logData = { event: LOGEVENT.LOG_IN, module: MODULE.LOGIN, data: userData, message: LOGEVENT.LOG_IN };
    API.SaveLogs(logData);

    let allModules = await API.getDataLookup(ENTITYNAME.Module);
    if (allModules.success) {
        utility.setValue(LOCALSTORAGE_KEY.ALLMODULES, allModules?.data ?? []);
    }

    // deleting user activity log on successful login
    API.deleteLogHistory();

    return res;
}

utility.timeAgo = (timestamp) => {
    return dayjs(timestamp).fromNow();
}

utility.isAutomateB = () => {
    let isDigitalSignage = false;
    const baseURL = window.location.protocol + window.location.host;
    const version = utility.getValue(LOCALSTORAGE_KEY.applicationVersion);
    if (version?.IsDigitalSignage || baseURL.includes('app.automateb') || baseURL.includes('cms.automateb')) {
        isDigitalSignage = true;
    }
    return isDigitalSignage;
}

utility.getUserChannels = async () => {

    let user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const channelRes = await API.getDataLookup(ENTITYNAME.Channel);
    let loggedInUserChannels = channelRes.data && channelRes.data.length > 0 && channelRes.data.filter((x) =>
        user.Channels && user.Channels.length > 0 && user.Channels.some((y) => y == x._id));

    return loggedInUserChannels;
}

utility.calculateAspectRatio = (width, height) => {
    if (width > height) {
        return ORIENTATION.Landscape
    } else if (width < height) {
        return ORIENTATION.Portrait
    } else {
        return ORIENTATION.Landscape
    }
}

utility.getThumbnailUrl = (url, contentType) => {
    switch (contentType) {
        case CONTENT_TYPE.Video:
            return url.replace('/upload/', '/upload/c_scale,w_300/').replace('.mp4', '.jpg');
        case CONTENT_TYPE.YouTube:
            return url.replace('https://www.youtube.com/watch?v=', 'https://img.youtube.com/vi/') + '/maxresdefault.jpg';
        case CONTENT_TYPE.Image:
            return url;
        default:
            return 'https://media.comicbook.com/files/img/default-movie.png';
    }
}


utility.getDataFilteredByRestrictions = (data, restrictions) => {

    let finalData = [];

    if (restrictions.length > 0) {

        finalData = data.filter((x) => {
            return !restrictions.some((condition) => {

                const { column, operator, value } = condition;

                if (operator.Operator === '=') {
                    return x[column.name] == value;
                }

                if (operator.Operator === '>') {
                    return x[column.name] > value;
                }

                if (operator.Operator === '<') {
                    return x[column.name] < value;
                }

                if (operator.Operator === '<=') {
                    return x[column.name] <= value;
                }

                if (operator.Operator === '>=') {
                    return x[column.name] >= value;
                }

                if (operator.Operator === 'like') {
                    return x[column.name] && x[column.name].includes(value);
                }

                return false;
            });
        })

    } else {
        finalData = [...data];
    }

    return finalData;

}

utility.isValidSegmentChannelRights = (data) => {

    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);

    return userData.RoleType.ID == ROLETYPE.SuperAdmin ? false : data?.some(x => !userData?.Channels.includes(x?._id)) ? true : false;

}


utility.isValidActionForUser = (moduleSID,actionSID) => {

    const userData = utility.getValue(LOCALSTORAGE_KEY.userData);
    const restrictions = userData?.Restrictions ?? [];

    if (userData.RoleType.ID == ROLETYPE.SuperAdmin) {
        return true;
    }

    if(restrictions?.some((restrict) => restrict.ModuleSID == moduleSID  && restrict.ActionSID == actionSID )){
        return false;
    }

    return true;
    
}