/* eslint-disable */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BossDialog from '../../../components/BossDialog';
import { useTranslation } from '../../../locale/useTranslation';
import { EditPageHeader } from '../../../components/EditPageHeader';
import * as API from '../../../framework/API/api';

const DigitalSignUserForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        _id: props?.selectedItem?._id ?? null,
        SID: props?.selectedItem?.SID ?? 0,
        name: props?.selectedItem?.name ?? '',
        email: props?.selectedItem?.email ?? '',
        password: props?.selectedItem?.password ?? '',
        confirmPassword: props?.selectedItem?.password ?? '',
        index: props?.selectedItem?.index ?? null
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (dataItem.name == undefined || dataItem.name == '' || !dataItem.name?.toString().trim()) {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.email == undefined || dataItem.email == "" || !emailRegex.test(dataItem.email)) {
            toast.error(lang.please_enter_email_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if(dataItem.SID == 0){
            if (dataItem.password == undefined || dataItem.password == '' || !dataItem.password?.toString().trim()) {
                toast.error(lang.please_enter_password_error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
            if (dataItem.confirmPassword == undefined || dataItem.confirmPassword == '' || !dataItem.confirmPassword?.toString().trim()) {
                toast.error(lang.please_enter_confirm_password_users_error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
            if (dataItem.password !== dataItem.confirmPassword) {
                toast.error(lang.confirm_password_do_not_match_error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
        }

       return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        let finalData = {
            _id: dataItem?._id,
            SID: dataItem?.SID,
            name: dataItem.name,
            email: dataItem.email?.toLowerCase(),
            password: dataItem.password,
            index: dataItem.index ?? null
        }
        let checkUserExists = await API.checkUserExists(finalData?._id, finalData.email);
        if(!checkUserExists.success){
            toast.error(checkUserExists.message);
            return;
        }
        props?.setUserData(finalData);
        setDataItem(blankDataItem);
        props?.closeForm(); 
    }; 

    return (
        <BossDialog 
            title={dataItem.name != "" ? dataItem.name : lang.add_user_dialog_header}
            width='700px'
            onClose={()=> {props.closeForm(); setDataItem(blankDataItem);}}
        >
            <div className="row">
                <div className='col-12'>
                    <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={()=> {props.closeForm(); setDataItem(blankDataItem);}}/>
                    <div className='row'>
                        <div className='col-6'>
                            <label htmlFor="name">{lang.name_label} *</label>
                            <input
                                style={{ display: 'inline-block' }}
                                type="text"
                                className="form-control form-control-sm"
                                name="name"
                                value={dataItem.name}
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-6'>
                            <label htmlFor="email">{lang.email_address_label} *</label>
                            <input
                                style={{ display: 'inline-block' }}
                                type="email"
                                className="form-control form-control-sm"
                                name="email"
                                value={dataItem.email}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    {dataItem.SID == 0 && <div className="mt-2 row">
                        <div className='col-6'>
                            <label htmlFor="password">{lang.password_label} *</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                style={{ display: "block", padding: "10px 20px" }}
                                className="form-control form-control-sm"
                                name="password"
                                value={dataItem.password}
                                onChange={onChange}
                            />
                            <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "24px", cursor: "pointer" }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)} />
                        </div>
                        <div className='col-6'>
                            <label htmlFor="confirm password">{lang.confirm_password_label} *</label>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                style={{ display: "block", padding: "10px 20px" }}
                                name="confirmPassword"
                                className="form-control form-control-sm"
                                value={dataItem.confirmPassword}
                                onChange={onChange}
                            />
                            <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "24px", cursor: "pointer" }} className={`icon-append fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                        </div>
                    </div>}
                </div>
            </div>
        </BossDialog>
    )
}

export default DigitalSignUserForm;