/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { OttPosterCollectionSelectionColumns } from "../../framework/utility/customColumns";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import BrowseRoundButton from "../../framework/forms/helpercomponents/buttons/BrowseRoundButton";
import { ConfirmDeleteAlert } from '../../ConfirmAlert'
import { useRef } from "react";
import PreviewRoundButton from "../../framework/forms/helpercomponents/buttons/PreviewRoundButton";
import ImagePreviewPage from "../../components/ImagePreviewPage";
import BossDialog from "../../components/BossDialog";

function OttAssetPosterEditForm(props) {

  const [ottPosterType, setOttPosterType] = useState([]);

  let initialValue = {
    Description: "",
    OttPosterType: "",
    Url: "",
  }
  const [dataItem, setDataItem] = useState(initialValue);
  const [i, setI] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [openImageBank, setOpenImageBank] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');
  const lang = useTranslation();
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  }  
  const [previewPageOpen, setPreviewPageOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);


  useEffect(() => {
    loadcombo();
  }, []);

  //to get data from collection on edit
  const onEdit = (dataItem) => {
    setDataItem(dataItem);
  }

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const loadcombo = async () => {
    var ottPosterType = await API.getDataLookup(ENTITYNAME.OttPosterType, { sort: { Description: 1 } });
    setOttPosterType(ottPosterType.data);
  }

  const isValid = () => {
    if (dataItem.OttPosterType == undefined || Object.keys(dataItem.OttPosterType).length == 0) {
      toast.error(`${lang.please_select_poster_type_first_media_episode_poster_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
   if (dataItem.Url == undefined || Object.keys(dataItem.Url).length == 0) {
      toast.error(`${lang.please_select_url_first_media_episode_poster_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;

  }

  const addPoster = () => {
    if (isValid()) {
      const dataNew = {
        Description: dataItem.Description,
        OttPosterType: typeof dataItem.OttPosterType === "string" ? { SID : JSON.parse(dataItem.OttPosterType).SID ,Description : JSON.parse(dataItem.OttPosterType).Description } : { SID : dataItem.OttPosterType.SID ,Description : dataItem.OttPosterType.Description },
        Url:dataItem.Url
        // OttPosterType: dataItem.OttPosterType,
      }
      console.log(dataNew);
      props.setOttPostersData([...props.data, dataNew])
      setI(i + 1);
      setDataItem(initialValue);
      clearData();
    }
  }

  const MyCommandCell = (props) => (
    
    <div className="mt-4" style={{ display: "flex" }}>
      <EditRoundButton onClick={() => editFormGrid(props)} />
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removePosters(props.dataIndex), () => { })} />
      <PreviewRoundButton onClick={(e) => { setPreviewPageOpen(true); openImagePreview(props) }} />
    </div>
  )

  const editFormGrid = (data) => {
    let dataItem = data.dataItem;
    setSelectedIndexRef(data.dataIndex);
    setDataItem(dataItem);
    setToggle(true);
    setEditMode(true);
  }

  const openImagePreview = (data) => {
    let dataItem = data.dataItem;    
    setImagePreview(dataItem);
    
  }

  const removePosters = (index) => {
    if (props.data.length == 1) {
      props.setOttPostersData([]);
      setI(i - 1);
      console.log(i);
      // clearData();
    }
    else {
      // console.log(index);
      props.setOttPostersData([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      // clearData();
      setI(i - 1);
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editPosters = () => {
    if (isValid()) {
      let local = [...props.data]
      local[selectedIndexRef.current] = dataItem;
      props.setOttPostersData(local);
      setEditMode(false);
      clearData();
    }
  }

  const clearData = () => {
    setDataItem(initialValue);
    setEditMode(false);
    setToggle(false);
  }

  const setSelectedImage = (dataList) => {
    if (dataList.length > 0) {
      let selectedImageURL = dataList[0].ImageUrl;
      setSelectedImageUrl(selectedImageURL);
      setDataItem({ ...dataItem, ["Url"]: selectedImageURL });
    } else {
      toast.error(`${lang.no_select_image_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const onClosedPreview = async () => {
    setPreviewPageOpen(false);

}
  return (<div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
    <div className="row">
      <div className={"col-12"}>
        <AddRoundButton onClick={() => setToggle(true)}></AddRoundButton>
        <Grid data={props.data} style={{ height: "20vh" }}  className="mt-2">
          <GridColumn cell={MyCommandCell} width={"100px"} locked={true} />
          <GridColumn field="Description" title={lang.description_column} editable={false} />
          <GridColumn field="OttPosterType.Description" title={lang.poster_type_column} editable={false} />
          <GridColumn field="Url" title={lang.image_column} cell={ImageCell} editable={false} />
        </Grid>
      </div>
      {toggle &&
        <BossDialog
          title={dataItem?.OttPosterType?.Description ? dataItem?.OttPosterType?.Description : lang.create_poster_dialog_header}
          onClose={() => setToggle(false)}
          width={700}
          height={300}
        >
          <div className="row">
            <div className="col-12">
              <Form
                render={(formRenderProps) => (
                  <FormElement>
                    <div className="row mt-2">
                      <div className="marlr">
                        {!editMode && <AddRoundButton onClick={() => addPoster()} />}
                        {editMode && <SaveRoundButton onClick={() => { editPosters() }} />}
                        <CancelRoundButton onClick={clearData} />
                      </div>
                    </div>
                    <div className="mt-2" style={{ border: "groove 1px" }}>
                      <div className="row" style={{ margin: "5px" }}>
                        <div className="col-12 mt-2">
                          <div className="row">
                          <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="TabView">{lang.poster_type_label} *</label>
                                <DropDownList
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  data={ottPosterType}
                                  name="OttPosterType"
                                  textField="Description"
                                  dataItemKey="_id"
                                  value={dataItem.OttPosterType}
                                  onChange={onChange}
                                  validator={(value) => value ? "" : "Please select the value"}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.description_label}</label>
                                <input type="text" className="form-control form-control-sm" name="Description" value={dataItem.Description} onChange={(e) => onChange(e)} />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <div className="form-group">
                                <label htmlFor="">{lang.url_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="Url" value={dataItem.Url} onChange={(e) => onChange(e)} />
                              </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                              <BrowseRoundButton onClick={() => setOpenImageBank(true)} />
                            </div>
                            {/* <div className="col-6 ml-3" style={{ marginTop: "20px" }}>
                              <Field
                                name={"Archive"}
                                component={Checkbox}
                                label={lang.archive}
                                onChange={(e) => onChange(e)}
                                checked={dataItem.Archive}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormElement>

                )} />
            </div>
          </div>
        </BossDialog>}
    </div>
    {openImageBank && <CollectionSelection entityname={ENTITYNAME.OttPosters} customColumns={OttPosterCollectionSelectionColumns} title={lang.select_url_tooltip} closeForm={() => setOpenImageBank(!openImageBank)} setDataList={setSelectedImage} mode={"single"} width={"50vw"} height={"60vh"} />}
    { previewPageOpen &&
      <ImagePreviewPage image={imagePreview.Url} onClose={onClosedPreview}></ImagePreviewPage>
    }
  </div>
  )
}

export default OttAssetPosterEditForm