import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { memo, startTransition, useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME, MEDIA_MANAGEMENT_FILTER_BY_OPTIONS } from "../../../framework/constant/constant";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { utility } from "../../../framework/utility/utilityProvider";

const ManageContentHeader = (props) => {

    const style = { margin: "0 10px" }
    const textStyle = { padding: "0 5px", display: "inline-block", fontSize: "13px", fontWeight: '400', marginBottom: "0px" }
    const buttonStyle = { padding: "0 5px", display: "inline-block", backgroundColor: "white", height: '30px', width: "150px" }

    const [data, setData] = useState({
        filterByOptions: MEDIA_MANAGEMENT_FILTER_BY_OPTIONS,
        // regions: [],
        // platforms: [],
        categories: [],
        pgRatings: [],
        genres: [],
        channels: [],
    });
    const date = new Date();
    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const [filterData, setFilterData] = useState({
        // region: null,
        // platform: null,
        // category: null,
        pgRating: null,
        fromDate: defaultDates.FirstDate,
        toDate: defaultDates.LastDate,
        filterBy: MEDIA_MANAGEMENT_FILTER_BY_OPTIONS[0],
        genre: null,
        productionYear: '',
        // notPublished: null,
        channel: null,
    });

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {

        // let regionRes = await API.getDataLookup(ENTITYNAME.Region);
        // let platformRes = await API.getDataLookup(ENTITYNAME.Platform);
        // let categoriesRes = await API.getDataLookup(ENTITYNAME.ContentCategory, { sort: { Name: 1 } });
        let genreRes = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
        var pgRatingRES = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        var channelsRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });

        let stateData = { ...data };
        let stateFilterData = { ...filterData };

        // if (regionRes.success) {
        //     stateData = { ...stateData, regions: [{ _id: 0, SID: 0, Description: "All" }, ...regionRes.data] };
        //     if (regionRes.data.length > 0) {
        //         stateFilterData = { ...stateFilterData, region: stateData.regions[0] }
        //     }
        // }
        // if (platformRes.success) {
        //     stateData = { ...stateData, platforms: [{ _id: 0, SID: 0, Description: "All" }, ...platformRes.data] };
        //     if (platformRes.data.length > 0) {
        //         stateFilterData = { ...stateFilterData, platform: stateData.platforms[0] }
        //     }
        // }
        // if (categoriesRes.success) {
        //     stateData = { ...stateData, categories: categoriesRes.data };
        //     if (categoriesRes.data.length > 0) {
        //         stateFilterData = { ...stateFilterData, category: [categoriesRes.data[0]] }
        //     }
        // }
        if (genreRes.success) {
            stateData = { ...stateData, genres: genreRes.data };
            if (genreRes.data.length > 0) {
                stateFilterData = { ...stateFilterData, genre: [genreRes.data[0]] }
            }
        }
        if (pgRatingRES.success) {
            stateData = { ...stateData, pgRatings: pgRatingRES.data };
            if (pgRatingRES.data.length > 0) {
                stateFilterData = { ...stateFilterData, pgRating: pgRatingRES.data[0] }
            }
        }
        if (channelsRes.success) {
            stateData = { ...stateData, channels: [{ _id: 0, SID: 0, FullChannelName: "All" }, ...channelsRes.data] };
            if (channelsRes.data.length > 0) {
                stateFilterData = { ...stateFilterData, channel: stateData.channels[0] }
            }
        }
        setData(stateData);
        setFilterData(stateFilterData);

    }

    const onChange = (e) => {
        if (e.target.name === "filterBy") {
            if (e.target.value.value === "treeView") {
                setFilterData({ ...filterData, [e.target.name]: e.target.value });
                props.setFilterData({ ...filterData, [e.target.name]: e.target.value });
                return;
            }
        }
        setFilterData({ ...filterData, [e.target.name]: e.target.value })
    }

    const onLoad = () => {
        startTransition(() => {
            props.setFilterData({ ...filterData });
        }, [])
    }

    const onExport = () => {
        props.setShowExport({ show: true, filterData: { ...filterData } });
    }

    const Header = () => {
        switch (filterData.filterBy.value) {
            case "publishingDates":
                return (
                    <>
                        <div style={style}>
                            <h5 style={textStyle}>From Date :</h5><br />
                            <input
                                type="date"
                                width={150}
                                onChange={onChange}
                                className="form-control form-control-sm"
                                value={filterData.fromDate}
                                name="fromDate"
                            />
                        </div>
                        <div style={style}>
                            <h5 style={textStyle}>To Date :</h5><br />
                            <input
                                type="date"
                                width={150}
                                onChange={onChange}
                                className="form-control form-control-sm"
                                value={filterData.toDate}
                                name="toDate"

                            />
                        </div>
                    </>
                );
            case "contentCategories":
                return (
                    <>
                        <div style={style}>
                            <h5 style={textStyle}>Categories :</h5><br />
                            <MultiSelect
                                style={{ ...buttonStyle, width: "300px", overflow: "auto" }}
                                data={data.categories}
                                dataItemKey="_id"
                                textField="Name"
                                value={filterData.category}
                                onChange={onChange}
                                name={"category"}
                            />
                        </div>

                    </>

                );
            case "genre":
                return (
                    <>
                        <div style={style}>
                            <h5 style={textStyle}>Genre :</h5><br />
                            <MultiSelect
                                style={{ ...buttonStyle, width: "300px", overflow: "auto" }}
                                data={data.genres}
                                dataItemKey="_id"
                                textField="Description"
                                value={filterData.genre}
                                onChange={onChange}
                                name={"genre"}
                            />
                        </div>
                    </>
                );
            case "pgRating":
                return (
                    <>
                        <div style={style}>
                            <h5 style={textStyle}>PG Rating :</h5><br />
                            <DropDownList
                                style={buttonStyle}
                                data={data.pgRatings}
                                textField="Description"
                                dataItemKey="_id"
                                value={filterData.pgRating}
                                onChange={onChange}
                                name="pgRating"
                            />
                        </div>
                    </>
                );
            case "productionYear":
                return (
                    <>
                        <div style={style}>
                            <h5 style={textStyle}>Production Year :</h5><br />
                            <input
                                type="input"
                                width={150}
                                onChange={onChange}
                                className="form-control form-control-sm"
                                value={filterData.productionYear}
                                name="productionYear"
                                autoFocus
                            />
                        </div>
                    </>
                );
            case "linear":
                return (
                    <>
                        <div style={style}>
                            <h5 style={textStyle}>From Date :</h5><br />
                            <input
                                type="date"
                                width={150}
                                onChange={onChange}
                                className="form-control form-control-sm"
                                value={filterData.fromDate}
                                name="fromDate"
                            />
                        </div>
                        <div style={style}>
                            <h5 style={textStyle}>To Date :</h5><br />
                            <input
                                type="date"
                                width={150}
                                onChange={onChange}
                                className="form-control form-control-sm"
                                value={filterData.toDate}
                                name="toDate"
                            />
                        </div>
                        <div style={style}>
                            <h5 style={textStyle}>Channel :</h5><br />
                            <DropDownList
                                style={buttonStyle}
                                data={data.channels}
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={filterData.channel}
                                onChange={onChange}
                                name="channel"
                            />
                        </div>
                    </>
                )
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "center", backgroundColor: "white", border: "1px solid #ededed", overflow: 'auto', padding: '10px', height: "80px", overflowY: "hidden" }}>
            <div style={{ marginRight: "15px" }}>
                <h5 style={{ ...textStyle, marginLeft: 0 }}>Filter by :</h5><br />
                <DropDownList
                    style={buttonStyle}
                    data={data.filterByOptions}
                    dataItemKey="_id"
                    textField="text"
                    value={filterData.filterBy}
                    onChange={onChange}
                    name="filterBy"

                />
            </div>
            <Header />
            {/* {!(filterData.filterBy.value.includes(data.filterByOptions[4].value) || filterData.filterBy.value.includes(data.filterByOptions[5].value) || filterData.filterBy.value.includes(data.filterByOptions[6].value)) && <div style={style}>
                <h5 style={textStyle}>Platform :</h5><br />
                <DropDownList
                    style={buttonStyle}
                    data={data.platforms}
                    dataItemKey="_id"
                    textField="Description"
                    defaultValue={filterData.platform}
                    onChange={onChange}
                    name="platform"

                />
            </div>
            }
            {!(filterData.filterBy.value.includes(data.filterByOptions[4].value) || filterData.filterBy.value.includes(data.filterByOptions[5].value) || filterData.filterBy.value.includes(data.filterByOptions[6].value)) && <div style={style}>
                <h5 style={textStyle}>Region :</h5><br />
                <DropDownList
                    style={buttonStyle}
                    data={data.regions}
                    dataItemKey="_id"
                    textField="Description"
                    defaultValue={filterData.region}
                    onChange={onChange}
                    name="region"
                />
            </div>
            } */}
            {/* !filterData.filterBy.value.includes(data.filterByOptions[6].value) &&  */}
            <RoundButton btnColor={'primary'} style={{ alignSelf: "end", ...style, color: "whitesmoke" }} icon={'eye'} title={'Load'} name={'Load'} onClick={onLoad} />
            <RoundButton btnColor={'warning'} style={{ alignSelf: "end", color: "whitesmoke" }} icon={'download'} title={'Export'} name={'Export'} onClick={onExport} />
        </div>
    );
}

export default memo(ManageContentHeader);